import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ApiService } from '../services/api-service';
import { Select2OptionData } from 'ng-select2';
import { DataTableDirective } from 'angular-datatables';
import { PER_PAGE } from '../constant/config';

@Component({
  selector: 'app-edi',
  templateUrl: './edi.component.html',
  styleUrls: ['./edi.component.scss']
})
export class EdiComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  voucherStartDate: Date;
  voucherEndDate: Date;
  today;
  payorList:any[] = [];
  payorOptions: Select2OptionData[] = [];
  selectedPayor;
  submitBtn = false;
  voucherSubmitBtn = false;
  importFile;

  auditList = [];
  totalNum;

  submitSuccess = false;
  errorMsg = null;

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  loading = false;

  constructor(private datePipe: DatePipe, private apiService: ApiService) { 
    let today = new Date;
    today.setDate(today.getDate()-1);
    this.today = {
      year: today.getFullYear(),
      month: (today.getMonth()) + 1,
      day: today.getDate()
    }
  }

  ngOnInit() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        processing: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          let response;
          try {
            this.loading = true;
              response = await this.apiService.getAuditRecord(true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
            
            this.auditList = response.data;
            this.totalNum = response.pagination.total;
          } catch (err) {
            console.log(err);
          } finally {
            this.loading = false;
          }
          callback({
            recordsTotal: this.totalNum,
            recordsFiltered: this.totalNum,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
    this.fetchPayor();
  }

  async fetchPayor() {
    try {
      this.payorList = await this.apiService.getPayors();
      this.payorOptions = this.payorList.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
    } catch (err) {
      console.log(err);
    }
  }

  async export() {
    this.submitBtn = true;
    if(!this.selectedPayor || !this.startDate || !this.endDate) {
      let startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
      let endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
      try {
        await this.apiService.edi(startDate, endDate, this.selectedPayor);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async voucherExport() {
    this.voucherSubmitBtn = true;
    if(this.voucherStartDate && this.voucherEndDate) {
      let startDate = this.datePipe.transform(this.voucherStartDate, 'yyyy-MM-dd');
      let endDate = this.datePipe.transform(this.voucherEndDate, 'yyyy-MM-dd');
      try {
        await this.apiService.exportVoucher(startDate, endDate);
      } catch (err) {
        console.log(err);
      }
    }
  }

  onFileChange(event: DataTransfer){
    this.importFile = event.files[0];
  }

  async voucherImport() {
    this.submitSuccess = false;
    this.errorMsg = null;
    if(this.importFile) {
      try {
        await this.apiService.importFile(this.importFile, 'VOUCHER', null);
        this.submitSuccess = true;
        this.importFile = null;
      } catch (err) {
        console.log(err);
        this.errorMsg = err.error.errorMsg;
      }
    }
  }

}
