import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-default',
  templateUrl: './view-default.component.html',
  styleUrls: ['./view-default.component.scss']
})
export class ViewDefaultComponent implements OnInit {

  service_list;

  contract = {
    name: 'Checkpoint Default Contract'
  };

  constructor() { }

  ngOnInit() {
  }

}
