export interface Copay {
    id: number,
    copay_type: string,
    amount: number,
    unit: string,
    customized_amount: number,
    default_amount: number,
    default_unit: string
}

export enum COPAY_UNIT {
    amount = 'AMOUNT',
    percentage = 'PERCENTAGE'
}