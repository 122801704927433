import { Component, OnInit, ViewChild } from '@angular/core';
import { PER_PAGE } from '../constant/config';
import { ApiService } from '../services/api-service';
import { Select2OptionData } from 'ng-select2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FetchDoctorClinicResponse } from '../response/FetchDoctorClinicResponse';

@Component({
  selector: 'app-doctor-clinic',
  templateUrl: './doctor-clinic.component.html',
  styleUrls: ['./doctor-clinic.component.scss']
})
export class DoctorClinicComponent implements OnInit {
  
  doctor_clinic:FetchDoctorClinicResponse = {
    data: [],
    pagination: null
  };
  networkGroup: Map<any, any>;
  networkGroupOptions: Select2OptionData[] = [];
  loading;

  modalLoading: boolean;
  importSuccess: boolean;
  importErr: String[];
  file: File;

  searchDoctorClinic = {
    network_group: null,
    doctor_id: null,
    doctor_name: null,
    clinic_id: null,
    clinic_name: null
  }

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(private apiService: ApiService) { }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    await this.fetchDoctorClinic();
  }

  async fetchDoctorClinic() {
    this.loading = true;
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          if(!this.networkGroup) 
            await this.fetchNetworkGroup();
          this.doctor_clinic = <FetchDoctorClinicResponse> await this.apiService.getDoctorClinic(
            null, 
            this.searchDoctorClinic.clinic_id, 
            this.searchDoctorClinic.doctor_id, 
            [this.searchDoctorClinic.network_group],
            this.searchDoctorClinic.clinic_name,
            this.searchDoctorClinic.doctor_name,
            true, 
            PER_PAGE, 
            dataTablesParameters.start/PER_PAGE + 1, 
            );
          this.loading = false;

          callback({
            recordsTotal: this.doctor_clinic['pagination'].total,
            recordsFiltered: this.doctor_clinic['pagination'].total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  async fetchNetworkGroup() {
    let res = await this.apiService.getNetworkGroup();
    this.networkGroup = new Map(res.map(item => [item.id, item]));
      this.networkGroupOptions = res.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
  }

  async filterDoctorClinic() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  async import() {
    if (!this.file) return;

    try {
      this.modalLoading = true;
      await this.apiService.importFile(this.file, 'DOCTOR_CLINIC', null);
      this.importSuccess = true;
    } catch(err) {
      console.log(err);
      this.importErr = err.error.error_messages;
    } finally {
      this.modalLoading = false;
    }
  }

  resetModal() { 
    this.importSuccess = false;
    this.importErr = null;
  }

  onFileChange(event: DataTransfer){
    this.file = event.files[0];
  }

  getNetworkGroupName(id){
    if(this.networkGroup) {
      return this.networkGroup.get(id).name;
    }
  }
}
