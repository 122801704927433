import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  login: boolean;

  constructor(protected localStorage: LocalStorage) {
  }

  checkLogin(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.localStorage.getItem<Boolean>('login')
      .subscribe((result) => { 
        resolve(result);
      }, (error) => {
        reject(error);
      });
    });
  }

  setLogin(){
    this.localStorage.setItem('login', true).subscribe(() => {});
  }
  
  setLogout(){
    this.localStorage.setItem('login', false).subscribe(() => {});
    this.localStorage.clear().subscribe(() => {});
  }

  setVisitToken(token): Promise<any> {
    return new Promise((resolve, reject) => {
      this.localStorage.setItem('visitToken', token).subscribe(() => {
        resolve();
      });
    });
  }

  getVisitToken(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.localStorage.getItem<any>('visitToken')
      .subscribe((result) => { 
        resolve(result);
      }, (error) => {
        reject(error);
      });
    });
  }

  setUserProfile(profile): Promise<any> {
    return this.localStorage.setItem('userProfile', profile).toPromise();
  }

  getUserProfile(): Promise<User>{
    return this.localStorage.getItem<any>('userProfile').toPromise();
  }
}
