import { Component, OnInit } from '@angular/core';
import { PLAN_TYPE } from '../models/Plan';
import { ApiService } from '../services/api-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-default-plan',
  templateUrl: './add-default-plan.component.html',
  styleUrls: ['./add-default-plan.component.scss']
})
export class AddDefaultPlanComponent implements OnInit {

  plan = {
    name: '',
    num_of_members: 0,
    term_notice_period: 0,
    type: PLAN_TYPE.def
  };

  constructor(private apiService: ApiService, private route: Router) { }

  ngOnInit() {
  }

  async add(plan) {
    this.route.navigate(['/default-plan']);
  }

}
