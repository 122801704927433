import { PushNotificationService } from './../services/push-notification.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { COUNTRY_CODE } from '../models/Doctor';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private translatedText;

  loginOption = new FormGroup ({
    option: new FormControl('email'),
  })

  profile = new FormGroup ({
    email: new FormControl(''),
    password: new FormControl('')
  })
  doctorProfile = new FormGroup ({
    country_code: new FormControl('852'),
    phone: new FormControl(''),
    password: new FormControl('')
  })
  returnUrl: string;

  loading: boolean = false;
  errorMsg = "";
  errorMsg2 = "";
  forgetPW = false;
  forgetPWEmail = "";
  forgetCountryCode = "852";
  forgetPhone = "";
  sent = false;

  country_code = COUNTRY_CODE;

  constructor(private apiService: ApiService, private router: Router, private storage: LocalStorageService, private route: ActivatedRoute, private pushNotification: PushNotificationService, private translate: TranslateService) {
    this.translate.get([
      'invalid_email_password',
      'field_required'
    ]).subscribe(res => this.translatedText = res);
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  async login() {
    this.loading = true;
    this.errorMsg = '';
    try{
      let user;
      if(this.loginOption.value.option === 'email')
        user = await this.apiService.login(this.profile.value);
      else
        user = await this.apiService.doctorLogin(this.doctorProfile.value);
      this.storage.setLogin();
      await this.storage.setUserProfile(user);
      await this.storage.setVisitToken(user.api_token);
      this.pushNotification.init();
      this.router.navigate([this.returnUrl]);
      // this.router.navigate(['/home']);
    } catch (err) {
      console.log(err);
      this.errorMsg = this.translatedText['invalid_email_password'];
    } finally {
      this.loading = false;
    }
  }

  forgetPWmodal() {
    this.forgetPW = !this.forgetPW;
  }

  async resetPW() {
    this.sent = false;
    this.errorMsg2 = "";
    if(( this.loginOption.value.option === 'email' && !this.forgetPWEmail ) || ( this.loginOption.value.option === 'phone' && !this.forgetPhone || !this.forgetCountryCode )) {
      this.errorMsg2 = this.translatedText['field_required']
    } else {
      this.loading = true;
      try {
        await this.apiService.resetPassword(this.forgetPWEmail, this.forgetCountryCode, this.forgetPhone)
        this.sent = true;
      } catch (err) {
        console.log(err)
        this.errorMsg2 = err.error.error_messages;
      } finally {
        this.loading = false;
      }
    }
  }

}
