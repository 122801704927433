import { Instruction } from './Instruction';
import { Copay } from './Copay';
import { Payor } from "./Payor";
import { Service_Item } from './Service_Item';

export interface Plan {
    id: number,
    name: string,
    num_of_members: number,
    insurer: Payor,
    term_notice_period: number,
    copay: Copay[],
    services: Service_Item[],
    image: string,
    image_style: {
        name: {},
        policy_no: {}
    },
    instructions: Instruction[]
}

export enum PLAN_TYPE {
    std = "STANDARD" ,
    def = "DEFAULT"
}