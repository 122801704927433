import { Payor } from "./Payor";
import { Plan } from "./Plan";
import { Service_Item } from "./Service_Item";

export enum CONTRACT_TEMPLATE_TYPE {
    std = "STANDARD",
    def = "DEFAULT"
}

export interface ContractTemplate {
    id: string,
    name: string,
    plan_id: number
    plan: Plan,
    // services: Service_Item[],
    // payor: Payor,
    type: CONTRACT_TEMPLATE_TYPE,
    services: Service_Item[]
}