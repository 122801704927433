import { Qualification } from './Qualification';
import { DoctorClinic } from './DoctorClinic';
import { Specialty } from "./Specialty";
import { Clinic } from "./Clinic";

export interface Doctor {
    id: string,
    name: string,
    zh_hant_name: string,
    zh_hans_name: string,
    icon_blob: string,
    nc_email: string,
    nc_created_at: string,
    specialty: Specialty,
    primary_license: string,
    secondary_license: string,
    year_practice: number,
    type: string,
    status: string,
    gender: string,
    resident: string,
    email: string,
    clinic: Clinic[],
    gp_qualification: Qualification,
    sp_qualification: Qualification,
    doctor_clinic: DoctorClinic[]
}

export enum TYPE {
    gp = 'GP',
    sp = 'SP',
    tcm = 'TCM',
    physio = 'PHYSIO'
}

export enum GENDER {
    m = 'M',
    f = 'F'
}

export enum RESIDENT {
    local = 'Local Resident',
    nonlocal = 'Non-Local Resident'
}

export const COUNTRY_CODE = [
    '852',
    '853',
    '86'
]