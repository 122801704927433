import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { QuestionControlService } from 'src/app/services/question-control.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Question } from 'src/app/models/questions/Question';
import { Select2OptionData } from 'ng-select2';
import { SPECIALTY_OPTION_TYPE } from 'src/app/models/Specialty';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [ QuestionControlService ]
})
export class DynamicFormComponent implements OnInit {
  @Input() questions: Question<any>[] = [];
  @Input() isTemplate: boolean = false;
  @Input() validate: boolean = true;
  @Output() onSubmit = new EventEmitter();

  // Custom Question
  @Input() specialtyOptions: Select2OptionData[] = [];
  @Input() diagnosisOptions: any[] = [];
  @Output() onChangeSpecialty = new EventEmitter();

  @Input() serviceOptions: Select2OptionData[] = [];
  @Input() facilityOptions: Select2OptionData[] = [];

  form: FormGroup;
  payLoad = '';
 
  constructor(private questionService: QuestionControlService, private fb: FormBuilder) {  }
 
  ngOnInit() {
    if (!this.isTemplate) 
      this.form = this.questionService.toFormGroup(this.questions);
  }

  ngOnChanges() {
    if (this.isTemplate)
      this.form = this.questionService.toFormGroup(this.questions);
  }

  changeSpecialty(specialtyId: string, i) {
    this.onChangeSpecialty.emit({specialtyId: specialtyId, type: SPECIALTY_OPTION_TYPE.diagnosis ,optionIndex: i});
  }
  
  getFormArray(i) {
    let key = this.getFormKey(i);
    return this.form.get(key.toString()) as FormArray;
  }

  getFormKey(i) {
    return this.questions[i].key;
  }

  addDiagnosis(i) {
    this.getFormArray(i).push(this.createDiagnosis());
  }

	removeItem(i, j) {
    this.getFormArray(i).removeAt(j);
	}

  createDiagnosis(): FormGroup {
    return this.fb.group({
      specialty_id: [null, [Validators.required]],
      diagnosis_id: [null, [Validators.required]]
    });
  }

  addService(i) {
    this.getFormArray(i).push(this.createService());
  }

  createService(): FormGroup {
    return this.fb.group({
      service_id: [null, [Validators.required]]
    });
  }
 
  submitForm() {
    //testing
    //this.payLoad = JSON.stringify(this.form.value);
    this.onSubmit.emit(this.form);
  }

  validateRow(i) {
    let validate = true;
    let key = this.questions[i].key;
    if (this.questions[i].required) {
      if (Array.isArray(this.form.value[key])) {
        validate = this.form.controls[key].valid && this.form.value[key].length > 0;
      } else {
        validate = this.form.controls[key].valid;
      }
    }
    return validate;
  }
}
