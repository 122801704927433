import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';
import { Color } from 'ng2-charts-x';
import * as moment from 'moment';

@Component({
  selector: 'app-invoice-transaction',
  templateUrl: './invoice-transaction.component.html',
  styleUrls: ['./invoice-transaction.component.scss']
})
export class InvoiceTransactionComponent implements OnInit {

  invoiceList = [];

  startDate: string;
  endDate: string;
  startDatePicker: Date;
  endDatePicker: Date;
  transactionData: Array<any> = [];
  transactionChartLabels: Array<any> = [];
  fetchDataErr = "";

  type = {
    invoice: 'INVOICE',
    deposit: 'DEPOSIT'
  }
  transaction_method = {
    alipay: 'ALIPAY', 
    unionpay: 'UNIONPAY', 
    insurer: 'INSURER', 
    clinic: 'CLINIC', 
    heals: 'HEALS'
  }
  state = {
    pending: 'PENDING',
    done: 'DONE'
  }
  filter = {
    type: null,
    transactionMethod: null,
    startDay: null,
    endDay: null,
    state: null,
    clinic_name: null,
    invoiceCode: null
  }

  loading = false;
  chartLoading = false;

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  lineChartData: Array<any> = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
    { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
  ];
  lineChartLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  lineChartOptions: any = {
    responsive: true
  };

  lineChartLegend = true;
  lineChartType = 'line';

  constructor(private apiService: ApiService) {  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let start, end;
          if(this.filter['startDay'])
            start = moment(this.filter['startDay']).format('YYYY-MM-DD');
          if(this.filter['endDay'])
            end = moment(this.filter['endDay']).format('YYYY-MM-DD')
          let response = await this.apiService.getInvoice(null, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1, this.filter['type'], this.filter['transactionMethod'], start, end, this.filter['state'], this.filter['clinic_name'], this.filter['invoiceCode'] );
          this.invoiceList = response.data;
          this.loading = false;

          callback({
            recordsTotal: response.pagination.total,
            recordsFiltered: response.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }

    this.endDate = moment().format('YYYY-MM-DD');
    this.startDate = moment().subtract(30, 'day').format('YYYY-MM-DD');
    this.endDatePicker = new Date(this.endDate);
    this.startDatePicker = new Date(this.startDate);

    this.getTransactionChart();
  }

  async getTransactionChart() {
    this.startDate = moment(this.startDatePicker).format('YYYY-MM-DD');
    this.endDate = moment(this.endDatePicker).format('YYYY-MM-DD');
    try {
      this.chartLoading = true;
      this.transactionData = [];
      this.fetchDataErr = "";
      let res = await this.apiService.getTransactionGraph(this.startDate, this.endDate);
      if(res && res.length > 0 && !this.fetchDataErr) {
        let flags = [];
        let typeLabel = [];
        this.transactionChartLabels = [];
        for (let i = 0 ; i < res.length ; i++) {
            if (flags[res[i].method] ) continue;
            flags[res[i].method] = true;
            typeLabel.push(res[i].method);
        }

        flags = [];
        let countDay = 0;
        countDay = moment.duration(moment(this.endDate).diff(moment(this.startDate))).asDays();
        if(countDay > 90) {
            let countMonth = (moment(this.endDate).month() + moment(this.endDate).year()*12) - (moment(this.startDate).month() + moment(this.startDate).year()*12)

            for(let i = 0; i <= countMonth; i++) {
                let label = moment(this.endDate).subtract(countMonth - i, 'months').format('YYYY-MM');
                this.transactionChartLabels.push(label);
            }
        } else {
            for(let i = 0; i <= countDay; i++) {
                let label = moment(this.endDate).subtract(countDay - i, 'days').format('YYYY-MM-DD');
                this.transactionChartLabels.push(label);
            }
        }

        if(countDay > 90) {
            typeLabel.forEach(type => {
                let result = {
                    data: [],
                    label: type,
                    fill: false,
                    lineTension: 0
                };
                this.transactionChartLabels.forEach(label => {
                    let totalAmount = 0;
                    res.forEach(e => {
                        if (e.date.slice(0, 7) === label && e.method === type)
                            totalAmount += e.amount;
                    });
                    result.data.push(totalAmount);
                });
                this.transactionData.push(result);
            });
            console.log(this.transactionData);
        } else {
            typeLabel.forEach(type => {
                let result = {
                    data: [],
                    label: type,
                    fill: false,
                    lineTension: 0
                };
                this.transactionChartLabels.forEach(label => {
                    let totalAmount = 0;
                    res.forEach(e => {
                        if (e.date === label && e.method === type)
                            totalAmount += e.amount/100;
                    });
                    result.data.push(totalAmount);
                });
                this.transactionData.push(result);
            });
            console.log(this.transactionData);
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      this.chartLoading = false;
    }
  }

  async filterInvoice() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }
  
  async resetFilter() {
    if(!this.isEmpty(this.filter)) {
      this.filter = {
        type: null,
        transactionMethod: null,
        startDay: null,
        endDay: null,
        state: null,
        clinic_name: null,
        invoiceCode: null
      }
      let dtInstance = await this.dtElement.dtInstance;
      dtInstance.destroy();
      this.dtTrigger.next();
    }
  }

  isEmpty(obj) {
    for(var key in obj) {
        if(obj[key])
            return false;
    }
    return true;
  }

}
