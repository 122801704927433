import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { ROLE } from '../models/User';

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss']
})
export class OfferListComponent implements OnInit {
  isDoctor = false;
  isAdmin = false;

  constructor(private storage: LocalStorageService) { }

  async ngOnInit() {
    let user = await this.storage.getUserProfile();
    if(user.role === ROLE.doctor) {
      this.isDoctor = true;
    } else if(user.role === ROLE.admin || user.role === ROLE.operator) {
      this.isAdmin = true;
    }
  }

}
