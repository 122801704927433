import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'multimarker-map',
  templateUrl: './multimarker-map.component.html',
  styleUrls: ['./multimarker-map.component.scss']
})
export class MultimarkerMapComponent implements OnInit {
  DEFAULT_ZOOM: number = 16;
  CEN_LAT_LNG = {
    lat: 22.3134127,
    lng: 114.1639514,
  };
  @Input ('markerList1') markerList1;
  @Input ('markerList2') markerList2;
  @Input ('zoom') zoom = 16;
  blueicon: any;
  orangeicon: any;
  loading: boolean;
  loadedData: boolean = false;

  mapStyle = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  constructor() {
    this.blueicon = {
      url: '../../assets/point_blue.png',
      scaledSize: {
        width: 8,
        height: 8
      }
    }

    this.orangeicon= {
      url: '../../assets/point_orange.png',
      scaledSize: {
        width: 8,
        height: 8
      }
    }
  }

  ngOnInit() {
  }

}
