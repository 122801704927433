import { PushNotificationService } from './services/push-notification.service';
import { Component } from '@angular/core';
import { LocalStorageService } from './services/local-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
// import * as firebase from 'firebase';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private localStorage: LocalStorageService, private router:Router, private pushNotification: PushNotificationService, private route: ActivatedRoute, private translate: TranslateService) {
    if(environment.production) {
      window.console.log = () => {};
    }

  }

  async ngOnInit() {
    this.translate.use('en');

    // let config = {
    //   apiKey: "AIzaSyBiijOD8NGq9xUrKHNKAqHkRnKVLD6S0u4",
    //   authDomain: "asia-docnet-mobile.firebaseapp.com",
    //   databaseURL: "https://asia-docnet-mobile.firebaseio.com",
    //   projectId: "asia-docnet-mobile",
    //   storageBucket: "asia-docnet-mobile.appspot.com",
    //   messagingSenderId: "856083810452"
    // };
    // firebase.initializeApp(config);

    // if (await this.localStorage.checkLogin()) {
    //   this.pushNotification.init();
    // }
    this.pushNotification.init();
  }
}
