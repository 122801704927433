import { Service_Item } from './Service_Item';
import { Clinic } from './Clinic';
import { Doctor } from './Doctor';
import { Payor } from './Payor';
import { ContractTemplate } from './ContractTemplate';

export interface Offer {
    id: number,
    contract: string | ContractTemplate,
    effective_date: string,
    status: OFFER_STATUS,
    doctor: string | Doctor,
    payor: Payor,
    clinics: Clinic[],
    services: Service_Item[]
}

export enum OFFER_STATUS {
    doctor = 'WAIT_FOR_DOCTOR_REPLY',
    payor = 'WAIT_FOR_PAYOR_REPLY',
    accept = 'ACCEPTED',
    decline = 'DECLINED',
    terminated = 'TERMINATED'
}

export enum OFFER_TYPE {
    std = "STANDARD" ,
    def = "DEFAULT"
}