import { environment } from '../../environments/environment';

export const PER_PAGE = 10;

export const API_URL = `${environment.api_url}/api/${environment.api_version}/nc`;

export const LOGIN = '/login';
export const DOCTOR_LOGIN = '/doctor/login';
export const RESET_PW = '/forget_password';
export const GET_PLAN_LIST_PATH = '/payor/plans';
export const GET_ALL_PLANS = '/admin/plans';
export const ADD_PLAN_PATH = '/plan';
export const ADD_PLANS_PATH = '/plans';
export const PLAN_DETAIL_PATH = '/plan';
export const UPDATE_PLAN_PATH = '/plan/edit';
export const GET_INSTRUCTION_LIST = '/instructions';
export const PLAN_DOC_PATH = '/plan/documents';
export const UPLOAD_MEDICAL_CARD = '/plan/image';
export const GET_CARD_IMAGE = '/plan/image';
export const GET_PLAN_CLASS = '/plan/class';
export const ASSIGN_PLAN_CLASS = '/plan/class/assign';

export const GET_SERVICE_ITEMS = '/services';
export const ADD_SERVICE_ITEM_PATH = '/service';
export const GET_SERVICE_CATEGORY = '/service/categories';
export const ADD_SERVICE_CATEGORY = '/service/categories';

export const GET_BENEFIT_CATEGORIES = '/benefit/category';

export const GET_SCHEMA_ITEMS = '/schema/items';

export const CONTRACT_TEMPLATE_LIST_PATH = '/payor/contracts';
export const ADD_CONTRACT_TEMPLATE_PATH = '/contract';
export const CONTRACT_TEMPLATE_DETAIL = '/contract';
export const ADMIN_GET_CONTRACT_TEMPLATE = '/admin/contracts';

export const GET_DEFAULT_TEMPLATE = '/default_contracts';
export const GET_DEFAULT_TEMPLATE_DETAIL = '/default_contract';

export const GET_OFFER_LIST = '/payor/offers';
export const GET_OFFER_DETAIL = '/payor/offer';
export const GET_PENDING_OFFER_DETAIL = '/user/pending_offers';
export const REPLY_COUTER_OFFER_PATH = '/offer/reply';
export const NEW_OFFER_PATH = '/offer';
export const ASSIGN_OFFER_TO_DOCNET = '/offerToAllDoctors';
export const TERMINATE_OFFER = '/offer/terminate';
export const ADMIN_GET_OFFER = '/admin/offer';

export const DOCTOR_GET_PENDING_OFFERS = '/doctor/pending_offers';
export const DOCTOR_GET_SIGNED_OFFERS = '/doctor/signed_offers';
export const DOCTOR_GET_TERMINATED_OFFERS = '/doctor/terminated_offers';
export const DOCTOR_GET_OFFER_DETAIL = '/doctor/offer'

export const GET_DOCTORS_LIST = '/getDoctorsBySpecialty';
export const GET_DOCTOR_DETAIL = '/getDoctorDetail';
export const GET_DOCNET_DOCTOR = '/getDoctorClinic';
export const ADMIN_GET_DOCTORS = '/doctor'

export const GET_DOCTOR_CLINIC = '/doctor_clinic';
export const GET_NETWORK_GROUP = '/network_group';
export const UPDATE_NETWORK_GROUP = '/network_group';
export const UPDATE_DOCTOR_CLINIC = '/doctor_clinic';
export const ADD_NETWORK_GROUP_PROVIDERS = '/network_group/doctor_clinic';
export const REMOVE_NETWORK_GROUP_PROVIDERS = '/network_group/doctor_clinic';

export const GET_SPECIALTY_LIST = '/specialties';
export const GET_SPECIALTIES = '/specialty';
export const UPDATE_SPECIALTY = '/specialty';

export const GET_CLINIC_PATH = '/clinic';
export const GET_CLINIC_MANAGEMENT_SYSTEM = '/cms';
export const GET_CLINIC_FTP_URL = '/clinic/ftp_url';

export const REGISTER_PUSH_NOTIFICATION_PATH = '/register_device';

export const GET_PROVIDER_MAPPINGS = '/insurer_mapping/provider';
export const GET_INSURER_CODE_MAPPINGS = '/insurer_mapping/code';

// Preapproval
export const PREAPPROVAL_AUTH = '/preapproval/auth';
export const GET_PREAPPROVAL = '/preapproval';
export const GET_PREAPPROVAL_FACILITY = '/preapproval/facility';
export const GET_PREAPPROVED_ITEMS_DETAIL = '/preapproval/preapprovedItems';
export const GET_PREAPPROVAL_SERVICES = '/preapproval/services';
export const GET_BENEFIT = '/preapproval/benefit';
export const CHECK_PA_STATUS = '/preapproval/clinic/checkStatus';
export const CANCEL_PA = '/preapproval/cancel';
export const CLINIC_CONSENT_PA = '/preapproval/clinic/consent';
export const SUBMIT_PREAPPROVAL = '/preapproval';

// dynamic questions
export const GET_PREAPPROVAL_FIELDS = '/preapproval/field';
export const ADD_PREAPPROVAL_FIELDS_MAPPING = '/preapproval/field/mapping/add';
export const DELETE_PREAPPROVAL_FIELDS_MAPPING = '/preapproval/field/mapping/delete';
export const GET_PREAPPROVAL_FIELDS_MAPPING = '/preapproval/field/mapping';
export const UPDATE_PREAPPROVAL_FIELDS_MAPPING = '/preapproval/field/mapping';
export const GET_PREAPPROVAL_QUESTIONS = '/preapproval/question';
export const REPLY_PREAPPROVAL = '/preapproval/reply';
export const UPDATE_PREAPPROVAL_QUESTIONS = '/preapproval/question';

export const ENROLL_MEMBER = '/enrollment';
export const EDIT_ENROLL_MEMBER = '/enrollment';
export const ENROLL_MEMBER_LIST = '/enrollments';
export const BULK_ADD_MEMBER = '/enrollment/csv';
export const BULK_ADD_ASIA_MEMBER = '/import/asia_enrollment';

export const ADD_UPDATE_PAYOR = '/company';
export const ADD_PAYOR_USER = '/company/user';
export const GET_PAYOR_USER = '/company/user';
export const UPDATE_PAYOR_USER = '/payor/user/update';
export const GET_PAYOR_DETAIL = '/company';
export const GET_PAYOR_LIST = '/company/companies';
export const GET_PAYOR_DOC = '/company/documents';
export const UPLOAD_PAYOR_ICON = '/company/icon';

export const SEARCH_DOCTOR = '/searchDoctor';
export const ADD_DOCTOR = '/doctor';
export const REGISTER_DOCTOR = '/doctor/register';

export const CHANGE_PASSWORD = '/change_password';

export const GET_DISTRICT = '/district';
export const ADD_OR_EDIT_DISTRICT = '/district';

export const GET_DIAGNOSIS = '/diagnosis';

export const GET_COPAY_TYPE = '/copay_type';
export const ADD_DIAGNOSIS = '/diagnosis';
export const EDIT_DIAGNOSIS = '/diagnosis';

export const DASH_PENDING = '/dashboard/offer/pending';
export const DASH_NETWORKGROUP = '/dashboard/map/network_group';
export const DASH_SIGNED_OFFER = '/dashboard/map/offer';
export const DASH_AGREE_FEE = '/dashboard/offer/agree_fee';

export const EDI_PATH = '/admin/asia/edi';

export const ASIA_MEMBER_TEMPLATE = '/import/asia_enrollment/sample';
export const ASIA_PLAN_TEMPLATE = '/plans/excel_sample';
export const MEMBER_ENROLLMENT_TEMPLATE = '/enrollment/csv/sample';

export const VOUCHER_EXPORT_PATH = '/admin/voucher/export';
export const AUDIT_PATH = '/admin/audit';

// Import files
export const IMPORT_CLINIC = '/csv/clinic';
export const IMPORT_DIAGNOSIS = '/import/diagnoses';
export const IMPORT_DISTRICT = '/csv/district';
export const IMPORT_DOCTOR = '/csv/doctor';
export const IMPORT_DOCTOR_CLINIC = '/csv/doctor_clinic';
export const IMPORT_SERVICE = '/import/services';
export const IMPORT_VOUCHER = '/admin/voucher/import';

export const HEALS_USER_STATUS = '/admin/user/status';
export const HEALS_USER_PATH = '/admin/user/heals';

export const DEMO_OFFER = '/demo/offer';
export const REMOVE_DEMO_OFFER_PATH = '/demo/offer';
export const DEMO_VISIT = '/demo/visit';
export const DEMO_PREAPPROVAL = '/demo/preapproval';
export const DEMO_PREAPPROVAL_RESET = '/demo/preapproval';

export const APP_VERSION = '/product';

export const EMAIL_ANNOUNCEMENT_PATH = '/announcement';

export const CMS_PATH = '/cms';

export const INVOICE_PATH = '/billing/invoices';
export const TRANSACTION_GRAPH_PATH = '/billing/transaction/graph';
export const VOUCHER_PDF_PATH = '/billing/invoice/download';
export const SUBMIT_VOUCHER_PATH = '/billing/invoice/submit';
export const UPDATE_INVOICE_DIAGNOSES = '/billing/invoice/diagnoses';
export const UPDATE_INVOICE_ITEMS = '/billing/invoice/items';
export const UPDATE_INVOICE_TRANSACTIONS = '/billing/invoice/transaction';
export const ALIPAY_REFUND_PATH = '/billing/transaction/refund'