import { FetchDoctorsResponse } from './../response/FetchDoctorsResponse';
import { Doctor } from './../models/Doctor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Specialty } from '../models/Specialty';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-manage-doctor',
  templateUrl: './manage-doctor.component.html',
  styleUrls: ['./manage-doctor.component.scss']
})
export class ManageDoctorComponent implements OnInit {
  
  doctors: Doctor[] = [];
  specialties: Specialty[] = [];
  specialtyOptions: Select2OptionData[] = [];
  loading;
  dtOptions: DataTables.Settings;

  modalLoading: boolean;
  importSuccess: boolean;
  importErr: String[];
  file: File;

  searchDoctor = {
    name: null,
    nc_user: false,
    specialty_id: null,
    network_group: null
  }

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(private apiService: ApiService) { }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    this.fetchDoctorList();
    this.fetchSpecialty();
  }

  async fetchDoctorList() {
    this.loading = true;
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let doctors = <FetchDoctorsResponse>await this.apiService.adminGetDoctorList(null, this.searchDoctor.specialty_id, this.searchDoctor.name, this.searchDoctor.nc_user, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
          this.doctors = doctors.data;
          this.loading = false;

          callback({
            recordsTotal: doctors.pagination.total,
            recordsFiltered: doctors.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }

    // try {
    //   this.loading = true
    //   this.doctors = await this.apiService.adminGetDoctorList();
    // } catch(err) {
    //   console.log(err);
    // } finally {
    //   this.loading = false;
    // }
  }

  async fetchSpecialty() {
    try {
      this.specialties = <Specialty[]>await this.apiService.getSpecialty(false);
      this.specialtyOptions = this.specialties.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
    } catch (err) {
      console.log(err)
    }
  }

  async filterDoctor() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  async import() {
    if (!this.file) return;

    try {
      this.modalLoading = true;
      await this.apiService.importFile(this.file, 'DOCTOR', null);
      this.importSuccess = true;
    } catch(err) {
      console.log(err);
      this.importErr = err.error.error_messages;
    } finally {
      this.modalLoading = false;
    }
  }

  resetModal() { 
    this.importSuccess = false;
    this.importErr = null;
  }

  onFileChange(event: DataTransfer){
    this.file = event.files[0];
  }
}
