import { Injectable } from '@angular/core';
import { Question, ANSWER_TYPE, ANSWER_BOOLEAN } from '../models/questions/Question';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { PreapprovalQuestion } from '../models/PreapprovalQuestion';
import { QuestionCustom, QuestionTextbox, QuestionDropdown, QuestionRadio, QuestionDate } from '../models/PreapprovalFormQuestion';

@Injectable({
  providedIn: 'root'
})
export class QuestionControlService {

  constructor(private fb: FormBuilder) { }

  toFormGroup(questions: Question<any>[] ) {
    let group: any = {};

    questions.forEach(question => {
      if (question.isArray) {
        group[question.key] = this.fb.array([]);
        // group[question.key] = this.fb.array([this.createDiagnosis()]);
      } else {
        group[question.key] = new FormControl(question.value || '', question.required? Validators.required : null);
      }
    });
    return new FormGroup(group);
  }

  getPreapprovalFormQuestions(questionList: PreapprovalQuestion[]) {
    let questions: Question<any>[] = [];
    questionList.forEach((question) => {
      switch(ANSWER_TYPE[question.type]) {
        case ANSWER_TYPE.STRING:
          questions.push(
            new QuestionTextbox({
              key: question.field_mapping_id,
              value: question.answer,
              label: question.question,
              // type: 'email',
              required: question.required,
              questionNo: question.question_no,
              order: question.order,
            }),
          );
          break;
        case ANSWER_TYPE.DROPDOWN:
          questions.push(
            new QuestionDropdown({
              key: question.field_mapping_id,
              value: question.answer,
              label: question.question,
              options: question.options.map((item) => ({id: item.id, text: item.text})),
              required: question.required,
              questionNo: question.question_no,
              order: question.order,
            })
          );
          break;
        case ANSWER_TYPE.BOOLEAN:
          questions.push(
            new QuestionRadio({
              key: question.field_mapping_id,
              value: question.answer,
              label: question.question,
              options: Object.keys(ANSWER_BOOLEAN).map(key => {
                  return { 
                  key: ANSWER_BOOLEAN[key],
                  value: key, 
                  };
              }),
              required: question.required,
              questionNo: question.question_no,
              order: question.order,
            })
          );
          break;
        case ANSWER_TYPE.DATE:
          questions.push(
            new QuestionDate({
              key: question.field_mapping_id,
              value: question.answer,
              label: question.question,
              required: question.required,
              questionNo: question.question_no,
              order: question.order,
            }),
          );
          break;
        case ANSWER_TYPE.DIAGNOSIS:
          questions.push(
            new QuestionCustom({
              key: question.field_mapping_id,
              value: question.answer,
              label: question.question,
              required: question.required,
              questionNo: question.question_no,
              order: question.order,
              isArray: true
            }, 'diagnosis'),
          );
          break;
        case ANSWER_TYPE.SERVICE:
          questions.push(
            new QuestionCustom({
              key: question.field_mapping_id,
              value: question.answer,
              label: question.question,
              required: question.required,
              questionNo: question.question_no,
              order: question.order,
              isArray: true
            }, 'service'),
          );
          break;
        case ANSWER_TYPE.HOSPITAL:
          questions.push(
            new QuestionCustom({
              key: question.field_mapping_id,
              value: question.answer,
              label: question.question,
              required: question.required,
              questionNo: question.question_no,
              order: question.order
            }, 'hospital'),
          );
          break;
      }
    });
    return questions.sort((a, b) => a.order - b.order);
  }

}
