import { DashboardAgreeFee } from './../models/DashboardAgreeFee';
import { Offer } from './../models/Offer';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PER_PAGE} from '../constant/config';
import { FetchDashboardAgreeFeesResponse } from '../response/FetchDashboardAgreeFeesResponse';
import { User, ROLE } from '../models/User';
import { LocalStorageService } from '../services/local-storage.service';
import { Router, NavigationEnd, Event } from '@angular/router';
import { environment } from '../../environments/environment';

const TOTAL_STEP = 3;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  pendingList: Offer[] = [];
  signedList: Offer[] = [];
  networkGroupList = [];
  agreeFeeList: DashboardAgreeFee[] = [];
  pendingLoading: boolean = false;
  signedLoading: boolean = false;
  docnetLoading: boolean = false;
  agreeFeeLoading: boolean = false;
  user: User;
  role = ROLE;

  dtOptions: DataTables.Settings;
  urlSubscription;

  currentStep = 1;

  usefulLinkList = [
    { name: 'home.alipay_online' , url: 'https://global.alipay.com/ilogin/account_login.htm' },
    { name: 'home.alipay_offline' , url: 'https://alipay.healshealthcare.com/auth/login' },
    { name: 'home.heals_website' , url: 'https://www.healshealthcare.com' },
    { name: 'home.docnet_website' , url: 'https://www.docnet.asia' },
    // { name: 'home.unipay_portal' , url: '' },
    { name: 'home.crm_website' , url: 'https://crm.zoho.com' },
    { name: 'home.cs_api_test', url: 'https://www.softlinkmedical.com.hk:3008/'},
    { name: 'home.log_viewer' , url: `${environment.api_url}/log-viewer` }
  ]

  constructor(private apiService: ApiService, private storage: LocalStorageService, private router: Router) { 
    this.urlSubscription = this.router.events.subscribe(async (event: Event) => {
      if (event instanceof NavigationEnd) {
        if(event.url === '/' && event.urlAfterRedirects === '/home') {
          let user = await this.storage.getUserProfile()
          if(user && user['role']=== ROLE.payor)
            $('#modal-default').modal({backdrop:'static',keyboard:false, show:true});
        }
      }
    });
  }

  async ngOnInit() {
    // $('#modal-default').modal({backdrop:'static',keyboard:false, show:true});
    this.fetchAgreeFee();
    this.user = await this.storage.getUserProfile();
    if(this.user.role === ROLE.admin || this.user.role === ROLE.operator || this.user.role === ROLE.payor) {
      this.fetchSignedOffer();
      this.fetchNetworkGroup();
      this.fetchPendingOffer();
    }
  }

  ngOnDestroy(): void {
    this.urlSubscription.unsubscribe();
  }

  nextStep() {
    if(++this.currentStep > 3)
      $('#modal-default').modal('hide');
  }

  previousStep() {
    this.currentStep--;
  }

  async fetchPendingOffer() {
    try {
      this.pendingLoading = true;
      this.pendingList = await this.apiService.dashboardGetPendingOffer();
    } catch (err) {
      console.log(err)
    } finally {
      this.pendingLoading = false;
    }
  }
  
  async fetchSignedOffer() {
    try {
      this.signedLoading = true;
      this.signedList = await this.apiService.dashboardGetSignedOffer();
    } catch (err) {
      console.log(err)
    } finally {
      this.signedLoading = false;
    }
  }
  
  async fetchNetworkGroup() {
    try {
      this.docnetLoading = true;
      this.networkGroupList = await this.apiService.dashboardGetNetworkGroup();
    } catch (err) {
      console.log(err)
    } finally {
      this.docnetLoading = false;
    }
  }

  async fetchAgreeFee() {
    try {
      this.agreeFeeLoading = true;
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          if(!this.user)
            this.user = await this.storage.getUserProfile();
          if(this.user.role === ROLE.admin || this.user.role === ROLE.operator || this.user.role === ROLE.payor) {
            let response = <FetchDashboardAgreeFeesResponse>await this.apiService.dashboardGetAgreeFee(true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
            this.agreeFeeList = response.data;

            callback({
              recordsTotal: response.pagination.total,
              recordsFiltered: response.pagination.total,
              data: []
            });
          }
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.agreeFeeLoading = false;
    }
  }

}
