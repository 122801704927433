import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { LocalStorageService } from '../services/local-storage.service';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Question } from '../models/questions/Question';
import { FormGroup } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { QuestionControlService } from '../services/question-control.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PreapprovalFormService } from '../services/preapproval-form.service';
import { INSURER_ID } from '../models/Insurer';
import { SCHEMA_TYPE } from '../models/Schema';

@Component({
  selector: 'app-preapproval',
  templateUrl: './preapproval.component.html',
  styleUrls: ['./preapproval.component.scss'],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class PreapprovalComponent implements OnInit {
	preapproveDetail;
	paToken: string = null;
	insurerId: string = null;

	translatedText: any[] = [];

	loading: boolean;
	validate: boolean = true;

	modelLoading: boolean;
	deleteSuccess: boolean;
	deleteErrorMsg: string;
	submitSuccess: boolean;
	submitErrorMsg: string;

	// Dynamic Questions
	formQuestions: Question<any>[];
	questions: any[] = [];

	form: FormGroup;
	paDetails: Object;
	insurerPayload: Object;	// constructed pa data

	// for ML
	benefitCategoryId: string = null;
	facilityId: string = null;
	items: any[] = null;
	diagnosisOptions: Select2OptionData[] = [];
	facilityOptions: Select2OptionData[] = [];
	serviceItems: any[] = [];
	
  constructor(private apiService: ApiService, private preapprovalService: PreapprovalFormService, private storage: LocalStorageService, private route: ActivatedRoute, private router: Router, private questionService: QuestionControlService, private toastr: ToastrService, private translate: TranslateService) {
		this.translate.get([
			'error',
			'success',
			'save_error',
			'preapproval.save_form_success_msg'
		]).subscribe(res => this.translatedText = res);
	}

	async ngOnInit() {
		this.route.queryParams.subscribe(async (params) => {
			if (params['clinicToken'] && params['paToken']) {
				this.loading = true;
				this.paToken = params['paToken'];
				try {
					let auth = await this.apiService.preapprovalAuthentication(params['clinicToken'], params['paToken']);
					await this.storage.setUserProfile(auth);
					await this.storage.setVisitToken(auth.api_token);
					await this.fetchFormDetail(this.paToken);
				} catch (err) {
					console.log(err);
					this.router.navigate(['/home']);
				} finally {
					this.loading = false;
				}
			} else {
				this.router.navigate(['/home']);
			}
		});
	}

	async getFacilities(insurerId, doctorId) {
		let res = await this.apiService.getPreapprovalFacilities(insurerId, doctorId);
		let facilities = [];
		res.forEach(item => {
			if (item.clinic_id) {
				facilities.push({
					id: item.code,
					text: item.clinic_name,
					additional: item.clinic_id
				});
			}
		});
		this.facilityOptions = facilities;
	}

	async fetchFormDetail(paToken: string) {
    	try {
			this.preapproveDetail = await this.apiService.getPreapproval(paToken);
			this.insurerId = this.preapproveDetail.insurer_id;
			this.benefitCategoryId = this.preapproveDetail.benefit_category_id;
			this.facilityId = this.preapproveDetail.facility_id;
			this.getFacilities(this.insurerId, this.preapproveDetail.doctor_id);
			this.fetchDiagnosis();

			let initData = this.preapproveDetail.patient_info;
			initData.clinic_name = this.preapproveDetail.clinic_name;
			initData.clinic_phone = this.preapproveDetail.clinic_phone;
			initData.clinic_email = this.preapproveDetail.clinic_email;
			initData.doctor_name = this.preapproveDetail.doctor_name;
			initData.policy_no = this.preapproveDetail.policy_no;

			let formDetails = this.preapproveDetail.form_details;
			this.serviceItems = formDetails? formDetails.serviceItems : null;
			
			this.form = this.preapprovalService.getInsurerPreapprovalForm(this.insurerId, formDetails, initData, false);	
			if (this.insurerId !== INSURER_ID.MANULIFE) {
				let questionList = this.preapproveDetail.questions;
				this.formQuestions = this.questionService.getPreapprovalFormQuestions(questionList);
			}
    	} catch (err) {
      		console.log(err);
    	}
	}

	async fetchDiagnosis() {
		let res = await this.apiService.getPreapprovalSchemaItems(null, null, SCHEMA_TYPE.DIAGNOSIS);
		let diagnosis = [];
		res.forEach(item => diagnosis.push({
			id: item.name,
			text: item.name
		}));
		this.diagnosisOptions = diagnosis;
	}

	async sendForm(submit: boolean = false) {
		try {
			this.loading = true;
			this.modelLoading = true;
			this.submitErrorMsg = "";
			// let preapprovalForm =JSON.parse(JSON.stringify(this.preapproveDetail));

			let preapprovalForm = {
				benefit_category_id: this.benefitCategoryId,
				facility_id: this.facilityId,
				pa_token: this.preapproveDetail.pa_token,
				pa_details: this.paDetails,
				submit: submit,
				items: this.items,
				insurer_payload: this.insurerPayload
			};
			await this.apiService.submitPreapproval(preapprovalForm);
			if (submit) {
				this.submitSuccess = true;
			} else {
				this.toastr.success(this.translatedText['preapproval.save_form_success_msg'], this.translatedText['success'], {
					positionClass: "toast-bottom-full-width"
				});
			}
		} catch (err) {
			console.log(err);
			if (submit) {
				this.submitErrorMsg = err.error.error_messages;
			} else {
				this.toastr.error( err.error.error_messages, this.translatedText['save_error'], {
					positionClass: "toast-bottom-full-width"
				});
			}
		} finally {
			this.loading = false;
			this.modelLoading = false;
		}
	}

	onDelete() {
		$('#modal-delete').modal({backdrop: 'static', keyboard: false, show: true});
	}

	async deletePreapproval() {
		try {
			this.modelLoading = true;
			await this.apiService.clinicCancelPa(this.paToken);
			this.deleteSuccess = true;
		} catch (err) {
			console.log(err);
			this.deleteErrorMsg = err.error.error_messages;
		} finally {
			this.modelLoading = false;
		}
	}

	onSubmit(data) {
		this.benefitCategoryId = data.benefitCategoryId;
		this.facilityId = data.facilityId;
		this.items = data.items;
		let formData = data.formData;
		let insurerData = data.insurerData;
		if (data.submit) {
			try {
				this.loading = true;
				this.validate = false;
				this.submitErrorMsg = "";
				if (this.insurerId === INSURER_ID.MANULIFE) {
					this.paDetails = formData;
					this.insurerPayload = insurerData;
				} else {
					this.validateDynamicForm();
				}
				$('#modal-submit').modal({backdrop: 'static', keyboard: false, show: true});
			} catch (err) {
				console.log(err);
			} finally {
				this.loading = false;
			}
		} else {
			this.paDetails = formData;
			this.sendForm(false);
		}
	}

	validateDynamicForm() {
		this.questions = [];
		for (let i = 0; i < this.formQuestions.length; i++) {
			let key = this.formQuestions[i].key;
			if (this.formQuestions[i].required) {
				if (Array.isArray(this.form.value[key]) && this.form.value[key].length === 0 ) return;
				if (!this.form.controls[key].valid) return;
			}
			this.questions.push({ id: key, answer: this.form.value[key] instanceof Date? this.preapprovalService.parseDate(this.form.value[key]) : this.form.value[key] });
		}
	}

  	print() {
		window.print();
	}

	// async setOptionsbySpecialty(val: any) {
	// 	let specialtyId = val.specialtyId;
	// 	let i = val.optionIndex;
	// 	try {
	// 		switch (val.type) {
	// 			case SPECIALTY_OPTION_TYPE.diagnosis:
	// 				if (!this.diagnosisList.get(specialtyId)) {
	// 					await this.setDiagnosis(specialtyId);
	// 				}
	// 				this.diagnosisOptions[i] = <Select2OptionData[]> this.diagnosisList.get(specialtyId).map(item => {
	// 					return {
	// 						id: item.id,
	// 						text: item.name,
	// 						additional: item.scheme
	// 					};
	// 				});
	// 				break;
	// 			case SPECIALTY_OPTION_TYPE.service:
	// 				if (!this.serviceList.get(specialtyId)) {
	// 					await this.setService(specialtyId);
	// 				}
	// 				this.serviceOptions[i] = <Select2OptionData[]> this.serviceList.get(specialtyId).map(item => {
	// 					return {
	// 						id: item.id,
	// 						text: item.name,
	// 						additional: item.fee
	// 					};
	// 				});
	// 				break;
	// 			default:
	// 		}

	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// }

	// async setDiagnosis(specialtyId) {
	// 	let diagnoses = await this.apiService.getDiagnosis(null, specialtyId);
	// 	this.diagnosisList.set(specialtyId, diagnoses);
	// }
}
