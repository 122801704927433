import { Specialty } from './../models/Specialty';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { Select2OptionData } from 'ng-select2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { PER_PAGE } from '../constant/config';
import { FetchSpecialtyList } from '../response/FetchSpecialtyList';

@Component({
  selector: 'app-specialty',
  templateUrl: './specialty.component.html',
  styleUrls: ['./specialty.component.scss']
})
export class SpecialtyComponent implements OnInit {
  specialties: Specialty[] = [];
  fullSpecialties: Specialty[] = [];
  rankList: Specialty[] = [];
  specialtyOptions: Select2OptionData[] = [];
  specialtyDetail:Specialty;
  editErrorMsg: string;
  editSubmitSuccess: boolean;
  submitLoading: boolean;
  loading: boolean;
  mode;
  specialtyIcon;
  specialtyIconURL;
  RANK_MAX_LENGTH = 10;

  searchSpecialty = {
    name: null
  }

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(private apiService: ApiService) { }

  async ngOnInit() {
    this.fetchSpecialty();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async fetchSpecialty() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let response = <FetchSpecialtyList>await this.apiService.getSpecialty(true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1, this.searchSpecialty.name);
          this.specialties = response.data;
          this.loading = false;

          callback({
            recordsTotal: response.pagination.total,
            recordsFiltered: response.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
    try {
      this.loading = true;

      this.fullSpecialties = <Specialty[]>await this.apiService.getSpecialty(false);
      this.specialtyOptions = this.fullSpecialties.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async openModal(mode, i?) {
    this.mode = mode;
    this.specialtyIcon = null;
    this.specialtyIconURL = null;
    this.editErrorMsg = "";
    if(mode === 'add') { 
      this.specialtyDetail = {
        id: null,
        name: null,
        zh_hans_name: null,
        zh_hant_name: null,
      }
    } else {
      this.editSubmitSuccess = false;
      this.specialtyDetail = this.specialties[i];
    }
  }

  async saveEdit(mode) {
    let update = []
    if(mode === 'edit') {
      update.push({
        id : this.specialtyDetail.id,
        en_name: this.specialtyDetail.name,
        hant_name: this.specialtyDetail.zh_hant_name,
        hans_name: this.specialtyDetail.zh_hans_name,
        rank: this.specialtyDetail.rank,
        icon: this.specialtyIcon
      });
    } else if(mode === 'rank'){
      this.rankList.forEach(item => {
        update.push({
          id : item.id,
          // en_name: item.name,
          // hant_name: item.zh_hant_name,
          // hans_name: item.zh_hans_name,
          rank: item.rank
          // icon: item.icon _blob
        });
      })
    }
    try {
      this.submitLoading = true;
      await this.apiService.editSpecialty(update);
      this.editSubmitSuccess = true;
      this.fetchSpecialty();
    } catch (err) {
      console.log(err)
      this.editErrorMsg = err.error.error_messages;
    } finally {
      this.submitLoading = false;
    }
  }

  onFileChange(event: DataTransfer){
    this.specialtyIcon = event.files[0];

    var reader = new FileReader();
    reader.onload = e => this.specialtyIconURL = reader.result;
    reader.readAsDataURL(this.specialtyIcon);
  }

  async openRankModal() {
    this.rankList = this.fullSpecialties.filter(function (el) {
      return el.rank
    });
    this.rankList.sort((a, b) => a.rank - b.rank);
  }

  addRank() {
    if(this.rankList.length < this.RANK_MAX_LENGTH) {
      this.rankList.push({
        id: null,
        name: null,
        zh_hans_name: null,
        zh_hant_name: null,
        rank: null
      })
    }
  }

  async refreshList() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }
}
