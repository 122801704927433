import { PUSH_NOTI_CONFIG, PUBLIC_VAPID_KEY } from './../constant/firebase-config';
import { ApiService } from './api-service';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  private messaging: firebase.messaging.Messaging;

  constructor(private apiService: ApiService, private toastr: ToastrService) {
    try {
      firebase.initializeApp(PUSH_NOTI_CONFIG);
      this.messaging = firebase.messaging();
      this.messaging.usePublicVapidKey(PUBLIC_VAPID_KEY);
    } catch (err) {
      console.error(err);
    }
  }

  async init() {
    try {
      let token = await this.messaging.getToken();

      if (token) {
        await this.apiService.registerPushNotification(token);
        console.log('registered');

        await this.messaging.requestPermission();
        console.log('Notification permission granted.');

        this.messaging.onMessage(payload => {
          console.log('Message received. ', payload);
          this.toastr.info( payload.notification.body, payload.notification.title, {
            disableTimeOut: true
          });
          new Notification(payload.notification.body, {
            icon: '../../assets/docnetHori.png'
          });
        });
      } else {
        console.log('No Instance ID token available. Request permission to generate one.');
      }
    } catch (err) {
      console.error('Unable to get permission to notify.', err);
    }
  }
}
