import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { Clinic } from '../models/Clinic';
import { Doctor } from '../models/Doctor';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../services/local-storage.service';
import { PER_PAGE } from '../constant/config';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CMS } from '../models/CMS';
import { environment } from '../../environments/environment';


enum RECIPIENT_TYPE {
  all = "All",
  clinic = "Clinic",
  insurer = "Insurer",
  cms = "CMS"
};

enum STATUS {
  sent = "Sent",
  pending = "Pending"
};

@Component({
  selector: 'app-miscellaneous',
  templateUrl: './miscellaneous.component.html',
  styleUrls: ['./miscellaneous.component.scss']
})

export class MiscellaneousComponent implements OnInit {
	
  offerList = [];
  visitRecord: {
    clinic: Clinic,
    doctor: Doctor,
    visit: {
      visit_token: string,
      ticket_type: string,
      arrival_time: string,
      user: {
        name:string
      },
      state: string,
      patient_id: string,
      medical_cards: string,
      insurer_id: string,
      insurer_name: string
    }[]
  };

  preapproval;
  patientAppVersion = [];
  doctorAppVersion = [];
  productList = [];
  emailList = [];
  cmsList = [];
  categoryList = [];

  cmsDetail: CMS;
  newCMS: CMS = {
    name: "",
    company: "",
    communication_email: "",
    default_remote_is_full: false,
    allow_multiple_transaction: false,
    allow_websocket_retry: false,
    show_on_heals_app: false,
    websocket_retry_number: 0,
    websocket_req_ticket_status: false,
    websocket_req_ticket_book: false,
    websocket_cancel_remote_ticket: false,
    websocket_confirm_remote_ticket: false,
    websocket_req_appointment_book: false,
    websocket_notify_appointment_book: false,
    websocket_confirm_appointment_book: false,
    websocket_reject_appointment_book: false,
    websocket_payment_status: false,
    websocket_doctor_app_otp: false,
    websocket_patient_check_in: false
  }
  cmsUpdateSuccess = false;
  cmsUpdateError = null;

  emailDetail;
  newEmail = {
    type: "",
    content: "",
    note: "",
    recipient_type: "",
    schedule_at: null
  }

  recipientTypeList = RECIPIENT_TYPE;
  statusList = STATUS;
  emailTypeList = [
    { name: 'Deployment' }
  ];
  submitBtn = false;
  filterEmail = {
    status: null,
    recipient_type: null
  }

  translateText = [];

  demoVisitErr = "";
  demoPreapprovalErr = "";
  env = environment.env;

  schedule_at: Date;
  today;

  fetchedDemo = false;
  fetchedEmail = false;
  fetchedCategory = false;

  loading = false;
  submitLoading = false;
  submitSuccess:boolean = false;
  errorMsg:string = '';

  dtOptions: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(private apiService: ApiService, private toastr: ToastrService, private translate: TranslateService, private storage: LocalStorageService, private datePipe: DatePipe) {
    translate.get([
      'miscellaneous.label_version_updated',
      'miscellaneous.msg_email_sent',
      'submit_error',
      'success'
    ]).subscribe((text:string[]) => {
      this.translateText = text;
    });
    let today = new Date;
    this.today = {
      year: today.getFullYear(),
      month: (today.getMonth()) + 1,
      day: today.getDate()
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let response = await this.getEmailAnnoucementRecord(true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
          this.emailList = response.data;
          this.loading = false;

          callback({
            recordsTotal: response.pagination.total,
            recordsFiltered: response.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      
    }
    await this.fetchAppVersion();
  }

  changeTab(e) {
    switch (e) {
      case 'tab2':
      if(!this.fetchedDemo) {
        this.getDemoOffer();
        this.getDemoVisit();
        this.getDemoPreapproval();
      }
      this.fetchedDemo = true;
      break;
      case 'tab3':
      break;
      case 'tab4':
      if(!this.fetchedCategory) {
        this.getCMSList();
        this.getServiceCategoryList();
      }
      this.fetchedCategory = true;
      break;
    }
  }
  
  async getDemoOffer() {
	  try {
      this.loading = true;
		  this.offerList = await this.apiService.getDemoOfferId();
	  } catch (err) {
		  console.log(err)
	  } finally {
      this.loading = false;
    }
  }

  async removeOffer(id) {
    try {
      this.loading = true;
      await this.apiService.removeDemoOffer(id);
      this.offerList.splice(this.offerList.findIndex(x => x.id === id), 1);
	  } catch (err) {
		  console.log(err)
	  } finally {
      this.loading = false;
    }
  }

  async getDemoVisit() {
    try {
      this.loading = true;
      this.demoVisitErr = "";
      this.visitRecord = await this.apiService.getDemoVisitRecord();
	  } catch (err) {
      console.log(err)
      this.demoVisitErr = err.error.error_messages[0];
	  } finally {
      this.loading = false;
    }
  }

  async addRemoteVisit() {
    try {
      this.loading = true;
      await this.apiService.addDemoVisit();
	  } catch (err) {
		  console.log(err)
	  } finally {
      this.loading = false;
    }
  }

  async getDemoPreapproval() {
    try {
      this.loading = true;
      this.demoPreapprovalErr = "";
      this.preapproval = await this.apiService.getDemoPreapproval();
	  } catch (err) {
      console.log(err)
      this.demoPreapprovalErr = err.error.error_messages[0];
	  } finally {
      this.loading = false;
    }
  }

  async resetPreapproval() {
    try {
      this.loading = true;
      this.demoPreapprovalErr = "";
      this.preapproval = await this.apiService.resetDemoPreapproval();
	  } catch (err) {
      console.log(err)
      this.demoPreapprovalErr = err.error.error_messages[0];
	  } finally {
      this.loading = false;
    }
  }

  async fetchAppVersion() {
    try {
      this.loading = true;
      let res = await this.apiService.getAppVersion();
      this.productList = res;
	  } catch (err) {
		  console.log(err)
	  } finally {
      this.loading = false;
    }
  }

  async updateVersion(product_id, row) {
    console.log(product_id);
    console.log(row)
    let param = {
      product_id: product_id,
      prefix:row.prefix,
      name:row.name,
      type:row.type,
      version:row.version,
      ios_support_build_no:row.ios_support_build_no,
      ios_latest_build_no:row.ios_latest_build_no,
      android_support_build_no:row.android_support_build_no,
      android_latest_build_no:row.android_latest_build_no,
    }

    try {
      this.loading = true;
      await this.apiService.updateAppVersion(param);
      this.toastr.success( this.translateText['miscellaneous.label_version_updated'],this.translateText['success'], {
        positionClass: "toast-bottom-full-width"
      });
	  } catch (err) {
      console.log(err)
      this.toastr.error( err.error.error_messages , this.translateText['submit_error'], {
        positionClass: "toast-bottom-full-width"
      });
	  } finally {
      this.loading = false;
    }
  }

  async getEmailAnnoucementRecord(pagination, per_page, page) {
	  try {
      this.loading = true;
		  return await this.apiService.getEmailAnnouncement(null, pagination, per_page, page, this.filterEmail.status, this.filterEmail.recipient_type);
	  } catch (err) {
		  console.log(err)
	  } finally {
      this.loading = false;
    }
  }

  async getEmailDetail(id) {
    try {
      this.loading = true;
		  this.emailDetail =  await this.apiService.getEmailAnnouncement(id);
	  } catch (err) {
		  console.log(err)
	  } finally {
      this.loading = false;
    }
  }

  async getCMSList() {
    try {
      this.loading = true;
		  this.cmsList = await this.apiService.getCMS();
	  } catch (err) {
		  console.log(err)
	  } finally {
      this.loading = false;
    }
  }

  async getCMSById(id) {
    try {
      this.loading = true;
      this.cmsUpdateSuccess = false;
      this.cmsUpdateError = null;
      this.cmsDetail = await this.apiService.getCMS(id);
      $('#modal-edit-cms').modal('show');
	  } catch (err) {
		  console.log(err)
	  } finally {
      this.loading = false;
    }
  }

  async addUpdateCMS(param) {
    try {
      this.submitLoading = true;
      await this.apiService.updateCMSDetail(param);
      this.cmsUpdateSuccess = true;
      await this.getCMSList();
      if(!param.id) {
        this.newCMS = {
          name: "",
          company: "",
          communication_email: "",
          default_remote_is_full: false,
          allow_multiple_transaction: false,
          allow_websocket_retry: false,
          show_on_heals_app: false,
          websocket_retry_number: 0,
          websocket_req_ticket_status: false,
          websocket_req_ticket_book: false,
          websocket_cancel_remote_ticket: false,
          websocket_confirm_remote_ticket: false,
          websocket_req_appointment_book: false,
          websocket_notify_appointment_book: false,
          websocket_confirm_appointment_book: false,
          websocket_reject_appointment_book: false,
          websocket_payment_status: false,
          websocket_doctor_app_otp: false,
          websocket_patient_check_in: false
        }
      }
      this.submitSuccess = true;
	  } catch (err) {
      console.log(err)
      this.cmsUpdateError = err.error.error_messages;
      this.errorMsg = err.error.error_messages;
      this.submitSuccess = false;
	  } finally {
      this.submitLoading = false;
    }
  }

  newCMSModal() {
    this.cmsUpdateSuccess = false;
    this.cmsUpdateError = null;
    $('#modal-add-cms').modal('show');
  }

  async getServiceCategoryList() {
    try {
      this.loading = true;
		  this.categoryList = await this.apiService.getServiceCategory();
	  } catch (err) {
		  console.log(err)
	  } finally {
      this.loading = false;
    }
  }

  async sendMail() {
    this.submitBtn = true;
    console.log(this.newEmail)
    let email = this.newEmail;
    email.schedule_at = this.datePipe.transform(this.schedule_at, 'yyyy-MM-dd');
    try {
      this.submitLoading = true;
      await this.apiService.sendEmailAnnouncement(email);
      this.resetEmail();
      this.toastr.success( this.translateText['miscellaneous.msg_email_sent'],this.translateText['success'], {
        positionClass: "toast-bottom-full-width"
      });
      this.submitBtn = false;
      this.submitSuccess = true;
	  } catch (err) {
      console.log(err);
      this.submitSuccess = false;
      this.errorMsg = err.error.error_messages;
	  } finally {
      this.submitLoading = false;
    }
  }

  async copyToNewEmail() {
    this.newEmail.content = this.emailDetail.main_content;
    this.newEmail.note = this.emailDetail.other_note;
    $('#modal-view-mail').modal('hide');
    $('#modal-new-mail').modal('show');
  }

  resetEmail() {
    this.newEmail = {
      type: "",
      content: "",
      note: "",
      recipient_type: "",
      schedule_at: null
    }
    this.schedule_at = null;
  }

  async refreashEmail() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

}
