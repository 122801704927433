import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PER_PAGE } from '../constant/config';
import { ROLE, User } from '../models/User';
import { LocalStorageService } from '../services/local-storage.service';
import { ApiService } from '../services/api-service';


@Component({
  selector: 'app-manage-payor',
  templateUrl: './manage-payor.component.html',
  styleUrls: ['./manage-payor.component.scss']
})
export class ManagePayorComponent implements OnInit {
  user: User;
  payors = {data: []};
  loading: boolean;
  dtOptions: DataTables.Settings;

  constructor(private apiService: ApiService, private router: Router, private storage: LocalStorageService) { }

  async ngOnInit() { 
    this.fetchPayors()
  }

  async fetchPayors() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          if(this.user) {
            this.payors = await this.apiService.getPayors(null, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
          } else {
            this.user = await this.storage.getUserProfile();
            if (this.user.role === ROLE.payor) {
              this.router.navigate(['/user/payor/view', this.user.detail.id]);
            } else {
              this.payors = await this.apiService.getPayors(null, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
            };
          }
          this.loading = false;

          callback({
            recordsTotal: this.payors['pagination'].total,
            recordsFiltered: this.payors['pagination'].total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }
}

