import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router, private storage: LocalStorageService ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = await this.storage.getUserProfile();
    if (currentUser) {
        // check if route is restricted by role
        console.log(route.firstChild.data.roles)
        if (route.firstChild.data.roles && route.firstChild.data.roles !== currentUser.role) {
          for (let i = 0; i < route.firstChild.data.roles.length; i++) {
            if(route.firstChild.data.roles[i] === currentUser.role)
              return true;
          }
            // role not authorised so redirect to home page
            this.router.navigate(['/']);
            return false;
        }

        // authorised so return true
        return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
