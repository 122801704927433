import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-contract',
  templateUrl: './manage-contract.component.html',
  styleUrls: ['./manage-contract.component.scss']
})
export class ManageContractComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
