import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../services/api-service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-invoice-detail",
  templateUrl: "./invoice-detail.component.html",
  styleUrls: ["./invoice-detail.component.scss"],
})
export class InvoiceDetailComponent implements OnInit {
  invoiceId;
  invoiceDetail: {
    id: number;
    preapproval: {
      id: number;
      pa_token: string;
      reference_no: string;
      insurer_id: string;
      insurer_name: string;
      doctor_id: string;
      doctor_name: string;
      clinic_id: string;
      clinic_name: string;
      facility_id: string;
      facility_name: string;
      patient_name: string;
    };
    type: string;
    state: string;
    clinic_id: string;
    clinic_name: string;
    invoice_code: string;
    total_amount: number;
    copayment_amount: number;
    transaction_method: string[];
    transactions: {
      id: string;
      type: string;
      state: string;
      amount: number;
      method: string;
      method_ids: string[];
      reference_no: string;
    }[];
    visit: {
      token: string;
      doctor_id: string;
      doctor_name: string;
      clinic_id: string;
      clinic_name: string;
      ticket_type: string;
      ec_reference_no: string;
      preapproval_id: string;
      state: string;
      source: string;
      remarks: string;
    };
    invoice_items: {
      id: number;
      type: string;
      invoice_id: number;
      name: string;
      amount: number;
      doctor_fee: number;
      qty: number;
      clinic_id: string;
      treatment_code: string;
      remarks: string;
      crm_id: string;
    }[];
    voucher: {
      id: number;
      visit_token: string;
      invoice_id: number;
      source: string;
      billing_amount: number;
      state: string;
      attempts: number;
      reference_no: string;
      response_message: string;
      submitted_time: string;
      settled_amount: string;
      settlement_remarks: string;
      settled_by: string;
      settled_time: string;
      image: string;
    };
    diagnoses: {
      id: number;
      visit_id: number;
      visit_token: string;
      visit_date: string;
      name: string;
      code: string;
      created_at: string;
      updated_at: string;
    }[];
  };

  translateText = [];

  loading = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.invoiceId = this.route.snapshot.paramMap.get("id");
    translate
      .get(["invoice.refund_success", "invoice.refund_msg"])
      .subscribe((text: string[]) => {
        this.translateText = text;
      });
  }

  ngOnInit() {
    if (this.invoiceId) {
      this.fetchInvoiceDetail(this.invoiceId);
    }
  }

  checkIfNumber(val) {
    return isNaN(val) ? "N/A" : val;
  }

  async fetchInvoiceDetail(id) {
    try {
      this.loading = true;
      this.invoiceDetail = await this.apiService.getInvoice(id);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async downloadVoucherPdf() {
    try {
      this.loading = true;
      let res = await this.apiService.getVoucherPdf(this.invoiceId);
      const linkSource = `data:application/pdf;base64,${res}`;
      const downloadLink = document.createElement("a");
      const fileName = "voucher-" + this.invoiceDetail.invoice_code + ".pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async submitVoucher() {
    try {
      this.loading = true;
      this.invoiceDetail = await this.apiService.submitVoucher(
        this.invoiceDetail.visit.token
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async updateInvoiceDiagnoses() {
    try {
      this.loading = true;
      await this.apiService.updateInvoiceDiagnoses(
        this.invoiceDetail.diagnoses
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async updateInvoiceItems() {
    try {
      this.loading = true;
      await this.apiService.updateInvoiceItems(
        this.invoiceDetail.invoice_items
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.fetchInvoiceDetail(this.invoiceId);
      this.loading = false;
    }
  }

  async updateInvoiceTransaction(id) {
    try {
      this.loading = true;
      await this.apiService.updateInvoiceTransaction(
        this.invoiceDetail.transactions[id]
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.fetchInvoiceDetail(this.invoiceId);
      this.loading = false;
    }
  }

  getTotalAmount(items, fieldName) {
    var total = 0;
    items.forEach((item) => {
      total += parseFloat(item[fieldName]);
    });
    return total;
  }

  voucherChecking(): boolean {
    if (!this.invoiceDetail || this.invoiceDetail.state !== "DONE")
      return false;

    for (let i = 0; i < this.invoiceDetail.transaction_method.length; i++) {
      const element = this.invoiceDetail.transaction_method[i];
      if (element === "INSURER") return true;
    }
    return false;
  }

  async refundDEP(id) {
    try {
      this.loading = true;
      await this.apiService.alipayRefund(id);
      this.toastr.success(
        this.translateText["invoice.refund_msg"],
        this.translateText["invoice.refund_success"],
        {
          positionClass: "toast-bottom-full-width",
        }
      );
      this.fetchInvoiceDetail(this.invoiceId);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }
}
