import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBySpecialtyid'
})
export class FilterBySpecialtyidPipe implements PipeTransform {

  transform(value: any, args?: number): any {
    if (!value || args === -1) {
      return value;
    }
    
    return value.filter(item => item.specialty.id.toString() === args.toString());
  }

}
