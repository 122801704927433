import { Clinic } from './../models/Clinic';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PER_PAGE } from '../constant/config';
import { ApiService } from '../services/api-service';
import { FetchClinicsResponse } from '../response/FetchClinicsResponse';
import { District } from '../models/District';
import { Select2OptionData } from 'ng-select2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CMS_TYPE } from '../models/CMS';

@Component({
  selector: 'app-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.scss']
})
export class ClinicComponent implements OnInit {

  clinicList: Clinic[] = [];
  district: District[] = [];
  districtOptions: Select2OptionData[] = [];
  loading: boolean;
  modalLoading: boolean;
  importSuccess: boolean;
  importErr: String[];
  file: File;
  clinicManagementSystem: Array<any> = [];

  searchClinic = {
    name: "",
    qr_code: "",
    address: "",
    district_id: null
  }

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings;

  constructor(private apiService: ApiService) { }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    await this.fetchClinic();
    await this.fetchCMS();
    await this.fetchDistrict();
  }

  async fetchClinic() {
    this.loading = true;
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let response = <FetchClinicsResponse>await this.apiService.getClinic(null, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1, this.searchClinic.name, this.searchClinic.qr_code, this.searchClinic.address, this.searchClinic.district_id);
          this.clinicList = response.data;
          this.loading = false;

          callback({
            recordsTotal: response.pagination.total,
            recordsFiltered: response.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  async fetchDistrict() {
    try {
      this.district = <District[]>await this.apiService.getDistrict();
      this.districtOptions = this.district.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err)
    }
  }

  async filterClinic() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  async import() {
    if (!this.file) return;

    try {
      this.modalLoading = true;
      await this.apiService.importFile(this.file, 'CLINIC', { clinic_management_system_id: this.getCMSId(CMS_TYPE.CS) });
      this.importSuccess = true;
    } catch(err) {
      console.log(err);
      this.importErr = err.error.error_messages;
    } finally {
      this.modalLoading = false;
    }
  }

  async fetchCMS () {
    try {
      this.clinicManagementSystem = await this.apiService.getManagementSystem();
    } catch (err) {
      console.log(err)
    }
  }

  getCMSId(name) {
    let cms = this.clinicManagementSystem;
    for (let i = 0; i < cms.length; i++) {
      if (cms[i].name === name) return cms[i].id;
    }
  }

  resetModal() { 
    this.importSuccess = false;
    this.importErr = null;
  }

  onFileChange(event: DataTransfer){
    this.file = event.files[0];
  }
}
