import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDoctorName'
})
export class FilterDoctorNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || !args) {
      return value;
    }

    return value.filter(item => { 
      if(item.doctor_name)
        return item.doctor_name.toUpperCase().indexOf(args.toUpperCase()) > -1;
      })
  }
}
