import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from '../services/local-storage.service';
import { ROLE } from '../models/User';
import { Plan } from '../models/Plan';
import { Select2OptionData } from 'ng-select2';
type AOA = any[][];

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

  planList: Plan[] = [];
  planOptions: Select2OptionData[] = [];
  newMember = {
    plan_id: '',
    policy_number: '', 
    cert_number: '',
    name: '',
    gender: '',
    dob: '', 
    effective_date: ''
  }

  effective_date: Date;
  dob: Date
  today;
  oldday = {year: 1900, month: 1, day: 1};
  submitBtn = false;

  errorMsg;
  submitSuccess = false;
  loading;

  // data: AOA = [ [1, 2], [3, 4] ];

  constructor(private apiService: ApiService, private datePipe: DatePipe, private storage: LocalStorageService) {
    let today = new Date;
    this.today = {
      year: today.getFullYear(),
      month: (today.getMonth()) + 1,
      day: today.getDate()
    }
   }

  async ngOnInit() {
    let user = await this.storage.getUserProfile();
    try {
      if( user.role === ROLE.admin || user.role === ROLE.operator)
        this.planList = <Plan[]>(await this.apiService.fetchALLPlan())
      else
        this.planList = <Plan[]>(await this.apiService.fetchPlan());

      this.planOptions = this.planList.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err);
    }
  }

  async submit(){
    this.submitBtn = true;
    this.errorMsg = '';
    this.submitSuccess = false;
    if(!this.newMember.policy_number || !this.effective_date || !this.newMember.plan_id || !this.newMember.name || !this.newMember.gender || !this.dob) 
    {}
    else {
      let newMember = this.newMember;
      if(this.dob)
        newMember.dob = this.datePipe.transform(this.dob, 'yyyy-MM-dd');
      newMember.effective_date = this.datePipe.transform(this.effective_date, 'yyyy-MM-dd');
      this.loading = true;
      try {
        await this.apiService.enrollMember(newMember);
        this.resetMember();
        this.submitSuccess = true;
      } catch (err) {
        this.errorMsg = err.error.error_messages;
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }

  resetMember() {
    this.newMember.name = '';
    this.newMember.cert_number = '';
    this.dob = null;
    this.newMember.gender = '';
    this.submitBtn = false;
  }

  // onFileChange(evt: any) {
  //   /* wire up file reader */
  //   const target: DataTransfer = <DataTransfer>(evt.target);
  //   if (target.files.length !== 1) throw new Error('Cannot use multiple files');
  //   const reader: FileReader = new FileReader();
  //   reader.onload = (e: any) => {
  //     /* read workbook */
  //     const bstr: string = e.target.result;
  //     const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

  //     /* grab first sheet */
  //     const wsname: string = wb.SheetNames[0];
  //     const ws: XLSX.WorkSheet = wb.Sheets[wsname];

  //     /* save data */
  //     this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1}));
  //   };
  //   reader.readAsBinaryString(target.files[0]);
  // }

}
