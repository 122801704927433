import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauth',
  templateUrl: './unauth.component.html',
  styleUrls: ['./unauth.component.scss']
})
export class UnauthComponent implements OnInit {

  time = 5;
  interval;

  constructor(private router: Router) { }

  ngOnInit() {
    this.interval = setInterval(() => {
      console.log(this.time)
      if(--this.time === 0) {
        clearInterval(this.interval);
        this.router.navigate(['/']);
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
