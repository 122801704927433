import { Copay } from './../models/Copay';
import { Service_Item } from './../models/Service_Item';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCopayType'
})
export class FilterCopayTypePipe implements PipeTransform {

  transform(value: Service_Item[], copayTypeId: number): any {
    if (!value || !copayTypeId) {
      return value;
    }
    
    return value.filter(item => item.copay_type_id === copayTypeId);
  }

}
