import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../services/api-service';
// import { ApiService } from './../constant/api-service';

@Component({
  selector: 'single-map',
  templateUrl: './single-map.component.html',
  styleUrls: ['./single-map.component.scss']
})
export class SingleMapComponent implements OnInit {

  DEFAULT_ZOOM: number = 16;
  CEN_LAT_LNG = {
    lat: 22.3134127,
    lng: 114.1639514,
  };
  @Input ('latitude') latitude;
  @Input ('longitude') longitude;
  @Input ('zoom') zoom = 16;
  icon: any;
  lastSelectedInfoWindow: any;
  loading: boolean;
  loadedData: boolean = false;

  mapStyle = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

  // async getClinic() {
  //   this.loading = true;
  //   try {
  //     // this.clinicList = await this.apiService.getClinicsLocation();
  //     // for(let i = 0 ; i < 59 ; i++){
  //     //   this.staticUrl += "&pushpin="+ this.clinicList[i].coordinates[1] + ","+ this.clinicList[i].coordinates[0] +";15;"
  //     // };
  //     // this.clinicList.forEach(item => {
        
  //     // });
  //     this.loading = false;
  //     this.loadedData = true;
  //   } catch (err) { }
  // }

  clickedMarker(infoWindow: any) {
    if (infoWindow == this.lastSelectedInfoWindow) {
      return;
    }
    if (this.lastSelectedInfoWindow != null) {
      try{
          this.lastSelectedInfoWindow.close();
        } catch {} //in case if you reload your markers
    }
    this.lastSelectedInfoWindow = infoWindow;
  }

}
