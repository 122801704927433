import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterContract'
})
export class FilterContractPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || args === '-1') {
      return value;
    }
    
    return value.filter(item => item.contract.toUpperCase().indexOf(args.toUpperCase()) > -1);
  }

}
