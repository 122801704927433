import { Component, OnInit } from '@angular/core';
import { PLAN_TYPE } from '../models/Plan';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-default-contract',
  templateUrl: './add-default-contract.component.html',
  styleUrls: ['./add-default-contract.component.scss']
})
export class AddDefaultContractComponent implements OnInit {

  fromDefaultContract: boolean;

  planList;

  contract = {
    name: '',
    plan_id: '-1',
    services: [],
    type: PLAN_TYPE.def
  };

  submitBtn = false;

  constructor(private apiService: ApiService, private router: Router) { }

  async ngOnInit() {
    await this.fetchPlan();
  }

  async fetchPlan() {
    this.planList = await this.apiService.fetchDefaultPlan(PER_PAGE);
  }

  async submit(contract) {
    contract.type = PLAN_TYPE.def;
    await this.apiService.addContractTemplate(contract);
    this.router.navigate(['/default-contract']);
  }
}
