import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { SortServiceItemPipe } from '../pipes/sort-service-item.pipe';
import { FilterBySpecialtyidPipe } from '../pipes/filter-by-specialtyid.pipe';
import { COVER_TYPE, SERVICE_TYPE, Service_Item } from '../models/Service_Item';
import { ToastrService } from 'ngx-toastr';
import { Specialty } from '../models/Specialty';
import { Select2OptionData } from 'ng-select2';
import { ServiceCategory } from '../models/ServiceCategory';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { PER_PAGE } from '../constant/config';
import { FetchServiceItemResponse } from '../response/FetchServiceItemResponse';
import { COPAY_UNIT } from '../models/Copay';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
  providers: [FilterBySpecialtyidPipe]
})
export class ServicesListComponent implements OnInit {

  specialties: Specialty[] = [];
  specialtyOptions: Select2OptionData[] = [];
  categories: ServiceCategory[] = [];
  categoryOptions: Select2OptionData[] = [];
  payorOption: Select2OptionData[] = [];
  newServiceItem = {
    specialty_id: -1,
    service_category_id: -1,
    name: '',
    service_code: '',
    schema_codes: [],
    admin_fee: [],
    cover_type: '',
    service_type: ''
  };

  selectedSpecialty = -1;
  selectedCategory;
  
  servicesList = [];
  showList = [];
  fetchedServiceItem = [];
  payorList = [];
  cover_type = COVER_TYPE;
  service_type = SERVICE_TYPE;
  copayUnit = COPAY_UNIT;
  submitBtn = false;
  submitSuccess = false;
  submitLoading = false;
  loadingServices = false;
  submitSuccess2 = false;
  submitBtn2 = false;
  editSubmitBtn = false;
  editSubmitSuccess = false;

  newCategory: string;
  serviceFile;

  errorMsg;
  errorMsg2;
  serviceDetail: Service_Item;
  deleteServiceId: string;
  editErrorMsg;

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();


  constructor(private apiService: ApiService, private sortName: SortServiceItemPipe, private fliterSpec: FilterBySpecialtyidPipe, private toastr: ToastrService) {
    this.newServiceItem.schema_codes = [];
    this.newServiceItem.admin_fee = []
   }

   ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          if(this.selectedSpecialty != -1) {
            this.loadingServices = true;
            let response = await this.fetchServiceItems(dataTablesParameters.start/PER_PAGE + 1);
            this.loadingServices = false;

            callback({
              recordsTotal: response.pagination.total,
              recordsFiltered: response.pagination.total,
              data: []
            });
          } else {
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });
          }
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      
    }
    await this.fetchSpecialties();
    await this.fetchServiceCategory();
    await this.fetchPayor();
  }

  async fetchSpecialties() {
    try {
      this.specialties = <Specialty[]>await this.apiService.getSpecialty(false);
      this.specialties = this.sortName.transform(this.specialties);
      this.specialtyOptions = this.specialties.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
      this.specialties.forEach(item => {
        this.fetchedServiceItem.push(false);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async fetchServiceCategory() {
    try {
      this.categories = await this.apiService.getServiceCategory();
      this.categories = this.sortName.transform(this.categories);
      this.categoryOptions = this.categories.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err);
    }
  }

  async fetchServiceItems(page) {
    if(this.selectedSpecialty !== -1) {
      try {
        let items = <FetchServiceItemResponse>await this.apiService.serviceItemList(this.selectedSpecialty, true, PER_PAGE, page, this.selectedCategory)
        this.servicesList = items.data;
        return(items);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async fetchPayor() {
    try {
      this.payorList = await this.apiService.getPayors(null, false);
      this.payorOption = this.payorList.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err)
    }
  }

  addSchemeRow(item) {
    if(!item.schema_codes)
      item.schema_codes = [];
    item.schema_codes.push({
      'name': '',
      'code': ''
    });
  }

  addFeeRow(item) {
    if(!item.admin_fee)
      item.admin_fee = [];
    item.admin_fee.push({
      'insurer_id': null,
      'unit': COPAY_UNIT.amount,
      'admin_fee': 0
    });
  }

  remove(list, i) {
    list.splice(i, 1);
  }

  async addCategory() {
    if(this.newCategory) {
      try {
        let category = this.newCategory.toUpperCase();
        let res = await this.apiService.addServiceCategory(category);
        this.fetchServiceCategory();
      } catch (err) {
        console.log(err);
      }
    }
  }

  serviceCatString(id) {
    if(id) {
      let index =  this.categories.findIndex( item => item.id === id );
      return this.categories[index].name;
    }
  }

  async changeSelectedSpecialty(event) {
    this.selectedSpecialty = event['value'];
    this.refreshService();
  }

  async changeSelectedCategory(event) {
    this.selectedCategory = event['value'];
    this.refreshService();
  }

  async refreshService() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  async addServiceItem() {
    this.submitBtn = true;
    this.submitSuccess = false;
    this.errorMsg = '';
    if(this.newServiceItem.name && this.newServiceItem.service_code && this.newServiceItem.specialty_id && this.newServiceItem.cover_type && this.newServiceItem.service_type) {
      try{
        this.submitLoading = true;
        let subItem;
        if(this.newServiceItem.service_category_id === -1) {
          subItem = {
            specialty_id: this.newServiceItem.specialty_id,
            name: this.newServiceItem.name,
            service_code: this.newServiceItem.service_code.toUpperCase(),
            schema_codes: this.newServiceItem.schema_codes,
            cover_type: this.newServiceItem.cover_type,
            service_type: this.newServiceItem.service_type
          }
        } else {
          subItem = this.newServiceItem;
          subItem.service_code = subItem.service_code.toUpperCase();
        }
        let item = await this.apiService.addServiceItem(subItem);
        this.submitSuccess = true;
        this.submitBtn = false;
        this.refreshService();

        // reset item
        this.newServiceItem.name = '';
        this.newServiceItem.schema_codes = [];
        this.newServiceItem.service_code = '';
      } catch(err) {
        this.errorMsg = err.error.error_messages;
        console.log(err)
      } finally {
        this.submitLoading = false;
      }
    }
  }

  async editServiceItem() {
    this.editSubmitBtn = true;
    this.editSubmitSuccess = false;
    this.editErrorMsg = '';
    if(this.serviceDetail.name && this.serviceDetail.cover_type && this.serviceDetail.service_type) {
      try{
        this.submitLoading = true;
        let subItem;
        if(this.serviceDetail.category_id && this.serviceDetail.category_id !== '-1') {
          subItem = {
            specialty_id: this.serviceDetail.specialty.id,
            name: this.serviceDetail.name,
            service_category_id: this.serviceDetail.category_id,
            service_code: this.serviceDetail.service_code.toUpperCase(),
            schema_codes: this.serviceDetail.schema_codes,
            cover_type: this.serviceDetail.cover_type,
            service_type: this.serviceDetail.service_type,
            admin_fee: this.serviceDetail.admin_fee
          }
        } else {
          subItem = {
            specialty_id: this.serviceDetail.specialty.id,
            name: this.serviceDetail.name,
            service_code: this.serviceDetail.service_code.toUpperCase(),
            schema_codes: this.serviceDetail.schema_codes,
            cover_type: this.serviceDetail.cover_type,
            service_type: this.serviceDetail.service_type
          }
        }
        let item = await this.apiService.addServiceItem(subItem);
        this.editSubmitSuccess = true;
        this.editSubmitBtn = false;
        this.refreshService();
        // this.changeSelectedSpecialty();
        // let index = this.servicesList.findIndex(x => x.id == item.id);
        // this.servicesList[index] = item;
        // this.showList = this.fliterSpec.transform(this.servicesList, this.selectedSpecialty);
        // if(this.fetchedServiceItem[this.newServiceItem.specialty_id-1]) {
        //   this.servicesList.push(item);
        //   if(this.newServiceItem.specialty_id === this.selectedSpecialty) {
        //     this.showList = this.fliterSpec.transform(this.servicesList, this.selectedSpecialty);
        //   }
        // }
      } catch(err) {
        this.editErrorMsg = err.error.error_messages;
        console.log(err)
      } finally {
        this.submitLoading = false;
      }
    }
  }

  onFileChange(event: DataTransfer){
    this.serviceFile = event.files[0];
  }

  async importServiceItem() {
    if(this.serviceFile) {
      try {
        this.submitSuccess2 = false;
        this.submitBtn2 = true;
        await this.apiService.importFile(this.serviceFile, 'SERVICE', null);
        this.submitSuccess2 = true;
        this.submitBtn2 = false;
      } catch(err) {
        console.log(err);
        this.errorMsg2 = err.error.error_messages;
      }
    }
  }

  async editModal(id) {
    this.editSubmitBtn = false;
    this.editSubmitSuccess = false;
    this.editErrorMsg = '';
    try {
      this.serviceDetail = await this.apiService.serviceItemDeatil(id);
      $('#modal-edit').modal('show');
    } catch (err) {
      console.log(err)
    }
  }

  deleteModal(id) {
    this.deleteServiceId = id;
  }

  async deleteService() {
    try {
      let err = await this.apiService.deleteService(this.deleteServiceId);
      this.toastr.success( 'The service has been deleted.','Delete Success', {
        positionClass: "toast-bottom-full-width"
      });
      this.refreshService();
      // for(let i = 0; i < this.servicesList.length ; i++ ) {
      //   if(this.showList[i].id.toString() == this.deleteServiceId.toString()) {
      //     this.servicesList.splice(i,1);
      //     break;
      //   }
      // }
      // this.showList = this.fliterSpec.transform(this.servicesList, this.selectedSpecialty);
    } catch(err) {
      this.toastr.error( err.error.error_messages, 'Delete Failed', {
        positionClass: "toast-bottom-full-width"
      });
    }
  }

}
