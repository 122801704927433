import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PLAN_TYPE, Plan } from '../../models/Plan';
import { ROLE, User } from '../../models/User';
import { LocalStorageService } from '../../services/local-storage.service';
import {NgbDateAdapter, NgbDateNativeAdapter} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/api-service';
import { DatePipe } from '@angular/common';
import { PER_PAGE } from '../../constant/config';
import { ContractTemplate } from 'src/app/models/ContractTemplate';
import { FetchContractsResponse } from 'src/app/response/FetchContractsResponse';
import { Specialty } from 'src/app/models/Specialty';
import { Select2OptionData } from 'ng-select2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FilterDoctorNamePipe } from '../../pipes/filter-doctor-name.pipe';
import { FilterClinicNamePipe } from '../../pipes/filter-clinic-name.pipe';

@Component({
  selector: 'contract-table',
  templateUrl: './contract-table.component.html',
  styleUrls: ['./contract-table.component.scss'],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class ContractTableComponent implements OnInit {

  templates: ContractTemplate[] = [];
  totalNum = 0;
  // defaultList = [];
  selectedPlan;
  @Input('is_type') is_type;
  // @Input('is_user') is_user;
  contractType = PLAN_TYPE;
  user: User;
  role = ROLE;
  effective_date: Date; 
  selectedContract;
  doctorList = [];
  loading: boolean;
  submitBtn: boolean = false;
  assignLoading: boolean = false;
  filterDoctor;
  filterClinic;
  filterSpecialty = "-1";
  specialtyList: Specialty[] = [];
  specialtyOptions: Select2OptionData[] = [];
  networkGroupOptions: Select2OptionData[] = [];
  planList: Select2OptionData[] = [];
  networkGroup: Map<any, any>;
  submitSuccess: boolean = false;
  selectedGroup;

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  // @Output() fetchContract: EventEmitter<any> = new EventEmitter();

  constructor(private storage: LocalStorageService, private apiService: ApiService, private datePipe: DatePipe, private filterNamePipe: FilterDoctorNamePipe, private filterClinicPipe: FilterClinicNamePipe) {
    let planId = (new URL(window.location.href)).searchParams.get('plan_id');
    if (planId)
      this.selectedPlan = planId;
  }

  private async getUser() {
    this.user = await this.storage.getUserProfile();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        processing: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
            this.loading = true;
            if(this.planList.length === 0)
              await this.fetchPlan();
            if (!this.user)
              await this.getUser();
            let response: FetchContractsResponse;
            if (this.user.role === ROLE.admin || this.user.role === ROLE.operator)
              response = <FetchContractsResponse>await this.apiService.adminGetContractTemplate(true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1, this.selectedPlan);
            else
              response = <FetchContractsResponse>await this.apiService.fetchContractTemplate(true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1, this.selectedPlan);
            
            this.templates = response.data;
            this.totalNum = response.pagination.total;
            this.loading = false;

          callback({
            recordsTotal: this.totalNum,
            recordsFiltered: this.totalNum,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  async openModel(id) {
    this.filterSpecialty = "-1";
    this.filterDoctor = null;
    this.filterClinic = null;
    this.selectedGroup = null;
    this.selectedContract = id;
    this.submitSuccess = false;
    try {
      this.loading = true;
      if (this.specialtyList.length === 0) {
        this.specialtyList = <Specialty[]>await this.apiService.getSpecialty(false);
        this.specialtyOptions = this.specialtyList.map(item => {
          return {
            id: item.id,
            text: item.name
          };
        });
      }
      let res = await this.apiService.getNetworkGroup();
      this.networkGroup = new Map(res.map(item => [item.id, item]));
      this.networkGroupOptions = res.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
      // this.doctorList = await this.apiService.fetchDocNetDoctor("Docnet");
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async sendOffer() {
    this.submitBtn = true;
    this.submitSuccess = false;
    let contract;
    if (this.effective_date) {
      contract = {
        contract_id: this.selectedContract ,
        effective_date: this.datePipe.transform(this.effective_date, 'yyyy-MM-dd'),
        doctor_clinic_ids: []
      }
      this.doctorList.forEach(doctor => {
        if (doctor.isChosen)
          contract.doctor_clinic_ids.push(doctor.id);
      });
    }

    if (this.effective_date && contract.doctor_clinic_ids.length > 0) {
      try {
        this.assignLoading = true;
        await this.apiService.signOfferToDocNet(contract);
        this.submitSuccess = true;
        // $('#modal-assign').modal('hide');
      } catch(err) {
        console.log(err);
      } finally {
        this.assignLoading = false;
      }
    }
  }

  specialtyName(id) {
    return this.specialtyList[this.specialtyList.findIndex(x => x.id === id)]['name'];
  }

  filterBySpecialtyid(arr) {
    if (!arr || this.filterSpecialty === "-1") {
      return arr;
    }
    return arr.filter(item => {
      if (item.specialty_id)
        return item.specialty_id.toString() === this.filterSpecialty;
    });
  }

  async fetchDoctor(event) {
    this.selectedGroup = event['value'];
    try {
      this.loading = true;
      this.doctorList = await this.apiService.getDoctorClinic(null, null, null, [this.selectedGroup], null, null, false, null, null);
    } catch(err){
      console.log(err)
    } finally {
      this.loading = false;
    }
  }
  
  getNetworkGroupName(groups){
    let nameList =[];
    groups.forEach(id => {
      nameList.push(this.networkGroup.get(id).name);
    });
    return nameList.join(', ');
  }

  async fetchPlan() {
    if (!this.user)
      await this.getUser();
    let plan:Plan[]
    if (this.user.role === ROLE.admin || this.user.role === ROLE.operator)
      plan = <Plan[]>(await this.apiService.fetchALLPlan());
    else
      plan = <Plan[]>(await this.apiService.fetchPlan());
    this.planList = plan.map(item => {
      return {
        id: item.id.toString(),
        text: item.name
      };
    });
  }

  async filterContract() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  selectAll() {
      let list = this.filterNamePipe.transform(this.doctorList, this.filterDoctor);
      list = this.filterClinicPipe.transform(list, this.filterClinic);
      list = this.filterBySpecialtyid(list);
      list.forEach(item => {
        this.doctorList[this.doctorList.findIndex(x => x.id === item.id)].isChosen = true;
      });
  }

  clearAll() {
    for(let i = 0; i < this.doctorList.length; i++) 
    this.doctorList[i].isChosen = false;
  }
}
