import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PER_PAGE } from '../constant/config';
import { ROLE } from '../models/User';
import { LocalStorageService } from '../services/local-storage.service';
import { ApiService } from './../services/api-service';

@Component({
  selector: 'app-view-payor',
  templateUrl: './view-payor.component.html',
  styleUrls: ['./view-payor.component.scss']
})
export class ViewPayorComponent implements OnInit {
  user;
  role = ROLE;
  payorDetails:any;
  documents = { data: [] };
  payorUsers = { data: [] };
  payorUser = {
    id: "",
    insurer_id: "",
    name: "",
    email: ""
  };

  payorDetailsLoading: boolean;
  documentsLoading: boolean;
  payorUsersLoading: boolean;
  modalLoading: boolean;

  documentDtOptions: DataTables.Settings;
  payorDtOptions: DataTables.Settings;
  insurerId = this.route.snapshot.params.id;

  submitSuccess = false;
  validate = true;
  errorMsg;
  constructor(private apiService: ApiService, private route: ActivatedRoute, private storage: LocalStorageService) {
    this.fetchPayorDetails();
    this.fetchDocuments();
    this.fetchPayorUsers();
  }

  async ngOnInit() { 
    this.user = await this.storage.getUserProfile();
  }

  async fetchPayorDetails() {
    try {
      this.payorDetailsLoading = true;
      this.payorDetails = await this.apiService.getPayorDetails(this.insurerId);
    } catch (err) {
      console.log(err)
    } finally {
      this.payorDetailsLoading = false;
    }
  }

  async fetchDocuments() {
    try {
      this.documentDtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.documentsLoading = true;
          this.documents = await this.apiService.getDoc(this.insurerId, null, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
          this.documentsLoading = false;
          callback({
            recordsTotal: this.documents['pagination'].total,
            recordsFiltered: this.documents['pagination'].total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.documentsLoading = false;
    }
  }

  async fetchPayorUsers() {
    try {
      this.payorDtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.payorUsersLoading = true;
          this.payorUsers = await this.apiService.getPayorUser(this.insurerId, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
          this.payorUsersLoading = false;

          callback({
            recordsTotal: this.payorUsers['pagination'].total,
            recordsFiltered: this.payorUsers['pagination'].total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.payorUsersLoading = false;
    }
  }

  async addPayorUser() {
    this.validate = false;
    this.errorMsg = "";
    if(this.payorUser.name && this.payorUser.email) {
      try {
        this.submitSuccess = false;
        this.modalLoading = true;
        this.payorUser.insurer_id = this.insurerId;
        await this.apiService.addNewPayorUser(this.payorUser);
        this.submitSuccess = true;
        this.validate = true;
        $('#payorTable').DataTable().ajax.reload();
      } catch (err) {
        console.log(err);
        this.errorMsg = err.error.error_messages;
      } finally {
        this.modalLoading = false;
      }
    }
  }

  async editPayorUser() {
    this.validate = false;
    this.errorMsg = "";
    if(this.payorUser.id && this.payorUser.name) {
      try {
        this.submitSuccess = false;
        this.modalLoading = true;
        let user = {
          user_id: this.payorUser.id,
          name: this.payorUser.name
        };
        await this.apiService.updatePayorUser(user);
        this.submitSuccess = true;
        this.validate = true;
        $('#payorTable').DataTable().ajax.reload();
      } catch (err) {
        console.log(err);
        this.errorMsg = err.error.error_messages;
      } finally {
        this.modalLoading = false;
      }
    }
  }

  resetModal(user) {
    this.validate = true;
    this.submitSuccess = false;
    this.errorMsg = '';
    this.payorUser = user? user : {
      insurer_id: "",
      name: "",
      email: ""
    };
  }
}
