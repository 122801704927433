import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { ActivatedRoute, Router } from '@angular/router';
import { OFFER_STATUS } from '../models/Offer';
import { LocalStorageService } from '../services/local-storage.service';
import { ROLE } from '../models/User';

@Component({
  selector: 'app-view-contract',
  templateUrl: './view-contract.component.html',
  styleUrls: ['./view-contract.component.scss']
})
export class ViewContractComponent implements OnInit {

  contract;
  offerStatus = OFFER_STATUS;
  remarks = '';

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, private storage: LocalStorageService) { }

  async ngOnInit() {
    this.fetchOfferDetail(this.route.snapshot.paramMap.get('id'));
  }

  async fetchOfferDetail (id) {
    try{
      let user = await this.storage.getUserProfile();
      if(user.role === ROLE.payor)
        this.contract = await this.apiService.getOfferDetail(id);
      else if(user.role === ROLE.admin || user.role === ROLE.operator)
        this.contract = await this.apiService.adminGetOffer(id);
      else
        this.contract = await this.apiService.doctorGetOfferDetail(id);
    } catch (err) {
      console.log(err);
    }
  }

  async terminate() {
    if(this.remarks) {
      try{
        await this.apiService.terminateOffer(this.contract.id,  this.remarks);
        $('#modal-counter').modal('hide');
        this.router.navigate(['/offer/terminated']);
      } catch(err) {
        console.log(err)
      }
    }
  }

}
