import { Component } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { ROLE } from '../models/User';

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.scss']
})
export class PlanListComponent {

  isAdmin = false;

  constructor(private localStorage: LocalStorageService) { }

  async ngOnInit() {
    let user = await this.localStorage.getUserProfile();
    if(user.role === ROLE.admin || user.role === ROLE.operator) 
      this.isAdmin = true;
  }

}
