import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorService } from './validator.service';
import { INSURER_ID } from '../models/Insurer';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PreapprovalFormService {

  constructor(private datePipe: DatePipe, private validatorService: ValidatorService) { }

  getInsurerPreapprovalForm(insurerId: string, formData: any, initData: any, viewMode: boolean) {
    switch(insurerId) {
      case INSURER_ID.MANULIFE:
        return this.getManulifeForm(formData, initData, viewMode);
      default:
        return null;
    }
  }

  getManulifeForm(formData, data, viewMode: boolean = false) {
    data = data? data : {};
    formData = formData? formData : {};
    return new FormGroup({
      insuredName: new FormControl({value: (formData.insuredName || data.name), disabled: viewMode}, [Validators.required, this.validatorService.isEnglish()]),
      hkid: new FormControl({value: (formData.hkid || data.id_num), disabled: true}, Validators.required),
      polNo: new FormControl({value: (formData.polNo || data.policy_no), disabled: true}, Validators.required),
      patientPhoneNo: new FormControl({value: (formData.patientPhoneNo || data.phone), disabled: viewMode}, [Validators.required, Validators.pattern('^[456789]{1}[0-9]{7}')]),
      dateFirstSymp: new FormControl({value: (formData.dateFirstSymp? new Date(formData.dateFirstSymp) : null), disabled: viewMode}, Validators.required),
      dateFirstConsult: new FormControl({value: (formData.dateFirstConsult? new Date(formData.dateFirstConsult) : null), disabled: viewMode}, Validators.required),
      clinicDesc: new FormControl({value: (formData.clinicDesc || data.clinic_name), disabled: true}, Validators.required),
      doctorName: new FormControl({value: (formData.doctorName || data.doctor_name), disabled: true}, Validators.required),
      docPhoneNo: new FormControl({value: (formData.docPhoneNo || data.clinic_phone), disabled: viewMode}, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
      docEmail: new FormControl({value: (formData.docEmail || data.clinic_email), disabled: viewMode}, [Validators.email, Validators.maxLength(50)]),
      doctorRemark: new FormControl({value: formData.doctorRemark, disabled: viewMode}, [this.validatorService.isEnglish(), Validators.maxLength(1000)]),
      diagnosis1Desc: new FormControl({value: formData.diagnosis1Desc, disabled: viewMode}, [Validators.required, this.validatorService.isEnglish()]),
      diagnosis2Desc: new FormControl({value: formData.diagnosis2Desc, disabled: viewMode}, this.validatorService.isEnglish()),
      diagnosis3Desc: new FormControl({value: formData.diagnosis3Desc, disabled: viewMode}, this.validatorService.isEnglish()),
      referDocName: new FormControl({value: formData.referDocName, disabled: viewMode}, [this.validatorService.isEnglish(), Validators.maxLength(100)]),
      sympHistory: new FormControl({value: formData.sympHistory, disabled: viewMode}, Validators.required),
      sympHistoryDate: new FormControl({
        value: (formData.sympHistoryDate? new Date(formData.sympHistoryDate) : null),
        disabled: (viewMode || (formData.sympHistory && formData.sympHistory === 'N'))? true : false
      }),
      sympHistoryDetail: new FormControl({
        value: formData.sympHistoryDetail,
        disabled: (viewMode || (formData.sympHistory && formData.sympHistory === 'N'))? true : false
      }),
      hospCode: new FormControl(formData.hospCode, Validators.required),
      hospitalReason: new FormControl({value: formData.hospitalReason, disabled: viewMode}, [this.validatorService.isEnglish(), Validators.maxLength(300)]),
      roomDay: new FormControl({value: 0, disabled: true}, [Validators.required, this.validatorService.isInteger, Validators.min(0), Validators.max(999.99)]),
      roomType: new FormControl({value: formData.roomType, disabled: viewMode}, Validators.required),
      anaestType: new FormControl(formData.anaestType, Validators.required),
      patientUsualDoc: new FormControl({value: formData.patientUsualDoc, disabled: viewMode}, Validators.required),
      docOthRole: new FormControl({value: formData.docOthRole, disabled: viewMode}, Validators.required),
      docOthRoleDesc: new FormControl({
        value: formData.docOthRoleDesc,
        disabled: (viewMode || (formData.docOthRole && formData.docOthRole === 'N'))? true : false
      }),
      paSubmitDate: new FormControl({value: (new Date ), disabled: true}, Validators.required),
      miscFee: new FormControl({value: (formData.miscFee || 0), disabled: true}, [Validators.required, this.validatorService.isNumber, Validators.min(0), Validators.max(999999999.99)]),
      dailyRoomFee: new FormControl({value: 0, disabled: true}, [Validators.required, this.validatorService.isNumber, Validators.min(0), Validators.max(999999999.99)]),
      dailyDocVisitFee: new FormControl({value: 0, disabled: true}, [Validators.required, this.validatorService.isNumber, Validators.min(0), Validators.max(999999999.99)]),
      anaesthetistFee: new FormControl({value: (formData.anaesthetistFee || 0), disabled: viewMode}, [Validators.required, this.validatorService.isNumber, Validators.min(0), Validators.max(999999999.99)]),
      operaTheaFee: new FormControl({value: (formData.operaTheaFee || 0), disabled: viewMode}, [Validators.required, this.validatorService.isNumber, Validators.min(0), Validators.max(999999999.99)]),
      confineBeginDate: new FormControl({value: (formData.confineBeginDate? new Date(formData.confineBeginDate) : null), disabled: viewMode}, Validators.required),
      confineEndDate: new FormControl({value: (formData.confineEndDate? new Date(formData.confineEndDate) : null), disabled: viewMode}, Validators.required),
    });
  }

  constructManulifePayload(data) {
    Object.keys(data).forEach(key => {
      data[key] = (data[key] === null || data[key] === undefined)? "" : data[key];
      data[key] instanceof Date? data[key] = this.parseDate(data[key], 'yyyy-MM-dd') : data[key];
    });

    let fees = {
      miscFee: parseFloat(data.miscFee),
      dailyRoomFee: parseFloat(data.dailyRoomFee),
      dailyDocVisitFee: parseFloat(data.dailyDocVisitFee),
      anaesthetistFee: parseFloat(data.anaesthetistFee),
      operaTheaFee: parseFloat(data.operaTheaFee)
    };

    let totalAmount = 0;
    Object.keys(fees).forEach(key => {
      data[key] = fees[key];
      totalAmount += fees[key];
    });
    data.surgicalDetails.forEach(item => {
      totalAmount += item.requestAmount;
    });

    data.roomDay = parseFloat(data.roomDay);
    data.totalRequestAmount = totalAmount;

    return data;
  }

  parseDate(date: Date, format: string = 'yyyy-MM-dd') {
		return this.datePipe.transform(date, format)
	}
}
