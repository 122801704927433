import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortDoctor'
})
export class SortDoctorPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.sort(this.compare);
  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    if(a.doctor_name && b.doctor_name) {
      const genreA = a.doctor_name.toUpperCase();
      const genreB = b.doctor_name.toUpperCase();
    
      let comparison = 0;
      if (genreA > genreB) {
        comparison = 1;
      } else if (genreA < genreB) {
        comparison = -1;
      }
      return comparison;
    }
  }

}
