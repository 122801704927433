import { ContractTemplate, CONTRACT_TEMPLATE_TYPE } from 'src/app/models/ContractTemplate';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-contract-template',
  templateUrl: './view-contract-template.component.html',
  styleUrls: ['./view-contract-template.component.scss']
})
export class ViewContractTemplateComponent implements OnInit {

  contract: ContractTemplate;
  contract_type = CONTRACT_TEMPLATE_TYPE;

  constructor(private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.url.subscribe((u) => {
      this.fetchContractTemplateDetail(this.route.snapshot.params.id);
    });
  }

  async fetchContractTemplateDetail (id) {
    try {
      this.contract  = await this.apiService.getContractTemplateDetail(id);
    } catch (err) {
      console.log(err);
    }
  }

}
