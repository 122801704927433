import { Component, OnInit } from '@angular/core';
import { PER_PAGE } from '../constant/config';
import { ApiService } from '../services/api-service';
import { ActivatedRoute, Router } from '@angular/router';
import { User, ROLE } from '../models/User';
import { LocalStorageService } from '../services/local-storage.service';

@Component({
  selector: 'app-add-payor',
  templateUrl: './add-payor.component.html',
  styleUrls: ['./add-payor.component.scss']
})
export class AddPayorComponent implements OnInit {
  insurerId;
 
  //payor
  defaultEC: boolean = true;
  loading: boolean;
  submitBtn: boolean = true;
  payorSubmitSuccess: boolean;
  payorSubmitError: string;
  payor = {
    id: "",
    en_name: "",
    hant_name: "",
    hans_name: "",
    email: "",
    status: "",
    ec_path: "",
    card_image: "",
    card_image_style: {
      name: null, 
      policy_no: null
    }
  };

  //medical card
  medicalCard = null;
  medicalCardURL = null;
  cardLoading: boolean;
  cardSubmitSuccess: boolean;
  cardSubmitError: string;
  displayName: boolean = false;
  displayPolicy: boolean = false;
  medicalCardStyle: any = {
    name: {
      top: 0,
      left: 0
    }, 
    policy_no: {
      top: 0,
      left: 0
    }
  };

  //icon
  icon = null;
  iconURL = null;
  iconLoading: boolean;
  iconSubmitSuccess: boolean;
  iconSubmitError: string;


  //documents
  documents = { data: [] };
  documentList = {
    insurer_id: null,
    documents: []
  }
  modalLoading: boolean;
  documentValidate: boolean = true;
  documentDtOptions: DataTables.Settings;
  documentSubmitSuccess: boolean;
  documentSubmitError: string;
  documentLoading: boolean;

  user:User;
  role = ROLE;


  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, private localStroage: LocalStorageService) {
    this.insurerId = this.route.snapshot.params.id;
    if (this.insurerId) {
      this.fetchPayorDetails();
      this.fetchDocuments();
    }
  }

  async ngOnInit() {
    this.user = await this.localStroage.getUserProfile();
  }

  async fetchPayorDetails() {
    try {
      this.loading = true;
      this.payor = await this.apiService.getPayorDetails(this.insurerId);
      let cardStyle = this.payor.card_image_style;
      if (this.payor.ec_path && this.payor.ec_path !== "") {
        this.defaultEC = false;
      }
      if (cardStyle) {
        if (!cardStyle.name.display || cardStyle.name.display != 'none') {
          this.displayName = true;
          this.medicalCardStyle.name.top = parseFloat(cardStyle.name.top);
          this.medicalCardStyle.name.left = parseFloat(cardStyle.name.left);
        }
        if (!cardStyle.policy_no.display || cardStyle.policy_no.display != 'none') {
          this.displayPolicy = true;
          this.medicalCardStyle.policy_no.top = parseFloat(cardStyle.policy_no.top);
          this.medicalCardStyle.policy_no.left = parseFloat(cardStyle.policy_no.left);
        }
      }
    } catch (err) {
      console.log(err);
      this.router.navigateByUrl('user/payor');
    } finally {
      this.loading = false;
    }
  }

  async fetchDocuments() {
    try {
      this.documentDtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.documentLoading = true;
          this.documents = await this.apiService.getPayorDoc(this.insurerId, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
          this.documentLoading = false;
          callback({
            recordsTotal: this.documents['pagination'].total,
            recordsFiltered: this.documents['pagination'].total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.documentLoading = false;
    }
  }

  async savePayor() {
    this.payorSubmitError = "";
    this.submitBtn = false;
    this.payorSubmitSuccess = false;

    if (this.payor.en_name && this.payor.hant_name && this.payor.hans_name && this.payor.status) {
      if (!this.defaultEC && (!this.payor.ec_path || this.payor.ec_path === "")) {
        return;
      } 
      if (this.defaultEC) {
        this.payor.ec_path = "";
      }
      try {
        this.submitBtn = true;
        this.payorSubmitError = "";
        this.loading = true;

        if (this.insurerId) {
          await this.apiService.addOrUpdatePayor(this.payor, this.insurerId);
        } else {
          this.payor = await this.apiService.addOrUpdatePayor(this.payor);
          this.router.navigate(['/user/payor/edit', this.payor.id]);
        }
        this.payorSubmitSuccess = true;
      } catch (err) {
        console.log(err);
        this.payorSubmitError = err.error.error_messages;
      } finally {
        this.loading = false;
      }
    }
  }

  async saveIcon() {
    try {
      this.iconLoading = true;
      this.iconSubmitSuccess = false;
      this.iconSubmitError = "";
      let icon = {
        insurer_id: this.insurerId,
        icon: this.icon
      }
      await this.apiService.uploadPayorIcon(icon);
      this.iconSubmitSuccess = true;
    } catch (err) {
      console.log(err);
      this.iconSubmitError = err.error.error_messages;
    } finally {
      this.iconLoading = false;
    }
  }

  async saveMedicalCard() {
    let cardStyle =JSON.parse(JSON.stringify(this.medicalCardStyle));
    if (this.displayName) {
      cardStyle.name.top += '%';
      cardStyle.name.left += '%';
    } else {
      cardStyle.name = {
        display: "none"
      }
    }
    if (this.displayPolicy) {
      cardStyle.policy_no.top += '%';
      cardStyle.policy_no.left += '%';
    } else {
      cardStyle.policy_no = {
        display: "none"
      }
    }
    let medicalCard = {
      insurer_id: this.insurerId,
      plan_id: null,
      image: this.medicalCard,
      style: JSON.stringify(cardStyle)
    }

    try {
      this.cardLoading = true;
      this.cardSubmitSuccess = false;
      this.cardSubmitError = "";
      await this.apiService.uploadMedicalCard(medicalCard);
      this.cardSubmitSuccess = true;
    } catch (err) {
      console.log(err);
      this.cardSubmitError = err.error.error_messages;
    } finally {
      this.cardLoading = false;
    }
  }
  
  async uploadDoc() {
    this.resetModal();
    this.documentValidate = false;
    try {
      this.modalLoading = true;
      this.documentList.insurer_id = this.insurerId;
      let submit = true;
      this.documentList.documents.forEach((doc) => {
        if (!doc.name || !doc.file || !doc.type) {
          submit = false;
          return;
        }
      });
      if (submit) {
        await this.apiService.uploadDoc(this.documentList);
        $('#documentTable').DataTable().ajax.reload();
        this.documentList.documents = [];
        this.documentSubmitSuccess = true;
      }
    } catch(err) {
      console.log(err);
      this.documentSubmitError = err.error.error_messages;
    } finally {
      this.modalLoading = false;
    }
  }

  addNewDoc() {
    this.resetModal();
    let len = this.documentList.documents.length;
    if (len === 0 || this.documentList.documents[len-1].file) {
      this.documentList.documents.push({
        // id: null,
        type: '',
        name: '',
        file: null
      })
    }
  }

  onCardChange(event: DataTransfer) {
    this.medicalCard = event.files[0];
    var reader = new FileReader();
    reader.onload = e => this.medicalCardURL = reader.result;
    reader.readAsDataURL(this.medicalCard);
  }

  onFileChange(event: DataTransfer, i){
    this.documentList.documents[i].file = event.files[0];
    this.documentList.documents[i].name = event.files[0].name.split(".")[0];
  }

  onIconChange(event: DataTransfer) {
    this.icon = event.files[0];
    var reader = new FileReader();
    reader.onload = e => this.iconURL = reader.result;
    reader.readAsDataURL(this.icon);
  }

  openModal() {
    this.resetModal();
    this.documentList.documents = [];
  }

  resetModal() { 
    this.documentSubmitSuccess = false;
    this.documentSubmitError = '';
    this.documentValidate = true;
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }
}
