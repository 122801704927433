import { Question } from "./Question";

export class QuestionDropdown extends Question<string> {
  controlType = 'dropdown';
  options: {id: string, text: string}[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}