export interface CMS {
    id?: string,
    name: string,
    company: string,
    communication_email: string,
    default_remote_is_full: boolean,
    allow_multiple_transaction: boolean,
    allow_websocket_retry: boolean,
    show_on_heals_app: boolean,
    websocket_retry_number: number,
    websocket_req_ticket_status: boolean,
    websocket_req_ticket_book: boolean,
    websocket_cancel_remote_ticket: boolean,
    websocket_confirm_remote_ticket: boolean,
    websocket_req_appointment_book: boolean,
    websocket_notify_appointment_book: boolean,
    websocket_confirm_appointment_book: boolean,
    websocket_reject_appointment_book: boolean,
    websocket_payment_status: boolean,
    websocket_doctor_app_otp: boolean,
    websocket_patient_check_in: boolean,
    created_at?: string,
    created_by?: string,
    created_by_role?: string,
    updated_at?: string,
    updated_by?: string,
    updated_by_role?: string
}

export enum CMS_TYPE {
    CS = 'ClinicSolution',
    WINMED = 'WinMed',
    CLINISYS = 'CliniSys',
    CLINIC_ASSIST = 'ClinicAssist'
}