import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { LocalStorageService } from '../services/local-storage.service';
import { ROLE } from '../models/User';
import { PER_PAGE } from '../constant/config';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Observable } from 'rxjs';
import { Plan } from '../models/Plan';
import { FetchMembersResponse } from '../response/FetchMembersResponse';
import { Member } from '../models/Member';
import { Select2OptionData } from 'ng-select2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-member-enrollment',
  templateUrl: './member-enrollment.component.html',
  styleUrls: ['./member-enrollment.component.scss']
})
export class MemberEnrollmentComponent implements OnInit {

  memberList: Member[] = [];
  planList: Plan[] = [];
  planOptions: Select2OptionData[] = [];

  bulkMember = {
    plan_id: '',
    csv: null
  }
  bulkAsiaMember = {
    file: null
  }
  loading: boolean;
  updateLoading: boolean;
  editSubmitSuccess: boolean;
  editErrorMsg: string;
  errorMsg;
  errorMsg2;
  submitSuccess = false;
  submitBtn = false;
  isAdmin;
  searchOption = {
    plan_id: "-1"
  }

  memberDetail: Member;

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  terminationDate: Date;
  effectiveDate: Date;

  constructor(private apiService: ApiService, private storage: LocalStorageService, private datePipe: DatePipe) { }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          if(this.searchOption.plan_id != "-1") {
            this.loading = true;
            let response = <FetchMembersResponse>await this.apiService.fetchMemberList(parseInt(this.searchOption.plan_id), PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
            this.memberList = response.data;
            this.loading = false;

            callback({
              recordsTotal: response.pagination.total,
              recordsFiltered: response.pagination.total,
              data: []
            });
          } else {
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });
          }
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      
    }

    let user = await this.storage.getUserProfile();
    this.isAdmin = (user.role === ROLE.admin || user.role === ROLE.operator);
    try {
      if (this.isAdmin)
        this.planList = <Plan[]>await this.apiService.fetchALLPlan();
      else
        this.planList = <Plan[]>await this.apiService.fetchPlan();

      this.planOptions = this.planList.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err);
    }
  }

  onFileChange(event: DataTransfer){
    this.bulkMember.csv = event.files[0];
  }

  onAsiaFileChange(event: DataTransfer) {
    this.bulkAsiaMember.file = event.files[0];
  }

  async bulkAddMember() {
    this.submitBtn = true;
    if(this.bulkMember.plan_id) {
      try {
        await this.apiService.bulkAddMember(this.bulkMember);
        this.submitSuccess = true;
        this.submitBtn = false;
      } catch(err) {
        console.log(err);
        this.errorMsg = err.error.error_messages;
      }
    }
  }

  async bulkAddAsiaMember() {
    this.submitBtn = true;
    if(this.bulkAsiaMember.file) {
      try {
        await this.apiService.bulkAddAsiaMember(this.bulkAsiaMember);
        this.submitSuccess = true;
        this.submitBtn = false;
      } catch(err) {
        console.log(err);
        this.errorMsg2 = err.error.error_messages;
      }
    }
  }

  async filterMember() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  openViewModal(member) {
    this.editSubmitSuccess = false;
    this.editErrorMsg = null;
    this.memberDetail = member;
    if(member['effective_date'])
      this.effectiveDate = new Date(member['effective_date']);
    console.log(this.effectiveDate);
    if(member['termination_date'])
      this.terminationDate = new Date(member['termination_date']);
    console.log(this.terminationDate);
  }

  async downloadTemplate() {
    try {
      await this.apiService.asiaMemberTemplate();
    } catch (err) {
      console.log(err);
    }
  }

  async downloadMemberTemplate() {
    try {
      await this.apiService.memberEnrollmentTemplate();
    } catch (err) {
      console.log(err);
    }
  }

  resetMsg() {
    this.errorMsg2 = "";
    this.errorMsg = "";
    this.submitSuccess = false;
  }

  async updateMember(member: Member) {
    let effective = this.datePipe.transform(this.effectiveDate, 'yyyy-MM-dd');
    let termination = this.datePipe.transform(this.terminationDate, 'yyyy-MM-dd');    
    
    this.updateLoading = true;
    try {
      await this.apiService.updateEnrollment(member.id, effective, termination);
      this.editSubmitSuccess = true;

      member.effective_date = effective;
      member.termination_date = termination;
    } catch (err) {
      console.error(err);
      this.editSubmitSuccess = false;
      this.editErrorMsg = err.error.error_messages;
    } finally {
      this.updateLoading = false;
    }
  }

}
