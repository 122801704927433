import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSpecialty'
})
export class FilterSpecialtyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || args) {
      return value;
    }
    
    return value.filter(item => item.specialty.toUpperCase().indexOf(args.toUpperCase()) > -1);
  }

}
