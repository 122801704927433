import { District } from './../models/District';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';
import { FetchDistrictsReponse } from '../response/FetchDistrictsReponse';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.scss']
})
export class DistrictComponent implements OnInit {

  districts: District[] = []
  loading: boolean;
  newDistrict: {
    latitude: number,
    longitude: number,
    name: string,
    zh_hant_name: string,
    zh_hans_name: string
  } = {
    latitude: null,
    longitude: null,
    name: '',
    zh_hant_name: '',
    zh_hans_name: ''
  };
  districtDetail: District;

  addLoading: boolean;
  addBtnClicked: boolean = false;
  addErrorMsg: string;
  addSubmitSuccess: boolean = false;

  editLoading: boolean;
  editSubmitBtn: boolean = false;
  editErrorMsg: string;
  editSubmitSuccess: boolean = false;

  modalLoading: boolean;
  importSuccess: boolean;
  importErr: String[];
  file: File;

  searchDistrict = {
    name: null
  }

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(private apiService: ApiService) { }

  async ngOnInit() {
    await this.fetchDistrict();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async fetchDistrict() {
    this.loading = true;
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let response = <FetchDistrictsReponse>await this.apiService.getDistrict(true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1, this.searchDistrict.name);
          this.districts = response.data;
          this.loading = false;

          callback({
            recordsTotal: response.pagination.total,
            recordsFiltered: response.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  async addDistrict() {
    this.addBtnClicked = true;
    this.addErrorMsg = '';
    this.addSubmitSuccess = false;

    if (this.newDistrict.name.length > 0 && this.newDistrict.zh_hant_name.length > 0 && this.newDistrict.zh_hans_name.length > 0 && this.newDistrict.latitude !== null && this.newDistrict.longitude !== null) {
      try {
        this.addLoading = true;
        await this.apiService.addOrEditDistrict(this.newDistrict.name, this.newDistrict.zh_hant_name, this.newDistrict.zh_hans_name, this.newDistrict.latitude, this.newDistrict.longitude);
        this.addSubmitSuccess = true;
        this.resetNewDistrictModel();
        this.fetchDistrict();
      } catch (err) {
        this.addErrorMsg = err.error.error_messages;
        console.error(err);
      } finally {
        this.addLoading = false;
      }
    }
  }

  async editModal(district: District) {
    this.districtDetail = JSON.parse(JSON.stringify(district));
  }

  async editDistrict() {
    this.editErrorMsg = '';
    this.editSubmitSuccess = false;
    let newDetail = {
      id : this.districtDetail.id,
      en_name: this.districtDetail.name,
      hant_name: this.districtDetail.zh_hant_name,
      hans_name: this.districtDetail.zh_hans_name,
      latitude: this.districtDetail.latitude,
      longitude: this.districtDetail.longitude
    }
    try {
      this.editLoading = true;
      await this.apiService.updateDistrict(newDetail);
      let index = this.districts.findIndex(item => item.id === this.districtDetail.id);
      if (index !== -1)
        this.districts[index] = JSON.parse(JSON.stringify(this.districtDetail));
      this.editSubmitSuccess = true;
    } catch (err) {
      console.error(err);
      this.editErrorMsg = err.error.error_messages;
    } finally {
      this.editLoading = false;
    }
  }

  setLatLng(latLong: {latitude: number, longitude: number}) {
    this.newDistrict.latitude = latLong.latitude;
    this.newDistrict.longitude = latLong.longitude;
  }

  private resetNewDistrictModel() {
    this.newDistrict = {
      latitude: null,
      longitude: null,
      name: '',
      zh_hant_name: '',
      zh_hans_name: ''
    };

    this.addBtnClicked = false;
  }

  async refreshList() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

    async import() {
    if (!this.file) return;

    try {
      this.modalLoading = true;
      await this.apiService.importFile(this.file, 'DISTRICT', null);
      this.importSuccess = true;
    } catch(err) {
      console.log(err);
      this.importErr = err.error.error_messages;
    } finally {
      this.modalLoading = false;
    }
  }

  resetModal() { 
    this.importSuccess = false;
    this.importErr = null;
  }

  onFileChange(event: DataTransfer){
    this.file = event.files[0];
  }
}
