import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { Router, ActivatedRoute } from '@angular/router';
import { STATUS, Clinic } from '../models/Clinic';
import { District } from '../models/District';
import { TYPE, Doctor, RESIDENT, GENDER, COUNTRY_CODE } from '../models/Doctor';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Select2OptionData } from 'ng-select2';
import { FetchClinicsResponse } from '../response/FetchClinicsResponse';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Specialty } from '../models/Specialty';

@Component({
  selector: 'app-add-doctor',
  templateUrl: './add-doctor.component.html',
  styleUrls: ['./add-doctor.component.scss']
})
export class AddDoctorComponent implements OnInit {
  doctorId;
  searchPrimaryLicense = '';
  doctor;
  doctorDetail: Doctor = {
    id: null,
    name: null,
    zh_hant_name: null,
    zh_hans_name: null,
    specialty: {
        id: null,
        name: null,
        zh_hant_name: null,
        zh_hans_name: null
    },
    type: null,
    status: null,
    gender: null,
    resident: null,
    email: null,
    nc_email: null,
    nc_created_at: null,
    icon_blob: null,
    year_practice: null,
    primary_license: null,
    secondary_license: null,
    gp_qualification: {
        name: null,
        zh_hant_name: null,
        zh_hans_name: null,
        year: null
    },
    sp_qualification: {
        name: null,
        zh_hant_name: null,
        zh_hans_name: null,
        year: null
    },
    clinic: null,
    doctor_clinic: null,
  };
  errorMsg;
  editErrorMsg;
  submitBtn = false;
  editSuccess = false;
  status = STATUS;
  doctor_type = TYPE;
  resident = RESIDENT;
  gender = GENDER;
  country_code = COUNTRY_CODE;

  loading;

  specialtyList: Specialty[] = [];
  specialtyOptions: Select2OptionData[] =  [];

  doctorAppPhone: string = '';
  doctorAppCountryCode = '852';
  doctorNcEmail = '';
  registered;

  translateText;

  searchClinic = {
    name: "",
    qr_code: "",
    address: "",
    district_id: null
  }
  district: District[] = [];
  districtOptions: Select2OptionData[] = [];
  modalLoading: boolean;

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings;
  clinicList: Clinic[] = [];
  customPerPage: number = 5;

  constructor(private apiService:ApiService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private translate: TranslateService) {
    this.doctorId = this.route.snapshot.paramMap.get("id");
    translate.get([
      'success',
      'doctor.nc_registered_msg',
      'submit_error'
    ]).subscribe((text:string[]) => {
      this.translateText = text;
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  addDoctorClinic(clinicId) {
    $('#modal-link-clinic').modal("hide");
    this.router.navigate(['/doctor-clinic/add'], { queryParams: {clinicId: clinicId, doctorId: this.doctorId}} );
  }

  async ngOnInit() {
    if (this.doctorId) {
      this.fetchDoctor(this.doctorId);
    }
    this.fetchSpecialty();
    this.fetchClinic();
    this.fetchDistrict();
  }

  async fetchDoctor (id) {
    this.loading = true;
    try {
      this.doctorDetail = <Doctor>await this.apiService.adminGetDoctorList(id);
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  async fetchSpecialty () {
    try {
      this.specialtyList = <Specialty[]>await this.apiService.getSpecialty(false);
      this.specialtyOptions = this.specialtyList.map(item => ({id: item.id, text: item.name}));
    } catch (err) {
      console.log(err)
    }
  }

  async add() {
    this.submitBtn = true;
    if(this.doctorDetail.status && this.doctorDetail.name && this.doctorDetail.zh_hant_name && this.doctorDetail.zh_hans_name && this.doctorDetail.primary_license && this.doctorDetail.specialty.id) {
      try {
        this.editErrorMsg = "";
        this.editSuccess = false;
        this.loading = true;
        let doctorinfo = {
          id: this.doctorDetail.id,
          status: this.doctorDetail.status,
          type: this.doctorDetail.type,
          gender: this.doctorDetail.gender,
          resident: this.doctorDetail.resident,
          primary_license: this.doctorDetail.primary_license,
          secondary_license: this.doctorDetail.secondary_license,
          gp_registered_year: this.doctorDetail.gp_qualification.year,
          sp_registered_year: this.doctorDetail.sp_qualification.year,
          email: this.doctorDetail.email,
          specialty_id: this.doctorDetail.specialty.id,
          en_name: this.doctorDetail.name,
          hant_name: this.doctorDetail.zh_hant_name,
          hans_name: this.doctorDetail.zh_hans_name,
          en_gp_qualification:this.doctorDetail.gp_qualification.name,
          hant_gp_qualification:this.doctorDetail.gp_qualification.zh_hant_name,
          hans_gp_qualification:this.doctorDetail.gp_qualification.zh_hans_name,
          en_sp_qualification:this.doctorDetail.sp_qualification.name,
          hant_sp_qualification:this.doctorDetail.sp_qualification.zh_hant_name,
          hans_sp_qualification:this.doctorDetail.sp_qualification.zh_hans_name,
        }
        let result = await this.apiService.addNewDoctor(doctorinfo);
        if(!this.doctorId)
          this.router.navigate(['/user/doctor/edit', result.doctor.id]);
        else {
          this.fetchDoctor(this.doctorDetail.id);
          this.editSuccess = true;
        }
        this.submitBtn = false;
      } catch (err) {
        console.log(err);
        this.editErrorMsg = err.error.error_messages;
      } finally {
        this.loading = false;
      }
    }
  }

  async connect() {
    this.registered = false;
    this.errorMsg = null;
    let ncDoctor
    if(this.doctorAppPhone.length > 0)
      ncDoctor = {
        country_code: this.doctorAppCountryCode,
        phone: this.doctorAppPhone,
        doctor_id: this.doctorDetail.id
      }
    else 
      ncDoctor = {
        email: this.doctorNcEmail,
        doctor_id: this.doctorDetail.id
      }
    try {
      await this.apiService.registerNCDoctor(ncDoctor);
      this.registered = true;
      this.fetchDoctor(this.doctorDetail.id);
      this.toastr.success( this.translateText['doctor.nc_registered_msg'],this.translateText['success'], {
        positionClass: "toast-bottom-full-width"
      });
    } catch (err) {
      this.errorMsg = err.error.error_messages;
      this.toastr.error( err.error.error_messages, this.translateText['submit_error'], {
        positionClass: "toast-bottom-full-width"
      });
    }
  }

  async fetchClinic() {
    this.modalLoading = true;
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: this.customPerPage,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.modalLoading = true; 
          let clinics = <FetchClinicsResponse>await this.apiService.getClinic(null, true, this.customPerPage, dataTablesParameters.start/this.customPerPage + 1, this.searchClinic.name, this.searchClinic.qr_code, this.searchClinic.address, this.searchClinic.district_id);
          this.clinicList = clinics.data;
          this.modalLoading = false;

          callback({
            recordsTotal: clinics.pagination.total,
            recordsFiltered: clinics.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.modalLoading = false;
    }
  }

  async fetchDistrict() {
    try {
      this.district = <District[]>await this.apiService.getDistrict();
      this.districtOptions = this.district.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err)
    }
  }

  async filterClinic() {   
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }


}
