import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { PreapprovalFormService } from '../services/preapproval-form.service';
import { ValidatorService } from '../services/validator.service';
import { INSURER_ID } from '../models/Insurer';
import { ApiService } from '../services/api-service';
import { PREAPPROVAL_SERVICE_CATEGORY } from '../models/Preapproval';

@Component({
  selector: 'app-preapproval-form',
  templateUrl: './preapproval-form.component.html',
  styleUrls: ['./preapproval-form.component.scss']
})
export class PreapprovalFormComponent implements OnInit {
  @Input() viewMode: boolean = false;
  @Input() form: FormGroup;
  @Input() insurerId: string = null;

  // ML
  @Input() package: string = null;
  @Input() facilityId: string = null;
  @Input() paToken: string = null;
  @Input() optionalData: any = {};
  @Input() serviceItems: any;
  @Input() facilityOptions: Select2OptionData[] = [];
  @Input() diagnosisOptions: Select2OptionData[] = [];

  @Output() onSubmit = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  roomType: string = null;
  anaesthesiaOptions: Select2OptionData[] = [];
  packageOptions: Select2OptionData[] = [];
  roomTypeOptions: Select2OptionData[] = [];

  totalAgreeFee: number = 0;
  totalRequestAmount: number = 0;
  validate: boolean = true;
  
  today;
  beforeToday;
  OPTION_DAY_CASE: string = '6';  // default value

  constructor(private preapprovalService: PreapprovalFormService, private validatorService: ValidatorService, private apiService: ApiService) { }
  
  async ngOnInit() {
    if (!this.viewMode) {
      await this.fetchPackage(this.insurerId);
      this.setDate();
    }
  }

  ngOnChanges() {
    if (!this.viewMode) {
      if (this.form && this.insurerId === INSURER_ID.MANULIFE) {
        this.form.get('sympHistory').valueChanges.subscribe((selectedValue) => {
          if (selectedValue === 'Y') {
            this.setField('sympHistoryDate', false, "", [Validators.required]);
            this.setField('sympHistoryDetail', false, "", [Validators.required, this.validatorService.isEnglish(), Validators.maxLength(200)]);
          } else {
            this.setField('sympHistoryDate', true, "", null);
            this.setField('sympHistoryDetail', true, "", null);
          }
        });
        this.form.get('docOthRole').valueChanges.subscribe((selectedValue) => {
          if (selectedValue === 'Y') {
            this.setField('docOthRoleDesc', false, "", [Validators.required, this.validatorService.isEnglish(), Validators.maxLength(100)]);
          } else {
            this.setField('docOthRoleDesc', true, "", null);
          }
        });
        this.form.get('confineBeginDate').valueChanges.subscribe((selectedValue) => {
          this.setField('confineEndDate', false, selectedValue, [Validators.required]);
        });
      }
      // init value
      if (this.facilityId && this.package) {
        this.getBenefits(false);
      }
    }

    if (!this.serviceItems) {
      this.resetService();
    } else {
      this.calculateFee();
    }
  }

  async fetchPackage(insurerId) {
    let res = await this.apiService.getBenefitCategories(insurerId);
    this.packageOptions = res.map(item => { 
      return {
        id: item.id, 
        text: item.name
      }
    });
  }
  
  deleteForm() {
    this.onDelete.emit(this.paToken);
  }

  setDate() {
    let today = new Date;
    this.today = {
      year: today.getFullYear(),
      month: (today.getMonth()) + 1,
      day: today.getDate()
    }
    today.setDate(today.getDate()-1);
    this.beforeToday = {
      year: today.getFullYear(),
      month: (today.getMonth()) + 1,
      day: today.getDate()
    }
  }
  
  getPackageName(id) {
    return this.packageOptions[this.packageOptions.findIndex(x => x && x.id.toString() === id.toString())].text;
  }

  saveForm() {
    let formData = this.form.getRawValue();
    formData.serviceItems = this.serviceItems;
    this.onSubmit.emit({formData: formData, submit: false, facilityId: this.facilityId, benefitCategoryId: this.package});
  }

  setField(field: string, disabled: boolean, value: any, validator: any[]) {
    let control = this.form.controls[field];
    if (disabled)
      control.disable();
    else
      control.enable();
    control.setValue(value);
    control.setValidators(validator);
    control.updateValueAndValidity();
  }

  gotoTop() {
    window.scroll({ 
      top: 0, 
      behavior: 'smooth' 
    });
  }

  submitForm() {
    if (this.insurerId === INSURER_ID.MANULIFE) {
      this.validate = false;
      if (!this.form.valid || !this.validateService()) {
        this.gotoTop();
        return;
      }

      this.form.get('miscFee').setValue(this.serviceItems.miscItem.requested_amount);
      this.form.get('anaesthetistFee').setValue(this.serviceItems.anaestItem.requested_amount);
      this.form.get('operaTheaFee').setValue(this.serviceItems.facilityItem.requested_amount);

      let formData = this.form.getRawValue();  // save form details
      let insurerData = this.form.getRawValue();	// get value from disabled field
      formData.serviceItems = this.serviceItems;
      
      insurerData.surgicalDetails = [];
      let items = [];

      // surgical items
      this.serviceItems.surgicalItem.forEach(service => {
        insurerData.surgicalDetails.push({
          surgCode: service.insurer_code,
          agreedFee: service.agree_fee,
          requestAmount: parseFloat(service.requested_amount) + parseFloat(service.admin_fee)
        });
        items.push(this.constructItem(service, 'surgicalDetails'));
      });
      // optional item
      if (this.serviceItems.optionalItem) {
        let optionalItem = this.serviceItems.optionalItem;
        insurerData.surgicalDetails.push({
          surgCode: optionalItem.insurer_code,
          agreedFee: optionalItem.agree_fee,
          requestAmount: parseFloat(optionalItem.requested_amount) + parseFloat(optionalItem.admin_fee)
        });
        items.push(this.constructItem(optionalItem, 'surgicalDetails'));
      }

      items.push(this.constructItem(this.serviceItems.miscItem, 'miscFee'));
      items.push(this.constructItem(this.serviceItems.anaestItem, 'anaesthetistFee'));
      items.push(this.constructItem(this.serviceItems.facilityItem, 'operaTheaFee'));

      insurerData = this.preapprovalService.constructManulifePayload(insurerData);
      console.log(insurerData);
      console.log(items);
      this.onSubmit.emit({formData: formData, insurerData: insurerData, submit: true, facilityId: this.facilityId, items: items, benefitCategoryId: this.package});
    }
  }

  constructItem(item, fieldName) {
    return {
      offer_service_id: item.offer_service_id,
      insurer_code: item.insurer_code,
      insurer_field_name: fieldName,
      requested_amount: parseFloat(item.requested_amount) + parseFloat(item.admin_fee)
    }
  }

  validateRow(key) {
    let validate = this.form && (this.form.controls[key].disabled || this.form.controls[key].valid);
    return (this.validate || validate);
  }

  validateService() {
    if (!this.serviceItems.surgicalItem || this.serviceItems.surgicalItem.length === 0) return false;

    for (let i = 0; i < this.serviceItems.surgicalItem.length; i++) {
      if (!this.checkRequestAmount(this.serviceItems.surgicalItem[i].requested_amount)) return false;
    }
    return true;
	}

  resetService() {
    this.serviceItems = {
      surgicalItem: [],
      optionalItem: null,
      // selectOptItem: false
      miscItem: null,
      anaestItem: null,
      facilityItem: null
    };
  
    this.anaesthesiaOptions = [];
    this.roomTypeOptions = [];
    this.calculateFee();
  }

  // getDiagnosisOption(specialtyId: string, i) {
  //   this.onChangeSpecialty.emit({specialtyId: specialtyId, type: SPECIALTY_OPTION_TYPE.diagnosis, optionIndex: i});
  // }

  // getServiceOption(specialtyId: string, i) {
  //   this.onChangeSpecialty.emit({specialtyId: specialtyId, type: SPECIALTY_OPTION_TYPE.service, optionIndex: i});
  // }
  
	calculateFee() {
    this.totalRequestAmount = 0;
    this.totalAgreeFee = 0;

    if (this.serviceItems.surgicalItem) {
      this.serviceItems.surgicalItem.forEach(item => {
        this.accumulateTotal(item);
      });
    }
    this.accumulateTotal(this.serviceItems.optionalItem);
    this.accumulateTotal(this.serviceItems.miscItem);
    this.accumulateTotal(this.serviceItems.anaestItem);
    this.accumulateTotal(this.serviceItems.facilityItem);
  }
  
  accumulateTotal(item) {
    if (item) {
      this.totalRequestAmount += parseFloat(item.requested_amount);
      this.totalRequestAmount += parseFloat(item.admin_fee);
      this.totalAgreeFee += parseFloat(item.agree_fee);
    }
  }

  checkIfNumber(val) {
    return isNaN(val)? 'N/A' : val;
  }

  setService(e, type) {
    this.resetService();
    if (type === 'hospCode') {
      let index = this.facilityOptions.findIndex(x => x && x.id === e);
      this.facilityId = (index >= 0)? this.facilityOptions[index].additional : null;
    }
    if (type === 'package')
      this.package = e;
    
    if (this.facilityId && this.package) {
      this.getBenefits(true);
    }
  }

  async getBenefits(resetFee: boolean = false) {
    try {
      let res = await this.apiService.getPreapprovalBenefit(this.paToken, this.facilityId, this.package);
      let anaesthesiaOptions = [];
      let roomTypeOptions = [];
      let surgicalList = [];
      res.forEach(item => {
        let option = {
          id: item.insurer_code,
          text: item.insurer_code_description,
          additional: item
        };

        item.requested_amount = item.agree_fee;
        switch (item.service_category) {
          case PREAPPROVAL_SERVICE_CATEGORY.SURGICAL_PROCEDURE:
            if (resetFee) {
              if (item.insurer_code === 'A210') {
                this.serviceItems.optionalItem = item;
              } else {
                surgicalList.push(item);
              }
            }
            break;
          case PREAPPROVAL_SERVICE_CATEGORY.SEDATION:
            anaesthesiaOptions.push(option);
            break;
          case PREAPPROVAL_SERVICE_CATEGORY.FACILITY:
            roomTypeOptions.push(option);
            break;
          case PREAPPROVAL_SERVICE_CATEGORY.LABORATORY:
            if (resetFee) this.serviceItems.miscItem = item;
            break;
        }
      });
      this.anaesthesiaOptions = anaesthesiaOptions;
      this.roomTypeOptions = roomTypeOptions;
      this.roomType = this.form.value['roomType'];

      if (resetFee) {
        this.serviceItems.surgicalItem = surgicalList;
        this.setListItem('anaestType', null);
        this.setListItem('roomType', null);
      }
      this.calculateFee();
    } catch (err) {
      console.log(err)
    }
  }

  setListItem(key, data) {
    let value = null;
    console.log(key, data, this.anaesthesiaOptions)
    switch(key) {
      case 'anaestType':
        let _anaestItem = null;
      
        _anaestItem = this.anaesthesiaOptions.filter(item => item.id === data).shift()
        this.serviceItems.anaestItem = _anaestItem? _anaestItem.additional : null;
        value = _anaestItem? _anaestItem.id : null;
        break;
      case 'roomType':
        const _facilityItem = this.roomTypeOptions.filter(item => item.id === data).shift()

        this.serviceItems.facilityItem = _facilityItem ? _facilityItem.additional : null;
        value = _facilityItem ? _facilityItem.id : null;
        // if (data) {
          
        //   this.serviceItems.facilityItem = data.additional;
        //   value = data.id;
        // } else {
        //   this.roomType = this.OPTION_DAY_CASE;
        //   let index = this.roomTypeOptions.findIndex(x => x && x.id === this.roomType);
        //   this.serviceItems.facilityItem = this.roomTypeOptions[index].additional;
        //   value = this.roomType;
        // }
        break;
    }
    this.form.get(key).setValue(value);
    this.calculateFee();
  }

  getFee(key, optionList) {
    let index = optionList.findIndex(x => x && x.id === this.form.value[key]);
    let fee = (index >= 0)? optionList[index].additional.agree_fee : 0;
    return fee;
  }

  checkRequestAmount(val) {
    if (val === null || val === '' || val.toString().indexOf(' ') >= 0 || isNaN(val) || val > 999999999.99) return false;
    return true;
  }

  getSubtotal(item) {
    let val = parseFloat(item.requested_amount) + parseFloat(item.admin_fee);
    return this.checkIfNumber(val);
  }

  // validateDiagnosis(val?) {
  //   if (val || this.validateRow('diagnosisCode')) {
  //     document.getElementById('diagnosis').classList.remove('is-invalid');
  //   } else {
  //     document.getElementById('diagnosis').classList.add('is-invalid');
  //   }
  // }
}
