import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { ActivatedRoute } from '@angular/router';
import { PreapprovalQuestion, SECTION_ALIAS } from '../models/PreapprovalQuestion';
import { TranslateService } from '@ngx-translate/core';
import { User, ROLE } from '../models/User';
import { LocalStorageService } from '../services/local-storage.service';
import { PREAPPROVAL_STATUS } from '../models/Preapproval';
import { FormGroup } from '@angular/forms';
import { PreapprovalFormService } from '../services/preapproval-form.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-approval-detail',
  templateUrl: './approval-detail.component.html',
  styleUrls: ['./approval-detail.component.scss']
})
export class ApprovalDetailComponent implements OnInit {
  private translatedText;

  user: User;
  role = ROLE;

  loading: boolean;
  modalLoading: boolean;
  validate: boolean = true;
  viewMode: boolean = true;
  approved: boolean = false;
  declined: boolean = false;

  // dynamic form
  // totalFee: number = 0;
  // dynamicQuestions: any[] = [];
  // diagnoses: PreapprovalQuestion;
  // services: PreapprovalQuestion;
  // submitSuccess: boolean;
  // submitError: string;
  // doctorComment: string;
  // payorComment: string;
  // comment: string;
  // formPending: boolean;

  pa_token: string;
  reference_no: string;
	preapprovalDetail;
  insurerId: string = null;
  // for ML
  form: FormGroup;
	facilityId: string = null;
	items: any[] = null;
  optionalData: any = {};
  serviceItems: any[] = [];
  approvedItems: any[] = [];
  copayAmount: number = 0;
  totalApprovedAmount: number = 0;
  totalRequestedAmount: number = 0;
  // totalClaimedAmount: number = 0;

  planId={
    'dev':'1344',
    'sit':'135',
    'uat':'25',
    'prod':'5',
  };
  PLAN_ID = this.planId[environment.env];

  constructor(private apiService: ApiService, private route: ActivatedRoute, private preapprovalService: PreapprovalFormService, private storage: LocalStorageService, private translate: TranslateService) {
    this.pa_token = this.route.snapshot.paramMap.get('pa_token');
    // this.translate.get([
    //   'yes',
    //   'no',
    //   'not_available',
    // ]).subscribe(res => this.translatedText = res);
  }

  async ngOnInit() {
    try {
      this.loading = true;
      await this.getPreapprovalDetail(this.pa_token);
      this.getPreapprovedItemsDetail(this.pa_token);
      this.user = await this.storage.getUserProfile();
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false
    }
  }

  async getPreapprovalDetail(paToken: string) {
    try {
      this.preapprovalDetail = await this.apiService.getPreapproval(paToken);
      this.insurerId = this.preapprovalDetail.insurer_id;
      this.facilityId = this.preapprovalDetail.facility_id;

      switch (this.preapprovalDetail.status) {
        case PREAPPROVAL_STATUS.APPROVED:
        case PREAPPROVAL_STATUS.CLAIMED:
          this.approved = true;
          break;
        case PREAPPROVAL_STATUS.DECLINED:
          this.declined = true;
          break;
      }

      let formDetails = this.preapprovalDetail.form_details;
      let roomType = await this.getInsurerCodeName(formDetails.roomType);
      let anaestType = await this.getInsurerCodeName(formDetails.anaestType);
      
      this.optionalData = {
        facilityName: this.preapprovalDetail.facility_name,
        benefitCatrgoryName: this.preapprovalDetail.benefit_catrgory_name,
        roomType: roomType,
        anaestType: anaestType
      };

      this.serviceItems = formDetails.serviceItems;
      this.form = this.preapprovalService.getInsurerPreapprovalForm(this.insurerId, formDetails, null, this.viewMode);	
    } catch (err) {
        console.log(err);
    }
  }

  async getInsurerCodeName(code) {
    let name = null;
    if (code) {
      try {
        let mappings = await this.apiService.getInsurerCodeMappings(code, this.insurerId);
        if (mappings.length > 0) {
          name = mappings[0].name;
        } 
      } catch (err) {
        console.log(err);
      }
    }
    return name;
  }

  async getPreapprovedItemsDetail(paToken: string) {
    try {
      let res = await this.apiService.getPreapprovedItemsDetail(paToken);
      this.approvedItems = res.items;
      this.totalApprovedAmount = res.total_approved_amount;
      if(this.isMinus1000()){
        this.totalApprovedAmount = this.totalApprovedAmount-1000>=0?this.totalApprovedAmount-1000:0;
      }

      this.approvedItems.forEach(item => {
        this.totalRequestedAmount += item.requested_amount;
        // this.totalClaimedAmount += item.claim_amount;
      });
      let copay = this.totalRequestedAmount - this.totalApprovedAmount;
      if (copay > 0) this.copayAmount = copay;
    } catch (err) {
        console.log(err);
    }
  }

  // async reply(status: PREAPPROVAL_STATUS) {
	// 	try {
  //     this.modalLoading = true;
  //     this.submitError = "";
	// 		await this.apiService.replyPreapproval(this.pa_token, status, this.comment);
  //     this.submitSuccess = true;
	// 	} catch (err) {
	// 		console.log(err);
	// 		this.submitError = err.error.error_messages;
	// 	} finally {
	// 		this.modalLoading = false;
	// 	}
	// }

  // parseVal(val) {
  //   switch(val) {
  //     case null:
  //       return this.translatedText['not_available'];
  //     case true:
  //       return this.translatedText['yes'];
  //     case false:
  //       return this.translatedText['no']
  //     default:
  //       return val;
  //   }
  // }

  // approve() {
  //   this.reply(PREAPPROVAL_STATUS.APPROVED);
  // }

  // reject() {
  //   this.validate = false;
  //   if (!this.comment) return;
  //   this.reply(PREAPPROVAL_STATUS.REJECTED);
  // }

  // resetModal() {
  //   this.validate = true;
  //   this.submitError = "";
  //   this.comment = null;
  // }

  isMinus1000(){
    return this.preapprovalDetail.medical_card.plan_id==this.PLAN_ID;
  }
}
