import { Component, OnInit } from '@angular/core';
import { STATUS } from '../models/Clinic';
import { ApiService } from '../services/api-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { CONTRACT_STATUS } from '../models/DoctorClinic';

@Component({
  selector: 'app-edit-doctor-clinic',
  templateUrl: './edit-doctor-clinic.component.html',
  styleUrls: ['./edit-doctor-clinic.component.scss']
})
export class EditDoctorClinicComponent implements OnInit {

  doctorClinicId;
  doctorClinicDetail = {
    id: null,
    clinic_id: null,
    doctor_id: null,
    cs_subscribed: false,
    cs_version: null,
    docnet_contract_status: null,
    dual_specialty: false,
    estimated_time: null,
    has_appointment: false,
    has_remote: false,
    ipo_login_id_primary: null,
    ipo_login_id_secondary: null,
    nc_subscribed: false,
    network_group: [],
    novation_status: null,
    status: null,
    ticket_deposit: null,
    show_on_heals_app: false,
    show_on_alipay_mini_program: false
  };
  loading: boolean;
  modalLoading: boolean;
  payorList: any[] = [];
  payorOptions: Select2OptionData[] = [];
  status = STATUS;
  contractStatus = CONTRACT_STATUS;
  submitError: string;
  submitSuccess: boolean;
  validate: boolean = true;
  networkGroup: any = [];

  constructor(private apiService:ApiService, private router: Router, private route: ActivatedRoute) {
    this.doctorClinicId = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.fetchPayors();
    if (this.doctorClinicId) {
      this.fetchDoctorClinic(this.doctorClinicId);
    } else {
      this.route.queryParams.subscribe(params => {
        if (params['clinicId'] && params['doctorId'])
          this.fetchDoctorClinic(null, params['clinicId'], params['doctorId']);
      });
    }
  }

  async fetchDoctorClinic(id, clinicId?, doctor_id?) {
    try {
      this.loading = true;
      let res = await this.apiService.getDoctorClinic(id, clinicId, doctor_id);
      if (res.id) {
        this.doctorClinicId = res.id;
        this.doctorClinicDetail = res;
        this.doctorClinicDetail.network_group = this.doctorClinicDetail.network_group? this.doctorClinicDetail.network_group : [];
      } else {
        this.doctorClinicDetail.clinic_id = clinicId;
        this.doctorClinicDetail.doctor_id = doctor_id;
      }
      this.fetchNetworkGroupList();
    } catch (err) {
      console.log(err);
      this.router.navigateByUrl('doctor-clinic');
    } finally {
      this.loading = false;
    }
  }

  async fetchPayors() {
    this.payorList = await this.apiService.getPayors();
    this.payorOptions = this.payorList.map(item => {
      return {
        id: item.id,
        text: item.name
      };
    });
  }

  async fetchNetworkGroupList() {
    try {
      let res = await this.apiService.getNetworkGroup();
      this.networkGroup = res.map(item => {
        return {
          id: item.id,
          name: item.name,
          selected: this.selectedGroup(item.id)
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  async saveDoctorClinic() {
    this.submitError = "";
    this.validate = false;
    this.submitSuccess = false;

    if (!this.doctorClinicDetail.status || !this.doctorClinicDetail.estimated_time || this.doctorClinicDetail.ticket_deposit === '' || this.doctorClinicDetail.ticket_deposit === null) return;
    
    try {
      this.validate = true;
      this.submitError = "";
      this.loading = true;
      let group = [];
      this.networkGroup.forEach(item => {
        if (item.selected) group.push(item.id);
      });
      this.doctorClinicDetail.network_group = group;
      let res = await this.apiService.updateDoctorClinic(this.doctorClinicDetail);
      if(!this.doctorClinicDetail.id) {
        this.router.navigate(['/doctor-clinic/edit', res.doctor_clinic.id])
      }
      this.submitSuccess = true;
    } catch (err) {
      console.log(err);
      this.submitError = err.error.error_messages;
    } finally {
      this.loading = false;
    }
  }

  selectedGroup(id) {
    return this.doctorClinicDetail.network_group.findIndex(x => x === id) != -1;
  }
}
