import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ApiService } from '../services/api-service';
import { SortArrayWithServiceBySpecialtyPipe } from '../pipes/sort-array-with-service-by-specialty.pipe';
import { Service_Item } from '../models/Service_Item';
import { SortServiceItemPipe } from '../pipes/sort-service-item.pipe';
import { Plan } from '../models/Plan';
import { Specialty } from '../models/Specialty';
import { Select2OptionData } from 'ng-select2';
import { ServiceCategory } from '../models/ServiceCategory';
import { FilterServiceCategoryPipe } from '../pipes/filter-service-category.pipe';

@Component({
  selector: 'template-fee-table',
  templateUrl: './template-fee-table.component.html',
  styleUrls: ['./template-fee-table.component.scss']
})
export class TemplateFeeTableComponent implements OnInit, OnChanges {
  @Input('itemList') itemList: {
    service: Service_Item,
    agree_fee: number,
    // copay_unit,
    // copay: number
  }[] = [];
  @Input('planDetail') planDetail : Plan;
  @Input('specialtyList') specialtyList: Specialty[];
  @Input('warningDisplay') warningDisplay: boolean = false;

  @Output() submit = new EventEmitter();

  specialtyOptions: Select2OptionData[] = [];
  
  // serviceList = [];
  categoryList: ServiceCategory[] = [];
  categoryOptions: Select2OptionData[] = [];
  selectedSpecialty = null;
  selectedCategory = null;
  
  newItem: {
    service?: Service_Item,
    agree_fee: number,
  };
  fetchItemArr = [];

  loadingItems: boolean = false;

  constructor(private apiService: ApiService, private sortingPipe: SortArrayWithServiceBySpecialtyPipe, private sortName: SortServiceItemPipe, private filterServiceCategory: FilterServiceCategoryPipe) { 
  }

  ngOnInit() {
    this.newItem = {
      agree_fee: 0,
      service: null
    };
    // this.fetchSpecialtyList();
    this.fetchCategoryList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.specialtyList) {
      this.specialtyOptions = changes.specialtyList.currentValue.map((item: Specialty) => {
        return {
          id: item.id,
          text: item.name
        };
      });
    }
  }

  // async fetchServiceList(id) {
  //   try {
  //     this.loadingItems = true;
  //     this.serviceList = await this.apiService.serviceItemList(id);
  //     // serviceList.forEach(item => {
  //     //   this.serviceList.push(item);
  //     // });
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     this.loadingItems = false;
  //   }
  // }

  // async fetchSpecialtyList() {
  //   try {
  //     this.specialtyList = await this.apiService.getSpecialtyList();
  //     this.specialtyList = this.sortName.transform(this.specialtyList);
  //     if(this.specialtyList.length > 0) {
  //       this.specialtyList.forEach(item => {
  //         this.fetchItemArr.push(false);
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  async fetchCategoryList() {
    try {
      this.categoryList = await this.apiService.getServiceCategory();
      this.categoryList = this.sortName.transform(this.categoryList);
      this.categoryOptions = this.categoryList.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err);
    }
  }

  addNewFee() {
    if (this.newItem.service) {
      if (this.itemList.length > 0 && this.itemList.findIndex(item => item.service.id === this.newItem.service.id) > -1) {
        let index = this.itemList.findIndex(item => item.service.id === this.newItem.service.id);
        this.itemList[index].agree_fee = this.newItem.agree_fee
        // this.itemList[index].copay = this.newItem.copay
        // this.itemList[index].copay_unit = this.newItem.copay_unit
      } else {
        let item = {
          service: this.newItem.service,
          agree_fee: this.newItem.agree_fee,
          // copay_unit: this.newItem.copay_unit,
          // copay: this.newItem.copay
        }
        this.itemList.push(item);
      }
      let index = this.planDetail.services.findIndex(item => item.id === this.newItem.service.id);
      this.planDetail.services.splice(index, 1);
      this.itemList = this.sortingPipe.transform(this.itemList);
      this.newItem.service = null;

    }
  }

  removeFee(index) { 
    this.planDetail.services.push(this.itemList[index].service);
    this.itemList.splice(index, 1);
    this.planDetail.services = this.sortName.transform(this.planDetail.services);
  }

  submitBtn() {
    let list = [];
    this.itemList.forEach(item => {
      list.push({
        id: item.service.id,
        agree_fee: item.agree_fee,
        // copay_unit: item.copay_unit,
        // copay: item.copay
      })
    });
    this.submit.emit(list);
  }

  filterListBySpecialtyId(arr, selector) {
    if(!arr || !selector)
      return arr;
    return arr.filter(item => item.specialty.id == selector);
  }

  getSelect2OptionsOfServiceItems(arr: Service_Item[], selector) {
    if (arr) {
      let array = this.filterListBySpecialtyId(arr, selector);
      array = this.filterServiceCategory.transform(array, this.selectedCategory);
      return array.map(value => {
        return {
          id: value.id,
          text: value.name
        };
      });
    } else
      return [];
  }

  selectedServiceItem(event) {
    this.newItem.service = this.planDetail.services.find(item => item.id == event['value']);
  }

  // filterChanged() {
  //   if(this.selectedSpecialty > 0 && !this.fetchItemArr[this.selectedSpecialty]) {
  //     this.fetchServiceList(this.selectedSpecialty);
  //     this.fetchItemArr[this.selectedSpecialty] = true;
  //   }
  // }

  specailtyName(id) {
    let index = this.specialtyList.findIndex( item => item.id === id );
    return this.specialtyList[index].name;
  }

  addAllService() {
    let list;
    if(this.selectedSpecialty)
      list = JSON.parse(JSON.stringify(this.filterListBySpecialtyId(this.planDetail.services, this.selectedSpecialty)));
    else
      list = JSON.parse(JSON.stringify(this.planDetail.services));
    list.forEach(item => {
      this.newItem.service = item;
      this.addNewFee();
    });
  }

  defaultPrice() {
    let list;
    if(this.selectedSpecialty)
      list = JSON.parse(JSON.stringify(this.filterListBySpecialtyId(this.planDetail.services, this.selectedSpecialty)));
    else
      list = JSON.parse(JSON.stringify(this.planDetail.services));
    list.forEach(item => {
      this.newItem.service = item;
      switch(item.id) {
        case 1:
        case 2:
          this.newItem.agree_fee = 0;
        break;
        case 3:
          this.newItem.agree_fee = 4600;
        break;
        case 4:
          this.newItem.agree_fee = 6600;
        break;
        case 5:
          this.newItem.agree_fee = 12000;
        break;
        case 6:
          this.newItem.agree_fee = 1700;
        break;
        case 7:
        case 9:
        case 10:
          this.newItem.agree_fee = 2400;
        break;
        case 8:
        case 11:
          this.newItem.agree_fee = 3000;
        break;
        case 12:
          this.newItem.agree_fee = 2000;
        break;
        case 13:
          this.newItem.agree_fee = 900;
        break;
        case 14:
          this.newItem.agree_fee = 3700;
        break;
        case 15:
          this.newItem.agree_fee = 4900;
        break;
      }
      this.addNewFee();
    });
  }
}

