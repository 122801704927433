import { Doctor } from './../models/Doctor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { OFFER_STATUS, Offer } from '../models/Offer';
import { PER_PAGE } from '../constant/config';
import { User, ROLE } from '../models/User';
import { LocalStorageService } from '../services/local-storage.service';
import { Select2OptionData } from 'ng-select2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Plan } from '../models/Plan';

@Component({
  selector: 'pending-offer',
  templateUrl: './pending-offer.component.html',
  styleUrls: ['./pending-offer.component.scss']
})
export class PendingOfferComponent implements OnInit {

  offers: Offer[] = [];
  contractList: string[] = [];
  doctorList: string[] = [];
  payorList = [];
  payorOption: Select2OptionData[] = [];
  planList: Select2OptionData[] = [];
  selectedContract = '-1';
  selectedDoctor = '-1';
  offerStatus = OFFER_STATUS;
  loading: boolean;

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  user: User;
  role = ROLE;
  searchOffer = {
    doctorName: null,
    planName: null,
    constractName: null,
    insurerId: null
  }

  constructor(private apiService: ApiService, private storage: LocalStorageService) { }

  async ngOnInit() {
    await this.fetchPendingOffer();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async fetchPendingOffer () {
    this.loading = true;
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let user = await this.storage.getUserProfile();
          if(this.planList.length === 0)
            await this.fetchPlan();
          let offers;
          if(user.role === ROLE.payor)
            offers = await this.apiService.getSignedOffer(null, "PENDING", true, PER_PAGE, (dataTablesParameters.start/PER_PAGE + 1));
          else if(user.role === ROLE.admin || user.role === ROLE.operator)
            offers = await this.apiService.adminGetOffer(null, "PENDING", true, PER_PAGE, (dataTablesParameters.start/PER_PAGE + 1), this.searchOffer.insurerId, this.searchOffer.doctorName, this.searchOffer.planName);
          else
            offers = await this.apiService.doctorGetPending(true, PER_PAGE, (dataTablesParameters.start/PER_PAGE + 1));
          this.offers = offers.data;

          this.offers.forEach(item => {
            this.contractList.push(<string>item.contract);
            this.doctorList.push(<string>item.doctor);
          });
          this.loading = false;

          callback({
            recordsTotal: offers.pagination.total,
            recordsFiltered: offers.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }

    this.user = await this.storage.getUserProfile();
    if(this.user.role === ROLE.admin || this.user.role === ROLE.operator)
      this.fetchPayor();
    
    // try {
    //   // this.offers = await this.apiService.getPedningOffer();
    //   this.offers['data'].forEach(item => {
    //     this.contractList.push(item.contract);
    //     this.doctorList.push(item.doctor);
    //   });
    //   // this.contractList = this.removeDuplicates(this.contractList, 'id');
    //   // this.doctorList = this.removeDuplicates(this.doctorList, 'primary_license');
    //   this.doctorList = this.doctorList.filter(function(item, pos, self) {
    //     return self.indexOf(item) == pos;
    // })
    // this.doctorList.sort();
    // this.contractList = this.contractList.filter(function(item, pos, self) {
    //     return self.indexOf(item) == pos;
    // })
    // this.contractList.sort();
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   this.loading = false;
    // }
  }

  removeDuplicates(myArr: any[], prop: string): any[] {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  async fetchPayor() {
    try {
      this.payorList = await this.apiService.getPayors(null, false);
      this.payorOption = this.payorList.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err)
    }
  }

  async refreshList() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  async fetchPlan() {
    if (!this.user)
      this.user = await this.storage.getUserProfile();
    let plan:Plan[]
    if (this.user.role === ROLE.admin || this.user.role === ROLE.operator)
      plan = <Plan[]>(await this.apiService.fetchALLPlan());
    else if (this.user.role === ROLE.payor)
      plan = <Plan[]>(await this.apiService.fetchPlan());
    if(plan) {
      this.planList = plan.map(item => {
        return {
          id: item.name,
          text: item.name
        };
      });
    }
  }

}
