import { InputFileComponent } from './component/input-file/input-file.component';
import { FilterServiceTypePipe } from './pipes/filter-service-type.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from './services/api-service';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgSelect2Module } from 'ng-select2';
import { TagInputModule } from 'ngx-chips';
import { ChartsModule } from 'ng2-charts-x';
import { QRCodeModule } from 'angularx-qrcode';
import { AngularEditorModule } from '@kolkov/angular-editor';


import { AppComponent } from './app.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppRouteModule } from './app-route/app-route.module';
import { LoginComponent } from './login/login.component';
import { PlanListComponent } from './plan-list/plan-list.component';
import { HomeComponent } from './home/home.component';
import { ViewPlanComponent } from './view-plan/view-plan.component';
import { AddPlanComponent } from './add-plan/add-plan.component';
import { PendingOfferComponent } from './pending-offer/pending-offer.component';
import { NewOfferComponent } from './new-offer/new-offer.component';
import { ManageContractComponent } from './manage-contract/manage-contract.component';
import { ContractTemplateListComponent } from './contract-template-list/contract-template-list.component';
import { OfferComponent } from './offer/offer.component';
import { AgreeFeeTableComponent } from './agree-fee-table/agree-fee-table.component';
import { ViewContractTemplateComponent } from './view-contract-template/view-contract-template.component';
import { ViewContractComponent } from './view-contract/view-contract.component';
import { EditPlanComponent } from './edit-plan/edit-plan.component';
import { SingleMapComponent } from './single-map/single-map.component';
import { CounterOfferComponent } from './counter-offer/counter-offer.component';
import { AddTemplateComponent } from './add-template/add-template.component';
import { TemplateFeeTableComponent } from './template-fee-table/template-fee-table.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { HttpInterceptService } from './services/http-intercept.service';
import { SortArrayWithServiceBySpecialtyPipe } from './pipes/sort-array-with-service-by-specialty.pipe';
import { FilterDoctorPipe } from './pipes/filter-doctor.pipe';
import { SortServiceItemPipe } from './pipes/sort-service-item.pipe';
import { FilterPlanPipe } from './pipes/filter-plan.pipe';
import { FilterContractPipe } from './pipes/filter-contract.pipe';
import { FilterInsideDoctorPipe } from './pipes/filter-inside-doctor.pipe';
import { SortDoctorNamePipe } from './pipes/sort-doctor-name.pipe';
import { FilterSpecialtyPipe } from './pipes/filter-specialty.pipe';
import { FilterPracticeYearPipe } from './pipes/filter-practice-year.pipe';
import { PreapprovalComponent } from './preapproval/preapproval.component';
import { ManagePreapprovalComponent } from './manage-preapproval/manage-preapproval.component';
import { ApprovalDetailComponent } from './approval-detail/approval-detail.component';
import { DefaultContractComponent } from './default-contract/default-contract.component';
import { AddDefaultContractComponent } from './add-default-contract/add-default-contract.component';
import { EditDefaultComponent } from './edit-default/edit-default.component';
import { ViewDefaultComponent } from './view-default/view-default.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OfferListComponent } from './offer-list/offer-list.component';
import { PlanComponent } from './component/plan/plan.component';
import { ContractComponent } from './component/contract/contract.component';
import { DefaultPlanComponent } from './default-plan/default-plan.component';
import { AddDefaultPlanComponent } from './add-default-plan/add-default-plan.component';
import { PlanTableComponent } from './component/plan-table/plan-table.component';
import { ContractTableComponent } from './component/contract-table/contract-table.component';
import { UserManageComponent } from './user-manage/user-manage.component';
import { ManageDoctorComponent } from './manage-doctor/manage-doctor.component';
import { ManagePayorComponent } from './manage-payor/manage-payor.component';
import { DatePipe } from '@angular/common';
import { MemberEnrollmentComponent } from './member-enrollment/member-enrollment.component';
import { AddPayorComponent } from './add-payor/add-payor.component';
import { AddDoctorComponent } from './add-doctor/add-doctor.component';
import { AddMemberComponent } from './add-member/add-member.component';
import { ServicesListComponent } from './services-list/services-list.component';
import { FilterBySpecialtyidPipe } from './pipes/filter-by-specialtyid.pipe';
import { FilterServiceCategoryPipe } from './pipes/filter-service-category.pipe';
import { PasswordComponent } from './password/password.component';
import { SortDoctorPipe } from './pipes/sort-doctor.pipe';
import { FilterDoctorNamePipe } from './pipes/filter-doctor-name.pipe';
import { FilterClinicNamePipe } from './pipes/filter-clinic-name.pipe';
import { ImportPlansComponent } from './import-plans/import-plans.component';
import { ViewPayorComponent } from './view-payor/view-payor.component';
import { ClinicComponent } from './clinic/clinic.component';
import { DoctorClinicComponent } from './doctor-clinic/doctor-clinic.component';
import { DistrictComponent } from './district/district.component';
import { SpecialtyComponent } from './specialty/specialty.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { FilterCopayTypePipe } from './pipes/filter-copay-type.pipe';
import { AddClinicComponent } from './add-clinic/add-clinic.component';
import { EditDoctorClinicComponent } from './edit-doctor-clinic/edit-doctor-clinic.component';
import { SearchMapComponent } from './component/search-map/search-map.component';
import { MultimarkerMapComponent } from './component/multimarker-map/multimarker-map.component';
import { ManagePreapprovalTemplateComponent } from './manage-preapproval-template/manage-preapproval-template.component';
import { EditPreapprovalTemplateComponent } from './edit-preapproval-template/edit-preapproval-template.component';
import { DynamicFormComponent } from './component/dynamic-form/dynamic-form.component';
import { EdiComponent } from './edi/edi.component';
import { SubmittedPreapprovalComponent } from './submitted-preapproval/submitted-preapproval.component';
import { ApprovedPreapprovalComponent } from './approved-preapproval/approved-preapproval.component';
import { RejectedPreapprovalComponent } from './rejected-preapproval/rejected-preapproval.component';
import { UnauthComponent } from './unauth/unauth.component';
import { PreapprovalFieldMappingComponent } from './preapproval-field-mapping/preapproval-field-mapping.component';
import { InstructionComponent } from './instruction/instruction.component';
import { PreapprovalFormComponent } from './preapproval-form/preapproval-form.component';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { AdminComponent } from './admin/admin.component';
import { MiscellaneousComponent } from './miscellaneous/miscellaneous.component';
import { InvoiceTransactionComponent } from './invoice-transaction/invoice-transaction.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { NetworkGroupComponent } from './network-group/network-group.component';
import { UpdateInfoComponent } from './component/update-info/update-info.component';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppMenuComponent,
    AppFooterComponent,
    LoginComponent,
    PlanListComponent,
    HomeComponent,
    ViewPlanComponent,
    AddPlanComponent,
    PendingOfferComponent,
    NewOfferComponent,
    ManageContractComponent,
    ContractTemplateListComponent,
    OfferComponent,
    AgreeFeeTableComponent,
    ViewContractTemplateComponent,
    ViewContractComponent,
    EditPlanComponent,
    SingleMapComponent,
    CounterOfferComponent,
    AddTemplateComponent,
    TemplateFeeTableComponent,
    EditTemplateComponent,
    SortArrayWithServiceBySpecialtyPipe,
    FilterDoctorPipe,
    SortServiceItemPipe,
    FilterPlanPipe,
    FilterContractPipe,
    FilterInsideDoctorPipe,
    SortDoctorNamePipe,
    FilterSpecialtyPipe,
    FilterPracticeYearPipe,
    PreapprovalComponent,
    ManagePreapprovalComponent,
    ApprovalDetailComponent,
    DefaultContractComponent,
    AddDefaultContractComponent,
    EditDefaultComponent,
    ViewDefaultComponent,
    DashboardComponent,
    OfferListComponent,
    PlanComponent,
    ContractComponent,
    DefaultPlanComponent,
    AddDefaultPlanComponent,
    PlanTableComponent,
    ContractTableComponent,
    UserManageComponent,
    ManageDoctorComponent,
    ManagePayorComponent,
    MemberEnrollmentComponent,
    AddPayorComponent,
    AddDoctorComponent,
    AddMemberComponent,
    ServicesListComponent,
    FilterBySpecialtyidPipe,
    FilterServiceCategoryPipe,
    PasswordComponent,
    SortDoctorPipe,
    FilterDoctorNamePipe,
    FilterClinicNamePipe,
    ImportPlansComponent,
    FilterServiceTypePipe,
    ViewPayorComponent,
    InputFileComponent,
    ClinicComponent,
    DoctorClinicComponent,
    DistrictComponent,
    SpecialtyComponent,
    DiagnosisComponent,
    FilterCopayTypePipe,
    AddClinicComponent,
    EditDoctorClinicComponent,
    SearchMapComponent,
    MultimarkerMapComponent,
    ManagePreapprovalTemplateComponent,
    EditPreapprovalTemplateComponent,
    DynamicFormComponent,
    MultimarkerMapComponent,
    EdiComponent,
    SubmittedPreapprovalComponent,
    ApprovedPreapprovalComponent,
    RejectedPreapprovalComponent,
    UnauthComponent,
    PreapprovalFieldMappingComponent,
    InstructionComponent,
    PreapprovalFormComponent,
    BreadcrumbComponent,
    AdminComponent,
    MiscellaneousComponent,
    InvoiceTransactionComponent,
    InvoiceDetailComponent,
    NetworkGroupComponent,
    UpdateInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRouteModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCar5m3m17V7OJk0VFYyRdBESEIvwjkpkM',
      libraries: ['places']
    }),
    FormsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot( {
      timeOut: 7000
    }), // ToastrModule added
    NgSelect2Module,
    TagInputModule,
    ChartsModule,
    QRCodeModule,
    AngularEditorModule
  ],
  providers: [
    ApiService, 
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptService, multi: true },
    SortArrayWithServiceBySpecialtyPipe,
    SortServiceItemPipe,
    FilterSpecialtyPipe,
    DatePipe,
    FilterServiceCategoryPipe,
    FilterDoctorNamePipe,
    FilterClinicNamePipe,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
}