import { ImportPlansComponent } from './../import-plans/import-plans.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from '../login/login.component';
import { HomeComponent } from '../home/home.component';
import { PlanListComponent } from '../plan-list/plan-list.component';
import { ViewPlanComponent } from '../view-plan/view-plan.component';
import { AddPlanComponent } from '../add-plan/add-plan.component';
import { NewOfferComponent } from '../new-offer/new-offer.component';
import { ManageContractComponent } from '../manage-contract/manage-contract.component';
import { ViewContractTemplateComponent } from '../view-contract-template/view-contract-template.component';
import { ViewContractComponent } from '../view-contract/view-contract.component';
// import { EditPlanComponent } from '../edit-plan/edit-plan.component';
import { CounterOfferComponent } from '../counter-offer/counter-offer.component';
import { AddTemplateComponent } from '../add-template/add-template.component';
// import { EditTemplateComponent } from '../edit-template/edit-template.component';
import { PreapprovalComponent } from '../preapproval/preapproval.component';
import { ManagePreapprovalComponent } from '../manage-preapproval/manage-preapproval.component';
import { ApprovalDetailComponent } from '../approval-detail/approval-detail.component';
// import { DefaultContractComponent } from '../default-contract/default-contract.component';
// import { AddDefaultContractComponent } from '../add-default-contract/add-default-contract.component';
// import { EditDefaultComponent } from '../edit-default/edit-default.component';
// import { ViewDefaultComponent } from '../view-default/view-default.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { OfferListComponent } from '../offer-list/offer-list.component';
import { DefaultPlanComponent } from '../default-plan/default-plan.component';
import { AddDefaultPlanComponent } from '../add-default-plan/add-default-plan.component';
import { ROLE } from '../models/User';
import { AuthGuardService } from '../services/auth-guard.service';
// import { UserManageComponent } from '../user-manage/user-manage.component';
import { ManageDoctorComponent } from '../manage-doctor/manage-doctor.component';
import { ManagePayorComponent } from '../manage-payor/manage-payor.component';
import { MemberEnrollmentComponent } from '../member-enrollment/member-enrollment.component';
import { AddPayorComponent } from '../add-payor/add-payor.component';
import { AddDoctorComponent } from '../add-doctor/add-doctor.component';
import { AddMemberComponent } from '../add-member/add-member.component';
import { ServicesListComponent } from '../services-list/services-list.component';
import { PendingOfferComponent } from '../pending-offer/pending-offer.component';
import { OfferComponent } from '../offer/offer.component';
import { PasswordComponent } from '../password/password.component';
import { ViewPayorComponent } from '../view-payor/view-payor.component';
import { ClinicComponent } from '../clinic/clinic.component';
import { DoctorClinicComponent } from '../doctor-clinic/doctor-clinic.component';
import { DistrictComponent } from '../district/district.component';
import { SpecialtyComponent } from '../specialty/specialty.component';
import { DiagnosisComponent } from '../diagnosis/diagnosis.component';
import { AddClinicComponent } from '../add-clinic/add-clinic.component';
import { EditDoctorClinicComponent } from '../edit-doctor-clinic/edit-doctor-clinic.component';
import { ManagePreapprovalTemplateComponent } from '../manage-preapproval-template/manage-preapproval-template.component';
import { EditPreapprovalTemplateComponent } from '../edit-preapproval-template/edit-preapproval-template.component';
import { EdiComponent } from '../edi/edi.component';
// import { ApprovedPreapprovalComponent } from '../approved-preapproval/approved-preapproval.component';
// import { SubmittedPreapprovalComponent } from '../submitted-preapproval/submitted-preapproval.component';
// import { RejectedPreapprovalComponent } from '../rejected-preapproval/rejected-preapproval.component';
import { UnauthComponent } from '../unauth/unauth.component';
import { PreapprovalFieldMappingComponent } from '../preapproval-field-mapping/preapproval-field-mapping.component';
import { InstructionComponent } from '../instruction/instruction.component';
import { AdminComponent } from '../admin/admin.component';
import { MiscellaneousComponent } from '../miscellaneous/miscellaneous.component';
import { InvoiceTransactionComponent } from '../invoice-transaction/invoice-transaction.component';
import { InvoiceDetailComponent } from '../invoice-detail/invoice-detail.component';
import { NetworkGroupComponent } from '../network-group/network-group.component';


const routes: Routes = [
  { 
    path: 'login', 
    component: LoginComponent,
  },
  { 
    path: 'preapproval', 
    component: PreapprovalComponent,
  },
  { path: 'instruction', 
    component: InstructionComponent,  
  },
  { path: '', 
    component: HomeComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: 'sidemenu.home' },
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: DashboardComponent },
      { path: '403', component: UnauthComponent },
      { path: 'plan',
        data: { roles: [ROLE.admin, ROLE.operator, ROLE.payor], breadcrumb: 'plan_list.plans' },
        children: [
          { path: '', component: PlanListComponent},
          { path: 'view/:id', component: ViewPlanComponent, data: { breadcrumb: 'View Plan' } },
          { path: 'add', component: AddPlanComponent, data: { breadcrumb: 'plan_list.add_new_plan' } },
          { path: 'import', component: ImportPlansComponent, data: { breadcrumb: 'Import Plan' } },
          { path: 'edit/:id', component: AddPlanComponent, data: { breadcrumb: 'Edit Plan' } },
        ] 
      },
      { path: 'contract',
        data: { roles: [ROLE.admin, ROLE.operator, ROLE.payor], breadcrumb: 'sidemenu.manage_contract' },
        children: [
          { path: '', component: ManageContractComponent },
          { path: 'view-template/:id', component: ViewContractTemplateComponent, data: { breadcrumb: 'view_plan.view_contract_template' } },
          { path: 'add-template', component: AddTemplateComponent, data: { breadcrumb: 'contract_template.add_contract_template' } },
          { path: 'add-template/:id', component: AddTemplateComponent, data: { breadcrumb: 'contract_template.add_contract_template' } },
          // { path: 'edit-template/:id', component: EditTemplateComponent, data: { breadcrumb: 'Edit Contract' } },
        ]
      },
      { 
        path: 'offer', 
        data: { breadcrumb: 'Offer' },
        children: [
          { path: '', component: OfferListComponent, children: 
            [
              { path: '', redirectTo: 'pending', pathMatch: 'full' },
              { path: 'pending', component: PendingOfferComponent, data: { breadcrumb: 'offers.pending_offers' }  },
              { path: 'signed', component: OfferComponent, data: { breadcrumb: 'offers.signed_offers' }  },
              { path: 'terminated', component: OfferComponent, data: { breadcrumb: 'offers.terminated_offers' }  },
              { path: 'declined', component: OfferComponent, data: { breadcrumb: 'offers.declined_offers' }  },
            ] 
          },
          { path: 'view/:id', component: ViewContractComponent, data: { breadcrumb: 'plan_list.view_contract' }   },
          { path: 'new', component: NewOfferComponent, data: { roles: ROLE.payor, breadcrumb: 'offers.new_offer'} },
          { path: 'counter/:id', component: CounterOfferComponent, data: { breadcrumb: 'counter_offer.counter_offer' } }
        ]
      },
      {
        path: 'preapproval-list',
        data: { breadcrumb: 'sidemenu.preapproval' },
        children: [
          { 
            path: '', 
            component: ManagePreapprovalComponent,
            // children: [
            //   { path: '', redirectTo: 'submitted', pathMatch: 'full' },
            //   { path: 'submitted', component: SubmittedPreapprovalComponent, data: { breadcrumb: 'preapproval.submitted_list' } },
            //   { path: 'approved', component: ApprovedPreapprovalComponent, data: { breadcrumb: 'preapproval.approved_list' } },
            //   { path: 'rejected', component: RejectedPreapprovalComponent, data: { breadcrumb: 'preapproval.rejected_list' } },
            // ]
          },
          { path: ':pa_token', component: ApprovalDetailComponent, data: { breadcrumb: 'preapproval_detail' } }
        ]
      },
      { path: 'preapproval-mapping', component: PreapprovalFieldMappingComponent, data: { roles: ROLE.admin, breadcrumb: 'preapproval.form_mapping' }  },
      { 
        path: 'preapproval-template', 
        data: { breadcrumb: 'sidemenu.preapproval_template' } ,
        children: [
          { path: '', component: ManagePreapprovalTemplateComponent, data: { roles: ROLE.admin, breadcrumb: 'preapproval.manage_preapproval_template' } },
          { path: 'add', component: EditPreapprovalTemplateComponent, data: { roles: [ROLE.admin], breadcrumb: 'preapproval.new_preapproval_template' } },
          { path: 'edit/:id', component: EditPreapprovalTemplateComponent, data: { roles: [ROLE.admin], breadcrumb: 'preapproval.edit_preapproval_template' } },
        ]
      },
      { 
        path: 'member', 
        data: { roles: [ROLE.admin, ROLE.operator, ROLE.payor], breadcrumb: 'sidemenu.member_enrollment'  } ,
        children: [
          { path: '', component: MemberEnrollmentComponent },
          { path: 'add', component: AddMemberComponent, data: { breadcrumb: 'member_enrollment.new_member' } },
        ]
      },
      { path: 'password', component: PasswordComponent,  data: { breadcrumb: 'change_password' } },
      // { path: 'default-contract', component: DefaultContractComponent, data: { roles: ROLE.admin } },
      // { path: 'default-contract/add', component: AddDefaultContractComponent, data: { roles: ROLE.admin } },
      // { path: 'default-contract/edit', component: EditDefaultComponent, data: { roles: ROLE.admin } },
      // { path: 'default-contract/view/:id', component: ViewContractTemplateComponent, data: { roles: ROLE.admin }  },
      // { path: 'default-plan', component: DefaultPlanComponent, data: { roles: ROLE.admin }  },
      // { path: 'default-plan/add', component: AddDefaultPlanComponent, data: { roles: ROLE.admin }  },
      // { path: 'user', component: UserManageComponent,
      // data: { roles: ROLE.admin }  },
      { path: 'user',
        data: { roles: [ROLE.admin, ROLE.operator] },
        children: [
          { 
            path: 'doctor',
            data: { roles: [ROLE.admin, ROLE.operator, ROLE.softlink], breadcrumb: 'sidemenu.doctor' },
            children: [
              { path: '', component: ManageDoctorComponent  },
              { path: 'add', component: AddDoctorComponent, data: { breadcrumb: 'doctor.add_doctor' } },
              { path: 'edit/:id', component: AddDoctorComponent, data: { breadcrumb: 'doctor.edit_doctor' }  },
            ]
          },
          {
            path: 'payor',
            data: { breadcrumb: 'payor' },
            children: [
              { path: '', component: ManagePayorComponent },
              { path: 'add', component: AddPayorComponent, data: { breadcrumb: 'payors.add_payor' } },
              { path: 'edit/:id', component: AddPayorComponent, data: { breadcrumb: 'payors.edit_payor' }  },
              { path: 'view/:id', component: ViewPayorComponent, data: { roles: [ROLE.admin, ROLE.operator, ROLE.payor],breadcrumb: 'payors.view_payor' }  }
            ]
          }
        ]
      },
      { path: 'services', component: ServicesListComponent, data: { roles: [ROLE.admin, ROLE.operator], breadcrumb: 'sidemenu.service_items' }  },
      { path: 'clinic', 
        data: { roles: [ROLE.admin, ROLE.operator, ROLE.softlink], breadcrumb: 'sidemenu.clinic' },
        children: [
          { path: '', component: ClinicComponent  },
          { path: 'add', component: AddClinicComponent, data: { breadcrumb: 'clinic.add_clinic' }   },
          { path: 'edit/:id', component: AddClinicComponent, data: { breadcrumb: 'clinic.edit_clinic' }  },
        ]
      },
      { 
        path: 'doctor-clinic', 
        data: { roles: [ROLE.admin, ROLE.operator, ROLE.softlink], breadcrumb: 'sidemenu.doctor_clinic' },
        children: [
          { path: '', component: DoctorClinicComponent },
          { path: 'add', component: EditDoctorClinicComponent, data: { breadcrumb: 'doctor_clinic.new_doctor_clinic'  }  },
          { path: 'edit/:id', component: EditDoctorClinicComponent, data: { breadcrumb: 'doctor_clinic.edit_doctor_clinic'  }  },
        ]
      },
      { path: 'district', component: DistrictComponent, data: { roles: [ROLE.admin, ROLE.operator], breadcrumb: 'sidemenu.district' }  },
      { path: 'specialty', component: SpecialtyComponent, data: { roles: [ROLE.admin, ROLE.operator], breadcrumb: 'specialty' }  },
      { path: 'diagnosis', component: DiagnosisComponent, data: { roles: [ROLE.admin, ROLE.operator], breadcrumb: 'diagnosis' }  },
      { path: 'edi', component: EdiComponent, data: { roles: [ROLE.admin, ROLE.operator], breadcrumb: 'sidemenu.edi' }  },
      { 
        path: 'admin', 
        data: { roles: ROLE.admin, breadcrumb: 'sidemenu.admin' },
        children: [
          { path: '', component: AdminComponent }
        ]
      },
      { 
        path: 'miscellaneous', 
        data: { roles: ROLE.admin, breadcrumb: 'sidemenu.miscellaneous' },
		    component: MiscellaneousComponent,
        children: [
          { path: '', component: AdminComponent }
        ]
      }, 
      {
        path: 'invoice-transaction',
        data: { roles: [ROLE.admin, ROLE.finance, ROLE.operator], breadcrumb: 'sidemenu.invoice' },
        children: [
          { path: '', component: InvoiceTransactionComponent },
          { path: ':id', component: InvoiceDetailComponent, data: { breadcrumb: 'sidemenu.invoice' } }
        ]
      },
      { 
        path: 'network-group', 
        data: { roles: ROLE.admin, breadcrumb: 'sidemenu.network_group' },
		    component: NetworkGroupComponent,
        // children: [
        //   { path: '', component: AdminComponent }
        // ]
      }
    ]
  },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ]
})
export class AppRouteModule { }
