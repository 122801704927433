export interface OpeningHours {
    weekday: number,
    session: string,
    by_appointment: boolean,
    start?: string,
    end?: string,
}

export const WEEKDAY = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Public Holiday'
]