import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { User, ROLE } from '../models/User';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  user: User;
  role = ROLE;

  constructor(private storage: LocalStorageService, private router: Router) { }

  async ngOnInit() {
    this.user = await this.storage.getUserProfile();
    console.log(this.user)
  }

  logout() {
    this.storage.setLogout();
    this.router.navigate(['/login']);
  }

}
