import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpEvent } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { from, throwError, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptService implements HttpInterceptor {
  constructor(private storage: LocalStorageService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return from(this.handleAccess(req, next));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler) {
    const token = await this.storage.getVisitToken();
    let authReq = request.clone();
    if(token){
      authReq = request.clone({ setHeaders: { 'Authorization': 'Bearer ' + token } });
    }
      return next.handle(authReq)
            .pipe(map((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                console.log(event);
              }
              return event;
            }), catchError( err => {
              if (err.status == 401 && request.url.indexOf('register_device') < 0 && request.url.indexOf('auth') < 0 && request.url.indexOf('checkStatus') < 0 ) {
                  this.router.navigateByUrl('/login');
                  return EMPTY;
              }
              if (err.status == 403) {
                this.router.navigateByUrl('/403');
                return EMPTY;
              } else {
                  return throwError(err);
              }
            }))
            .toPromise();
    }
}
