import { Component, OnInit, ViewChild } from '@angular/core';
import { PER_PAGE } from '../constant/config';
import { ApiService } from '../services/api-service';
import { Select2OptionData } from 'ng-select2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-preapproval-field-mapping',
  templateUrl: './preapproval-field-mapping.component.html',
  styleUrls: ['./preapproval-field-mapping.component.scss']
})

export class PreapprovalFieldMappingComponent implements OnInit {
  addSuccess: boolean;
  deleteId: number = null;
  errorMsg: string;
  fieldList: any[] = [];
  fieldOptions: any[] = [];
  loading: boolean;
  modalLoading: boolean;
  modalErrorMsg: string;
  newField = {
    insurerId: null,
    fieldName: null
  };
  payorId: string = null;
  payorOptions: Select2OptionData[] = [];
  preapprovalFields: any[] = [];
  sectionOptions: Select2OptionData[] = [];
  submitSuccess: boolean;
  validate: boolean = true;

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings;

  constructor(private apiService: ApiService) { 
    this.fetchPayor();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    await this.fetchPreapprovalFields();
  }
  
  async addField() {
    try {
      this.reset();
      this.validate = false;
      this.modalLoading = true;
      if (!this.newField.insurerId || !this.newField.fieldName) return;
      await this.apiService.addPreapprovalFieldsMapping(this.newField.insurerId, this.newField.fieldName);
      this.addSuccess = true;
      this.validate = true;
      this.refreshList();
    } catch (err) {
      console.log(err);
      this.modalErrorMsg = err.error.error_messages;
    } finally {
      this.modalLoading = false;
    }
  }

  async deleteField() {
    this.reset();
    try {
      this.loading = true;
      await this.apiService.deletePreapprovalFieldsMapping(this.deleteId);
      this.refreshList();
    } catch (err) {
      console.log(err);
      this.errorMsg = err.error.error_messages;
    } finally {
      this.loading = false;
    }
  }

  async fetchPayor() {
    try {
      let payors = await this.apiService.getPayors();
      this.payorOptions = payors.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
    } catch (err) {
      console.log(err);
    }
  }

  async fetchPreapprovalFields() {
    this.loading = true;
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          if(this.fieldList.length === 0)
            await this.fetchSections();
          let res = await this.apiService.getPreapprovalFieldsMapping(this.payorId, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
          this.preapprovalFields = res['data'];
          for (let i = 0; i < this.preapprovalFields.length; i++) {
            this.getSectionFields(this.preapprovalFields[i].section, i);
          }
          this.loading = false;

          callback({
            recordsTotal: res['pagination'].total,
            recordsFiltered: res['pagination'].total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }
  
  async fetchSections() {
    try {
      this.fieldList = <any[]> await this.apiService.getPreapprovalFields();
      this.sectionOptions = Object.keys(this.fieldList).map(key => {
        return {
          id: key,
          text: key
        };
      });
    } catch (err) {
      console.log(err);
    }
  }

  async refreshList() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  async save(i?: number) {
    this.reset();
    let fields = [];
    if (i === undefined)
      fields = this.preapprovalFields;
    else
      fields.push(this.preapprovalFields[i]);
    try {
      this.loading = true;
      await this.apiService.updatePreapprovalFieldsMapping({fields: fields});
      this.submitSuccess = true;
      this.refreshList();
    } catch (err) {
      console.log(err);
      this.errorMsg = err.error.error_messages;
    } finally {
      this.loading = false;
    }
  }

  getSectionFields(section, i) {
    if (section) {
      let fields = this.fieldList[section];
      this.fieldOptions[i] = <Select2OptionData[]> fields.map(item => {
        return {
          id: item.id,
          text: item.field_alias
        };
      });
    } else {
      this.fieldOptions[i] = null;
    }
  }

  reset() {
    this.errorMsg = "";
    this.submitSuccess = false;
    this.modalErrorMsg = "";
    this.addSuccess = false;
  }
  
  resetModal() {
    this.reset();
    this.validate = true;
    this.newField = {
      insurerId: null,
      fieldName: null
    };
  }
  
  setDeleteField(id) {
    this.deleteId = id;
  }
}
