import { Component, OnInit, ViewChild } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { ROLE, STATUS } from '../models/User';
import { ApiService } from '../services/api-service';
import { DataTableDirective } from 'angular-datatables';
import { Subject, throwError } from 'rxjs';
import { PER_PAGE } from '../constant/config';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../services/local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  roleOptions: Select2OptionData[] = [];
  role = ROLE;
  status = STATUS;

  newAdmin: {
    role: ROLE,
    name: string,
    email: string,
    password: string,
    confirm_pw: string
  } = {
    role: null,
    name: "",
    email: "",
    password: "",
    confirm_pw: ""
  }
  memberList = [];
  submitSuccess;
  editSubmitSuccess;
  errorMsg;
  submitBtn = false;
  loading = false;
  submitLoading = false;
  searchRole;
  editErrorMsg;
  adminDetail;
  translateText;

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(private apiService: ApiService, private translate: TranslateService, private storage: LocalStorageService, private router: Router) { 
    translate.get([
      'admin.password_mismatch'
    ]).subscribe((text:string[]) => {
      this.translateText = text;
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let response = await this.apiService.getHealsUser(true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1, this.searchRole);
          this.memberList = response.data;
          this.loading = false;

          callback({
            recordsTotal: response.pagination.total,
            recordsFiltered: response.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      
    }

    for(let item in this.role){
      if(item !== this.role.doctor && item !== this.role.payor)
      this.roleOptions.push({
        id: item,
        text: item.charAt(0).toUpperCase() + item.slice(1)
      });
    }
  }

  async addUser() {
    this.submitSuccess = false;
    this.errorMsg = null;
    this.submitBtn = true;
    if(this.newAdmin.password === this.newAdmin.confirm_pw && this.newAdmin.email && this.newAdmin.password && this.newAdmin.role && this.newAdmin.name && this.newAdmin.confirm_pw) {
      try {
        this.submitLoading = true;
        await this.apiService.addUpdateHealsUser(this.newAdmin);
        this.submitSuccess = true;

        this.newAdmin = {
          role: null,
          name: "",
          email: "",
          password: "",
          confirm_pw: ""
        };

        await this.filterMember();
        this.submitBtn = false;
      } catch(err) {
        console.log(err)
        this.errorMsg = err.error.error_messages;
      } finally {
        this.submitLoading = false;
      }
    } else if(this.newAdmin.password === this.newAdmin.confirm_pw)
      this.errorMsg = [this.translateText['admin.password_mismatch']];
  }

  async filterMember() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  openViewModal(member) {
    this.editSubmitSuccess = false;
    this.editErrorMsg = null;
    this.adminDetail = member;
  }

  async updateUser() {
    this.submitSuccess = false;
    this.errorMsg = null;
    try {
      this.submitLoading = true;
      if(this.adminDetail.password && this.adminDetail.password !== this.adminDetail.confirm_pw) {
        throw 'PASSWORD_MISMATCH';
      }
      let update = {
        id:this.adminDetail.id,
        name:this.adminDetail.name,
        role:this.adminDetail.role,
        password: this.adminDetail.password
      }
      await this.apiService.addUpdateHealsUser(update);
      this.editSubmitSuccess = true;

      let user = await this.storage.getUserProfile();
      if(this.adminDetail.password && this.adminDetail.id === user.id) {
        $('#modal-edit').modal("hide");
        this.storage.setLogout();
        this.router.navigate(['/login']);
      }
    } catch(err) {
      console.log(err)
      if(err === 'PASSWORD_MISMATCH')
        this.editErrorMsg = [this.translateText['admin.password_mismatch']]
      else
        this.editErrorMsg = err.error.error_msg;
    } finally {
      this.submitLoading = false;
    }
  }

  async updateStatus(user) {
    try {
      if(user.status === STATUS.active) {
        await this.apiService.updateHealsUserStatus(user.id, STATUS.inactive);
        user.status = STATUS.inactive;
      } else {
        await this.apiService.updateHealsUserStatus(user.id, STATUS.active);
        user.status = STATUS.active;
      }
    } catch(err) {
      console.log(err)
    }
  }

}
