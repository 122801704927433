import { Select2OptionData } from 'ng-select2';
import { ROLE } from './../../models/User';
import { User } from 'src/app/models/User';
import { LocalStorageService } from './../../services/local-storage.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../services/api-service';
import { SortServiceItemPipe } from '../../pipes/sort-service-item.pipe';
import { Plan, PLAN_TYPE } from 'src/app/models/Plan';
import { Specialty } from 'src/app/models/Specialty';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {
  @Input('contract') contract = {
    name: '',
    plan_id: null,
    services: [],
  };

  warningDisplay: boolean = false;

  planList: Plan[] = [];
  planOptions: Select2OptionData[] = [];
  planDetail: Plan = <Plan>{};
  specialtyList: Specialty[] = [];

  @Output() submitContract = new EventEmitter();

  submitBtn = false;

  user: User;
  loading = false;
  errorMsg;

  constructor(private apiService: ApiService, private sortName: SortServiceItemPipe, private storage: LocalStorageService) { }

  async ngOnInit() {
    this.user = await this.storage.getUserProfile();
    this.fetchPlan();
    let planId = (new URL(window.location.href)).searchParams.get('plan_id');
    if (planId) {
      this.contract.plan_id = planId;
      this.getPlanDetail();
    }
  }

  async fetchPlan() {
    if (this.user.role === ROLE.admin || this.user.role === ROLE.operator)
      this.planList = <Plan[]>(await this.apiService.fetchALLPlan());
    else
      this.planList = <Plan[]>(await this.apiService.fetchPlan());

    this.planOptions = this.planList.map(item => {
      return {
        id: item.id.toString(),
        text: item.name
      };
    });
  }

  select2ValueChanged(event) {
    this.contract.plan_id = event['value'];
    this.getPlanDetail();
  }

  async getPlanDetail() {
    try {
      this.planDetail = await this.apiService.getPlanDetail(this.contract.plan_id);
      this.specialtyList = [];
      this.planDetail.services.forEach(item => {
        this.specialtyList.push(item.specialty)
      });
      this.specialtyList = this.removeDuplicates(this.specialtyList, 'id');
      this.specialtyList = this.sortName.transform(this.specialtyList);
    } catch (err) {
      console.log(err);
    }
  }

  removeDuplicates(myArr: any[], prop: string): any[] {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  async submit(item) {
    this.submitBtn = true;
    this.warningDisplay = (this.contract.plan_id === null);

    if (this.contract.plan_id && this.contract.name && this.contract.services.length > 0) {
      let contract = JSON.parse(JSON.stringify(this.contract));
      contract.services = item;
      contract.type = PLAN_TYPE.std;
      contract.creator_id = this.user.id;
      this.loading = true;
      try {
        let res = await this.apiService.addContractTemplate(contract);
        this.submitContract.emit(res);
      } catch (err) {
        console.log(err)
        this.errorMsg = err.error.error_messages;
      } finally {
        this.loading = false;
      }
    }
  }

}
