import { Doctor } from './../models/Doctor';
import { FetchDoctorsResponse } from './../response/FetchDoctorsResponse';
import { ContractTemplate } from './../models/ContractTemplate';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import {NgbDateAdapter, NgbDateNativeAdapter, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SortServiceItemPipe } from '../pipes/sort-service-item.pipe';
import { FilterSpecialtyPipe } from '../pipes/filter-specialty.pipe';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { PER_PAGE } from '../constant/config';
import { Plan } from '../models/Plan';
import { Specialty } from '../models/Specialty';
import { Select2OptionData } from 'ng-select2';
import { User, ROLE } from '../models/User';
import { LocalStorageService } from '../services/local-storage.service';

@Component({
  selector: 'app-new-offer',
  templateUrl: './new-offer.component.html',
  styleUrls: ['./new-offer.component.scss'],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class NewOfferComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  // doctors = [];
  showDoctors: Doctor[] = [];
  plans: Plan[] = [];
  planOptions: Select2OptionData[] = [];
  templates: ContractTemplate[] = [];
  templateOptions: Select2OptionData[] = [];
  doctor: Doctor;
  selectedplan = null;
  selectedTemplate = null;
  searchDoctorName: string;
  selectedSpecialty: any = "-1";
  specialtyList: Specialty[] = [];
  specialtyOptions: Select2OptionData[] = [];
  effective_date: Date;
  // clinicList = [];
  // selectedYear = [];
  submitBtn: boolean = false;
  fetchItemArr: boolean[] = [];
  today: {};
  loadingDoctor: boolean = false;
  loadingDoctorDetail: boolean = false;
  selectedDoctor: Doctor[] = [];
  user: User;

  dtOptions: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject();

  constructor(private apiService: ApiService, private calendar: NgbCalendar, private router: Router, private sortName: SortServiceItemPipe, private filterSpecialty: FilterSpecialtyPipe, private datePipe: DatePipe, private storage: LocalStorageService) { 
    let today = new Date;
    this.today = {
      year: today.getFullYear(),
      month: (today.getMonth()) + 1,
      day: today.getDate()
    }
    let planId = (new URL(window.location.href)).searchParams.get('plan_id');
    if (planId)
      this.selectedplan = planId;
    let contractId = (new URL(window.location.href)).searchParams.get('template_id');
    if (contractId)
      this.selectedTemplate = contractId;
   }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          let doctors: FetchDoctorsResponse;
          // if (this.selectedSpecialty != '-1') {
            if (this.searchDoctorName)
              doctors = <FetchDoctorsResponse>await this.apiService.adminGetDoctorList(null, this.selectedSpecialty['id'],this.searchDoctorName, true, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
            else
              doctors = <FetchDoctorsResponse>await this.apiService.adminGetDoctorList(null, this.selectedSpecialty['id'], null, true, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1);
            
            this.showDoctors = doctors.data;
            // doctors.data.forEach(item => {
            //   this.doctors.push(item);
            // });
            // this.showDoctors = this.filterSpecialty.transform(this.doctors, this.selectedSpecialty.name);

            callback({
              recordsTotal: doctors.pagination.total,
              recordsFiltered: doctors.pagination.total,
              data: []
            });
          // } else {
          //   callback({
          //     recordsTotal: 0,
          //     recordsFiltered: 0,
          //     data: []
          //   });
          // }
        }
      };
    } catch (err) {
      console.error(err);
      this.showDoctors = [];
    }
    this.selectedSpecialty = '-1'
    await this.fetchPlanList();
    await this.fetchContractTemplate();
    // await this.fetchDoctorList();
    // await this.getCLinic();
    await this.fetchSpecialtyList();
  }
  async fetchSpecialtyList() {
    try {
      this.specialtyList = <Specialty[]>await this.apiService.getSpecialty(false);
      this.specialtyList = this.sortName.transform(this.specialtyList);
      this.specialtyOptions = this.specialtyList.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
      if (this.specialtyList.length > 0)
        this.specialtyList.forEach(item => {
          this.fetchItemArr.push(false);
        });
    } catch (err) {
      console.error(err);
    }
  }

  async openDoctorModal(id) {
    try {
      this.loadingDoctorDetail = true;
      this.doctor = <Doctor>await this.apiService.adminGetDoctorList(id);
      if (this.doctor.doctor_clinic.length > 0) {
        this.doctor.doctor_clinic.forEach(clinic => {
          clinic['checked'] = true;
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingDoctorDetail = false;
    }
  }

  async fetchPlanList() {
    this.user = await this.storage.getUserProfile();
    if(this.user.role === ROLE.payor)
      this.plans = <Plan[]>await this.apiService.fetchPlan();
    else
      this.plans = <Plan[]>await this.apiService.fetchALLPlan();
    this.planOptions = this.plans.map(item => {
      return {
        id: item.id.toString(),
        text: item.name
      };
    });
  }

  async fetchContractTemplate() {
    if(this.user.role === ROLE.payor)
      this.templates = <ContractTemplate[]>await this.apiService.fetchContractTemplate();
    else
      this.templates = <ContractTemplate[]>await this.apiService.adminGetContractTemplate(false);
    if(this.selectedplan)
      this.getSelect2TemplateOptions();
  }

  filterTemplate(arr: ContractTemplate[], plan) {
    return arr.filter(item => item.plan_id == plan);
  }

  getSelect2TemplateOptions() {
    if(this.selectedplan) {
      let array = this.filterTemplate(this.templates, this.selectedplan);
      return array.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
    }
  }

  // templateSelect2ValueChanged(event) {
  //   this.selectedTemplate = event;
  // }

  resetTemplate() {
    this.selectedTemplate = null;
  }

  planSelect2ValueChanged(event) {
    console.log(this.selectedplan)
    // this.selectedplan = this.plans.find(plan => plan.id == event);
    this.resetTemplate();
  }
  
  removeDuplicates(myArr: any[], prop: string): any[] {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  async sendOffer() {
    let today = new Date(new Date().toDateString());
    this.submitBtn = true;
    if (!this.selectedTemplate || 
        !this.effective_date || this.effective_date < today) {

    } else {
      let offer = {
        contract_id: this.selectedTemplate,
        effective_date: this.datePipe.transform(this.effective_date, 'yyyy-MM-dd'),
        doctor_clinic_ids: []
      };
      this.selectedDoctor.forEach(doctor => {
        doctor.doctor_clinic.forEach(clinic => {
          if (clinic['checked'])
            offer.doctor_clinic_ids.push(clinic.id)
        });
      });
      try {
        await this.apiService.sendOffer(offer);
        this.router.navigate(['/offer']);
      } catch (err) {
        console.error
      }
    }
  }

  // async getCLinic() {
  //   this.clinicList = await this.apiService.getClinic();
  // }

  async filterChanged(event) {
    // try {
    //   this.loadingDoctor = true;
    //   if(this.selectedSpecialty['id'] > 0 && !this.fetchItemArr[this.selectedSpecialty['id']]) {
    //     await this.fetchDoctorList(this.selectedSpecialty['id']);
    //     this.fetchItemArr[this.selectedSpecialty['id']] = true;
    //   } else {
    //     this.showDoctors = this.filterSpecialty.transform(this.doctors, this.selectedSpecialty.name);
    //   }
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   this.loadingDoctor = false;
    // }
    this.selectedSpecialty = this.specialtyList.find(item => item.id == event['value']);
    this.searchDoctorName = "";
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  addDoctor() {
    let flag = false;
    this.doctor.doctor_clinic.forEach(clinic => {
      if (clinic['checked'])
        flag = true;
    });
    if(flag) {
      let index = this.selectedDoctor.findIndex(x => x.id === this.doctor.id);
      if (index < 0 )
        this.selectedDoctor.push(this.doctor);
      else
        this.selectedDoctor[index] = this.doctor;
    }
  }

  removeFee(i) {
    this.selectedDoctor.splice(i, 1);
  }

  async filterDoctorName() {
      let dtInstance = await this.dtElement.dtInstance;
      dtInstance.destroy();
      this.dtTrigger.next();
  }

  selectAllClinic() {
    if (this.doctor.doctor_clinic.length > 0) {
      this.doctor.doctor_clinic.forEach(clinic => {
        clinic['checked'] = true;
      });
    }
  }

  unselectAllClinic() {
    if (this.doctor.doctor_clinic.length > 0) {
      this.doctor.doctor_clinic.forEach(clinic => {
        clinic['checked'] = false;
      });
    }
  }
}
