import { CopayType } from './../models/CopayType';
import { FilterServiceCategoryPipe } from './../pipes/filter-service-category.pipe';
import { COPAY_UNIT } from './../models/Copay';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SERVICE_TYPE } from './../models/Service_Item';
import { DataTableDirective } from 'angular-datatables';
import { LocalStorageService } from './../services/local-storage.service';
import { ROLE } from './../models/User';
import { ApiService } from '../services/api-service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import * as Ajv from 'ajv';
import * as bootstrap from 'bootstrap';
import { Service_Item } from '../models/Service_Item';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Specialty } from '../models/Specialty';
import { Payor } from '../models/Payor';
import { ServiceCategory } from '../models/ServiceCategory';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-import-plans',
  templateUrl: './import-plans.component.html',
  styleUrls: ['./import-plans.component.scss']
})
export class ImportPlansComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  readonly schema = {
    type: 'array',
    items: {
      required: ['plan name', 'plan code', 'gp co payment', 'sp co payment', 'chi co payment', 'phy co payment', 'no of members', 'termination notice period'],
      properties: {
        'gp co payment': {
          type: 'number',
          minimum: 0
        },
        'sp co payment': {
          type: 'number',
          minimum: 0
        },
        'chi co payment': {
          type: 'number', 
          minimum: 0
        },
        'phy co payment': {
          type: 'number',
          minimum: 0
        },
        'no of members': {
          type: 'number',
          minimum: 0
        },
        'termination notice period': {
          type: 'number',
          minimum: 0
        }
      }
    }
  };
  readonly maxErrorLength = 20;
  readonly maxPlanShownInChunk = 8;
  readonly maxServiceItemShownInChunk = 8;

  private translatedText: any;
  errorMsgs: string[] = [];
  noOfErrorsNotShowing: number;

  private ajv = new Ajv({
    coerceTypes: true,
    allErrors: true,
    jsonPointers: true
  });
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  reader: FileReader = new FileReader();
  file: DataTransfer;
  fileImported: boolean;
  importedPlans: {}[] = [];
  importedTotal: number;
  mappedPlanService: {}[] = [];

  isAdmin: boolean = false;

  newItem: {
    service?: Service_Item,
    copayType?: CopayType
  } = {
    service: null,
    copayType: null
  };
  backupServiceList = [];
  serviceList = [];
  specialtyList: Specialty[] = [];
  specialtyOptions: Select2OptionData[] = [];
  categoryList: ServiceCategory[] = [];
  categoryOptions: Select2OptionData[] = [];
  copayTypeArray: CopayType[] = [];
  selectedSpecialty = null;
  selectedCategory = null;
  selectedCopayUnit = null;
  selectedPayor = null;
  selectedUnit = COPAY_UNIT.amount;
  fetchItemArr = [];
  addedItemList = [];
  instructions: {
    id: number,
    messages: string[]
  }[] = [];
  // payorList: Payor[] = [];
  payorList: any[] = [];
  payorOptions: Select2OptionData[] = [];
  selectedServiceTypes = [];
  instructionsList = [];

  memberCard;
  memberCardURL;
  topName = 0; 
  leftName = 0;
  topPolicy = 0;
  leftPolicy = 0;
  displayName = true;
  displayPolicy = true;

  loadingItems: boolean  = false;
  rendering: boolean;
  loading: boolean;
  addFeeCheck = false;

  copayUnit = COPAY_UNIT;

  constructor(private apiService: ApiService, private storage: LocalStorageService, private translate: TranslateService, private toastr: ToastrService, private router: Router, private filterServiceCategory: FilterServiceCategoryPipe) {
    this.translate.get([
      'plan_name',
      'gp_copay',
      'sp_copay',
      'tcm_copay',
      'physio_copay',
      'no_of_members',
      'bulk_import_plans.termination_notice_period',
      'bulk_import_plans.row',
      'bulk_import_plans.import_in_process',
      'bulk_import_plans.msg_import_in_process'
    ]).subscribe(res => this.translatedText = res);
  }

  async ngOnInit() {
    let user = await this.storage.getUserProfile();
    if (user.role === ROLE.admin || user.role === ROLE.operator) {
      this.isAdmin = true;
      try {
        this.payorList = await this.apiService.getPayors();
        this.payorOptions = this.payorList.map(item => ({id: item.id, text: item.name}));
      } catch (err) {
        console.log(err);
      }
    }

    await this.fetchSpecialtyList();
    await this.fetchCategoryList();
    this.fetchCopyType();

    this.initFileReader();
    this.getInstructionList();

    let that = this;
    $('.import-plans-container').on('change', '.table-checkbox', function () {
      let $id = $(this).attr('id');

      if ($id === 'checkbox-all') {
        for (let plan of that.importedPlans) {
          plan['selected'] = this.checked;
        }

        $('.table-checkbox').prop('checked', this.checked);
      } else {
        let idChunk = $id.split('_');
        let planName = idChunk[1].split('-').join(' ');
        for (let plan of that.importedPlans) {
          if (plan['plan name'] === planName && plan['plan code'] == idChunk[2]) {
            plan['selected'] = this.checked;
          }
        }
      }
    });
  }

  onFileChange(file: DataTransfer) {
    /* wire up file reader */
    this.file = file;
  }

  upload() {
    if (this.mappedPlansTotal() > 0) {
      $('#modal-alert').modal({
        backdrop:'static',
        keyboard: false,
        show: true
      });
    } else
      this.readFile();
  }

  addNewFee() {
    this.addFeeCheck = true;
    if (this.newItem.service && this.selectedCopayUnit) {
      this.newItem.copayType = this.selectedCopayUnit;
      if (this.addedItemList.length > 0 && this.addedItemList.findIndex(item => item.id === this.newItem.service.id) > -1) {
        this.addedItemList.findIndex(item => item.id === this.newItem.service.id);
      } else {
        this.addedItemList.push(this.newItem);
      }
      let index = this.serviceList.findIndex(item => item.id === this.newItem.service.id);
      this.serviceList.splice(index, 1);
      this.addFeeCheck = false;
      this.newItem = {
        service: null,
        copayType: null
      };
    }
  }

  addAllService() {
    let list = JSON.parse(JSON.stringify(this.filterServiceCategory.transform(this.filterListBySpecialtyId(this.serviceList, this.selectedSpecialty), this.selectedCategory)));    
    list.forEach(item => {
      this.newItem.service = item;
      this.addNewFee();
    });
  }

  private changeKeysToLowerCase(array) {
    let newarray = [];
    for (let data of array) {
      let key, keys = Object.keys(data);
      let n = keys.length;
      let newobj = {};
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = data[key];
      }
      newarray.push(newobj);
    }
    return newarray;
  }

  private validateExcel(data) {
    var validate = this.ajv.compile(this.schema);
    var valid = validate(data);

    return {valid: valid, errors: validate.errors};
  }

  filterChanged(event: {}) {
    if (!this.fetchItemArr[event['value']]) {
      this.fetchServiceList(event['value']);
      this.fetchItemArr[event['value']] = true;
    }
  }

  filterListBySpecialtyId(arr, selector) {
    if (!arr || !selector)
      return arr;
    return arr.filter(item => item.specialty.id == selector);
  }

  selectedServiceItem(event) {
    this.newItem.service = this.serviceList.find(item => item.id == event['value']);
    console.log(this.newItem)
  }

  getSelect2OptionsOfServiceItems(arr: Service_Item[], selector) {
    let array = this.filterListBySpecialtyId(arr, selector);
    array = this.filterServiceCategory.transform(array, this.selectedCategory);
    return array.map(value => {
      return {
        id: value.id,
        text: value.name
      };
    });
  }

  removeFee(index) {
    this.serviceList.push(this.addedItemList[index].service);
    this.addedItemList.splice(index, 1);
  }

  canMap(): boolean {
    if (this.addedItemList.length <= 0)
      return false;
  
    for (let plan of this.importedPlans) {
      if (plan['selected'])
        return true;
    }

    return false;
  }

  map() {
    let chunk = {
      plans: [],
      service_items: [],
      showAllPlans: false,
      showAllServiceItems: {}
    };

    for (let type in SERVICE_TYPE) {
      chunk.showAllServiceItems[type] = false;
    }

    for (let i = 0; i < this.importedPlans.length; i++) {
      if (this.importedPlans[i]['selected']) {
        this.importedPlans[i]['selected'] = false;
        chunk.plans.push(this.importedPlans[i]);

        this.importedPlans.splice(i--, 1);
      }
    }

    if (chunk.plans.length > 0) {
      chunk.service_items = JSON.parse(JSON.stringify(this.addedItemList));
      this.mappedPlanService.push(chunk);
      this.selectedServiceTypes.push(SERVICE_TYPE.gp);
    }

    $('#checkbox-all').prop('checked', false);
    this.addedItemList = [];
    this.serviceList = JSON.parse(JSON.stringify(this.backupServiceList));

    this.reRender();
  }

  removeChunk(index) {
    for (let plan of this.mappedPlanService[index]['plans']) {
      this.importedPlans.push(plan);
    }

    this.mappedPlanService.splice(index, 1);
    
    this.reRender();
  }

  serviceTypeArray() {
    let serviceType = [];
    for (let key of Object.keys(SERVICE_TYPE))
      serviceType.push(SERVICE_TYPE[key]);
    return serviceType;
  }

  mappedPlansTotal() {
    let result = 0;
    for (let plan of this.mappedPlanService) {
      result += plan['plans'].length;
    }

    return result;
  }
  
  async submit() {
    if(!this.selectedPayor)
      return;
    
      this.loading = true;

    let param = [];

    let gpId = this.copayTypeArray.find(item => item.type === 'GP').id;
    let spId = this.copayTypeArray.find(item => item.type === 'SP').id;
    let tcmId = this.copayTypeArray.find(item => item.type === 'TCM').id;
    let physioId = this.copayTypeArray.find(item => item.type === 'PHYSIO').id;

    for (let planService of this.mappedPlanService) {
      let planObj = {};
      for (let plan of planService['plans']) {
        planObj = {
          type: 'STANDARD',
          name: plan['plan name'] + ' ' + plan['plan code'],
          insurer_id: this.selectedPayor,
          num_of_members: plan['no of members'],
          term_notice_period: plan['termination notice period'],
          copay: [{
            copay_type_id: gpId,
            copay_amount: plan['gp co payment'],
            copay_unit: this.selectedUnit
          }, {
            copay_type_id: spId,
            copay_amount: plan['sp co payment'],
            copay_unit: this.selectedUnit
          }, {
            copay_type_id: tcmId,
            copay_amount: plan['chi co payment'],
            copay_unit: this.selectedUnit
          }, {
            copay_type_id: physioId,
            copay_amount: plan['phy co payment'],
            copay_unit: this.selectedUnit
          }]
        };

        let services = [];
        for (let service of planService['service_items']) {
          let copay_type_id;
          switch (service['copayType']) {
            case SERVICE_TYPE.gp:
              copay_type_id = gpId;
              break;
            case SERVICE_TYPE.sp:
              copay_type_id = spId;
              break;
            case SERVICE_TYPE.tcm:
              copay_type_id = tcmId;
              break;
            case SERVICE_TYPE.physio:
              copay_type_id = physioId;
          }
          services.push({
            id: service['service']['id'],
            copay_type_id: copay_type_id
          });
        }

        planObj['services'] = services;
        planObj['instructions'] = this.instructions;
        param.push(planObj);
      }
    }

    console.log(param);

    let style = {
      'name': null,
      'policy_no': null
    }
    if(this.displayName) {
      style['name'] = {
        'top': this.topName + '%',
        'left': this.leftName + '%'
      }
    } else {
      style['name'] = {
        'display': 'none',
      }
    }
    if(this.displayPolicy) {
      style['policy_no'] = {
        'top': this.topPolicy + '%',
        'left': this.leftPolicy + '%'
      }
    } else {
      style['policy_no'] = {
        'display': 'none',
      }
    }

    try {
      await this.apiService.addPlans(param, this.memberCardURL, style);
      this.toastr.success(this.translatedText['bulk_import_plans.msg_import_in_process'], this.translatedText['bulk_import_plans.import_in_process'], {
        positionClass: "toast-bottom-full-width"
      });
      this.router.navigate(['/plan']);
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  private async reRender() {
    this.rendering = true;
    let dtInstance = await this.dtElement.dtInstance;

    if (dtInstance)
      dtInstance.destroy();
    this.dtTrigger.next();

    setTimeout(() => {
      this.rendering = false;
    }, 1000);
  }

  private async fetchServiceList(id) {
    try {
      this.loadingItems = true;
      let list = <Service_Item[]>await this.apiService.serviceItemList(id);
      list.forEach(item => {
        this.serviceList.push(JSON.parse(JSON.stringify(item)));
        this.backupServiceList.push(JSON.parse(JSON.stringify(item)));
      })
      
    } catch (err) {
      console.log(err);
    } finally {
      this.loadingItems = false;
    }
  }

  private async fetchSpecialtyList() {
    try {
      this.specialtyList = <Specialty[]>await this.apiService.getSpecialty(false);
      this.specialtyOptions = this.specialtyList.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
      if (this.specialtyList.length > 0) {
        this.specialtyList.forEach(item => {
          this.fetchItemArr.push(false);
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  private async fetchCategoryList() {
    try {
      this.categoryList = await this.apiService.getServiceCategory();
      this.categoryOptions = this.categoryList.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err);
    }
  }

  private renderValidationError(errors) {
    this.errorMsgs = [];
    for (let i = 0; i < this.maxErrorLength; i++) {
      if (errors[i]) {
        let arr = errors[i]['dataPath'].split('/');
        switch (errors[i]['keyword']) {
          case 'required':
            this.errorMsgs.push(this.translatedText['bulk_import_plans.row'] + ' ' + (parseInt(arr[1]) + 2) + " " + errors[i]['message']);
            break;
          default:
            this.errorMsgs.push(this.translatedText['bulk_import_plans.row'] + ' ' + (parseInt(arr[1]) + 2) + " " + arr[2] + " " + errors[i]['message']);
            break;
        }
      } else
        break;
    }

    if (errors.length > this.maxErrorLength)
      this.noOfErrorsNotShowing = errors.length - this.maxErrorLength;
    else
      this.noOfErrorsNotShowing = 0;
  }

  private initFileReader() {
    this.reader = new FileReader();
    this.reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      let excelData = XLSX.utils.sheet_to_json(ws);
      // To make the header in case sensitive
      excelData = this.changeKeysToLowerCase(excelData);

      let result = this.validateExcel(excelData);
      if (result.valid) {
        for (let data of excelData)
          data['selected'] = false;

        this.importedPlans = excelData;
        this.importedTotal = excelData.length;
        this.dtOptions = {
          order: [[1, 'asc']],
          ajax: (data, callback) => {
            callback({data: this.importedPlans});
          },
          columns: [{
            title: '<input class="table-checkbox" id="checkbox-all" type="checkbox">',
            render: (data, type, row) => '<input class="table-checkbox" id="checkbox_' + row['plan name'].split(' ').join('-') + '_' + row['plan code'] + '" type="checkbox">'
          }, {
            title: this.translatedText['plan_name'],
            render: (data, type, row) => row['plan name'] + ' ' + row['plan code']
          }, {
            title: this.translatedText['gp_copay'],
            data: 'gp co payment'
          }, {
            title: this.translatedText['sp_copay'],
            data: 'sp co payment'
          }, {
            title: this.translatedText['tcm_copay'],
            data: 'chi co payment'
          }, {
            title: this.translatedText['physio_copay'],
            data: 'phy co payment'
          }, {
            title: this.translatedText['no_of_members'],
            data: 'no of members'
          }, {
            title: this.translatedText['bulk_import_plans.termination_notice_period'],
            data: 'termination notice period'
          }],
          columnDefs: [{
            targets: 0,
            orderable: false
          }]
        };
        
        this.mappedPlanService = [];

        this.errorMsgs = [];

        this.fileImported = true;

        setTimeout(() => {
          this.reRender();
        });
      } else {
        this.renderValidationError(result.errors);
      }
    };
  }

  private async fetchCopyType() {
    try {
      this.copayTypeArray = await this.apiService.getCopayType();
    } catch (err) {
      console.error(err);
    }
  }

  readFile() {
    if (this.file.files.length > 0) {
      if (this.file.files.length !== 1) throw new Error('Cannot use multiple files');
        this.reader.readAsBinaryString(this.file.files[0]);
    } else {
      this.fileImported = false;
    }
  }

  async downloadTemplate() {
    try {
      await this.apiService.asiaPlanTemplate();
    } catch (err) {
      console.log(err);
    }
  }

  onMedicalChange(event: DataTransfer){
    this.memberCard = event.files[0];

    var reader = new FileReader();
    reader.onload = e => this.memberCardURL = reader.result;
    reader.readAsDataURL(this.memberCard);
  }

  instructionString(id) {
    if(id)
      return this.instructionsList[this.instructionsList.findIndex(x => x.id.toString() === id.toString())]['type'];
  }

  newLine(i) {
    this.instructions[i].messages.push('');
  }

  async getInstructionList() {
    try {
      this.instructionsList = await this.apiService.getInstructions();
    } catch (err) {
      console.log(err);
    }
  }

  newInstruction() {
    this.instructions.push({
      id: null,
      messages: ['']
    });
  }

  removeInstruction(i) {
    this.instructions.splice(i, 1);
  }

  removeLine(i, j) {
    this.instructions[i].messages.splice(j, 1);
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

}
