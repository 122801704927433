import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterInsideDoctor'
})
export class FilterInsideDoctorPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || !args || args === '-1') {
      return value;
    }

    let tempList = [];
    value.forEach(item => {
      if(item.doctor && item.doctor.toUpperCase() === args.toUpperCase())
        tempList.push(item);
    });
    return tempList;
  }

}
