import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api-service';
import { LocalStorageService } from '../services/local-storage.service';
import { PREAPPROVAL_STATUS } from '../models/Preapproval';

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
export class InstructionComponent implements OnInit {

  clinicToken: string = null;
  paToken: string = null;
  result;

  refreshLoading: boolean = false;
  processLoading: boolean = false;

  errorMsg: string = "";
  processError: string = null;

  benefits: any = [];
  loading;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private storage: LocalStorageService, private router: Router) { }

  async ngOnInit() {
    this.route.queryParams.subscribe(async params => {
      this.clinicToken = params.clinicToken;
      this.paToken = params.paToken;
      await this.auth(this.clinicToken, this.paToken);
      await this.checkPaStatus();
    });
  }

  async getBenefits() {
    try {
      this.loading = true;
      this.benefits = await this.apiService.getPreapprovalServices(this.paToken);
    } catch (err) {
      console.log(err);
      this.errorMsg = err.error.error_messages;
    } finally {
      this.loading = false;
    }
  }

  async checkPaStatus() {
    this.refreshLoading = true;
    try {
      this.result = await this.apiService.checkPaStatus(this.paToken);
      switch(this.result.status) {
        case PREAPPROVAL_STATUS.INITIAL:
          if (this.result.preapproval.medical_card) {
            await this.getBenefits();
          }
          break;
        case PREAPPROVAL_STATUS.DRAFT:
          this.router.navigate(['/preapproval'], { queryParams: { 
            clinicToken: this.clinicToken,
            paToken: this.paToken
          }});
          break;
        case PREAPPROVAL_STATUS.SUBMITTED:
        case PREAPPROVAL_STATUS.APPROVED:
        case PREAPPROVAL_STATUS.DECLINED:
        case PREAPPROVAL_STATUS.DROPPED:
        case PREAPPROVAL_STATUS.CLAIMED:
          this.router.navigate(['/preapproval-list', this.paToken]);
          break;
        case PREAPPROVAL_STATUS.CANCELLED:
        case PREAPPROVAL_STATUS.DELETED:
          this.router.navigate(['/home']);
          break;
      }
    } catch (err) {
      console.log(err);
      this.processError = err.error.error_messages;
      // this.router.navigate(['/home']);
    } finally {
      setTimeout(() => {
        this.refreshLoading = false;
      }, 1000);
    }
  }

  async auth(clinicToken, paToken) {
    try {
      let auth = await this.apiService.preapprovalAuthentication(clinicToken, paToken);
      await this.storage.setUserProfile(auth);
      await this.storage.setVisitToken(auth.api_token);
    } catch (err) {
      console.log(err);
      this.processError = err.error.error_messages;
      // this.router.navigate(['/home']);
    }
  }

  async proceed() {
    try {
      this.processLoading = true;
      await this.apiService.clinicConsentPa(this.paToken);
      this.router.navigate(['/preapproval'], { queryParams: { 
        clinicToken: this.clinicToken,
        paToken: this.paToken
      }});
    } catch (err) {
      console.log(err);
      this.errorMsg = err.error.error_messages;
      // this.router.navigate(['/home']);
    } finally {
      this.processLoading = false;
    }
  }

}
