import { Question } from "./Question";

export class QuestionTextbox extends Question<string> {
  controlType = 'textbox';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}