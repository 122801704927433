import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDoctor'
})
export class FilterDoctorPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || !args || args === '-1') {
      return value;
    }

    return value.filter(item => item.en_name.toUpperCase().indexOf(args.toUpperCase()) > -1);
  }

}
