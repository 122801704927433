import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
  yearRange: string = null;

  constructor() { }

  ngOnInit() {
    let year = new Date().getFullYear();
    this.yearRange = '2018 - ' + year
  }

}
