import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPracticeYear'
})
export class FilterPracticeYearPipe implements PipeTransform {

  transform(value: any, args?: any[]): any {
    if (!value || args) {
      return value;
    }
    
    return value.filter(item => (item.doctorDetail.year_partice > args[0] && item.doctorDetail.year_partice < args[1]));
  }

}
