export enum PREAPPROVAL_STATUS {
    APPROVED = 'APPROVED',
    CANCELLED = 'CANCELLED',   // by another clinic
    CLAIMED = 'CLAIMED',
    DECLINED = 'DECLINED',
    DELETED = 'DELETED',   // by same clinic
    DRAFT = 'DRAFT',
    DROPPED = 'DROPPED',   // by payor
    INITIAL = 'INITIAL',
    SUBMITTED = 'SUBMITTED'
}

export enum PREAPPROVAL_SERVICE_CATEGORY {
    CONSULTATION = 'CONSULTATION',
    FACILITY = 'FACILITY',
    LABORATORY = 'LABORATORY',
    MEDICAL_PROCEDURE = 'MEDICAL PROCEDURE',
    MEDICATION = 'MEDICATION',
    MISC = 'MISC',
    OTHERS = 'OTHERS',
    SEDATION = 'SEDATION',
    SURGICAL = 'SURGICAL',
    SURGICAL_PROCEDURE = 'SURGICAL PROCEDURE',
    VACCINATION = 'VACCINATION'
}
