import { Specialty } from "./Specialty";

export interface Service_Item {
    id: number,
    name: string,
    category: string,
    category_id: string,
    schema_codes?: {
        id: number,
        messages: string[],
        name: string,
        code: string
    }[],
    service_code: string,
    specialty: Specialty,
    cover_type: COVER_TYPE,
    service_type: SERVICE_TYPE,
    copay_type_id: number,
    agree_fee: number,
    status: string,
    admin_fee?: {
        insurer: {
            id: string,
            name: string,
        },
        admin_fee: number,
        unit: string
    }[]
}

export enum COVER_TYPE {
    pkg = 'PACKAGE',
    pkg_item = 'PACKAGE_ITEM'
}

export enum SERVICE_TYPE {
    gp = 'GP',
    sp = 'SP',
    tcm = 'TCM',
    physio = 'PHYSIO'
}