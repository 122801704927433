import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';
import { PREAPPROVAL_STATUS } from '../models/Preapproval';

@Component({
  selector: 'app-approved-preapproval',
  templateUrl: './approved-preapproval.component.html',
  styleUrls: ['./approved-preapproval.component.scss']
})
export class ApprovedPreapprovalComponent implements OnInit {

  selectedAll: boolean = false;
  approvalList: any[] = [];
  selectedList: any[] = [];

  loading: boolean;

  dtOptions: DataTables.Settings;

  constructor(private apiService: ApiService) { }

  async ngOnInit() {
    try {
      this.loading = true;
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let approval = await this.apiService.getPreapproval(null, null, PREAPPROVAL_STATUS.APPROVED, null, null, null, true, PER_PAGE, (dataTablesParameters.start/PER_PAGE + 1));   
          this.approvalList = approval.data;
          this.loading = false;

          callback({
            recordsTotal: approval.pagination.total,
            recordsFiltered: approval.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

}
