import { FilterServiceCategoryPipe } from './../../pipes/filter-service-category.pipe';
import { Instruction } from './../../models/Instruction';
import { Plan } from './../../models/Plan';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from '../../services/local-storage.service';
import { ROLE } from '../../models/User';
import { PLAN_TYPE } from '../../models/Plan';
import { ApiService } from '../../services/api-service';
import { SortServiceItemPipe } from '../../pipes/sort-service-item.pipe';
import { Service_Item } from '../../models/Service_Item';
import { SortArrayWithServiceBySpecialtyPipe } from '../../pipes/sort-array-with-service-by-specialty.pipe';
import { Router, ActivatedRoute } from '@angular/router';
import { Specialty } from 'src/app/models/Specialty';
import { COPAY_UNIT } from 'src/app/models/Copay';
import { TranslateService } from '@ngx-translate/core';
import { ServiceCategory } from 'src/app/models/ServiceCategory';
import { Payor } from 'src/app/models/Payor';
import { Select2OptionData } from 'ng-select2';
import { CopayType } from '../../models/CopayType';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {
  @Output() submit = new EventEmitter();

  readonly MAX_NO_OF_MEMBERS = 1000000;
  readonly MIN_NO_OF_MEMBERS = 0;
  readonly MIN_TERM_PERIOD = 0;

  private translatedText;

  plan: Plan;
  planModel: {
    name: string,
    num_of_members: number,
    term_notice_period: number,
    type: PLAN_TYPE,
    insurer_id: string,
    copay: {
      copay_type_id: number,
      copay_amount: number,
      copay_unit: string
    }[]
    instructions: {
      id: number,
      messages: string[]
    }[],
    services: {
      id: number,
      copay_type_id: number
    }[]
  } = {
    name: null,
    num_of_members: null,
    term_notice_period: null,
    type: PLAN_TYPE.std,
    insurer_id: null,
    copay: [],
    instructions: [],
    services: []
  };


  loading: boolean;
  plan_type = PLAN_TYPE;
  copayUnit = COPAY_UNIT;

  submitBtn = false;
  isAdmin = false;
  addFeeCheck = false;
  payorList:any[] = [];
  payorOptions: Select2OptionData[] = [];

  instructions: Instruction[] = [];
  // benefitSummary = [];

  copayTypeArray = [];

  newItem: {
    service?: Service_Item,
    copayType?: CopayType
  };
  serviceList: Service_Item[] = [];
  specialtyList: Specialty[] = [];
  specialtyOptions: Select2OptionData[] = [];
  categoryList: ServiceCategory[] = [];
  categoryOptions: Select2OptionData[] = [];
  selectedSpecialty = null;
  selectedCategory = null;
  fetchItemArr = [];
  itemList: {
    service: Service_Item,
    copayType: CopayType
  }[] = [];
  errorMsg = [];

  newlyAddedServiceItems: {
    [key: number]: {
      service: Service_Item,
      copayType: CopayType
    }[]
  } = {};

  // GPList = [];
  // SPList = [];
  // TCMList = [];
  // PHYSIOList = [];
  // NotList = [];

  // oriGPList = [];
  // oriSPList = [];
  // oriTCMList = [];
  // oriPHYSIOList = [];
  // oriNotList = [];

  loadingItems: boolean = false;

  memberCard;
  memberCardURL;
  topName = 0; 
  leftName = 0;
  topPolicy = 0;
  leftPolicy = 0;
  displayName = true;
  displayPolicy = true;

  edit = false;

  // gpConsultation = [];
  // spConsultation = [];
  // tcmConsultation = [];
  // physcoConsultation = [];

  constructor(private storage: LocalStorageService, private apiService: ApiService, private sortName: SortServiceItemPipe, private sortingPipe: SortArrayWithServiceBySpecialtyPipe, private router: Router, private route: ActivatedRoute, private translate: TranslateService, private filterServiceCategory: FilterServiceCategoryPipe, private toastr: ToastrService,) {
    this.translate.get([
      'add_plan.name_required',
      'add_plan.no_of_members_required',
      'add_plan.no_of_members_min',
      'add_plan.no_of_members_max',
      'add_plan.term_notice_period_required',
      'add_plan.term_notice_period_min',
      'add_plan.term_notice_period_max',
      'add_plan.medical_card_template_required',
      'add_plan.service_items_required',
      'add_plan.payor_required',
      'add_plan.instructions_no_empty',
      'submit_error'
    ], {
      member_min: this.MIN_NO_OF_MEMBERS,
      member_max: this.MAX_NO_OF_MEMBERS,
      period_min: this.MIN_TERM_PERIOD
    }).subscribe(res => this.translatedText = res);

    this.newItem = {
      service: null,
      copayType: null
    };
  }

  async ngOnInit() {
    this.loading = true;
    await this.fetchCopyType();
    await this.getInstructionList();
    await this.fetchSpecialtyList();
    await this.fetchCategoryList();
    
    if(this.router.url.includes("edit")) {
      this.edit = true;
      let id = this.route.snapshot.paramMap.get("id");
      this.fetchPlan(id);
    }

    let user = await this.storage.getUserProfile();
    if (user.role === ROLE.admin || user.role === ROLE.operator) {
      this.isAdmin = true;
      try {
        this.payorList = await this.apiService.getPayors();
        this.payorOptions = this.payorList.map(item => {
          return {
            id: item.id,
            text: item.name
          };
        });
      } catch (err) {
        console.log(err);
      }
    }
    this.loading = false;
  }

  async fetchPlan(id) {
    try {
      this.loading = true;
      this.plan = await this.apiService.getPlanDetail(id);

      this.planModel.name = this.plan.name;
      this.planModel.num_of_members = this.plan.num_of_members;
      this.planModel.term_notice_period = this.plan.term_notice_period;
      this.planModel.insurer_id = this.plan.insurer.id
      // for (let co of this.plan.copay) {
      //   switch (co.copay_type) {
      //     case COPAY_TYPE.gp:
      //       this.planModel.gp_copay_amount = co.amount;
      //       this.planModel.gp_copay_unit = co.unit;
      //       break;
      //     case COPAY_TYPE.sp:
      //       this.planModel.sp_copay_amount = co.amount;
      //       this.planModel.sp_copay_unit = co.unit;
      //       break;
      //     case COPAY_TYPE.tcm:
      //       this.planModel.tcm_copay_amount = co.amount;
      //       this.planModel.tcm_copay_unit = co.unit;
      //       break;
      //     case COPAY_TYPE.physio:
      //       this.planModel.physio_copay_amount = co.amount;
      //       this.planModel.physio_copay_unit = co.unit;
      //       break;
      //   }
      // }
      for (let i = 0; i < this.planModel.copay.length - 1; i++) {
        let index = this.plan.copay.findIndex( x => x.id === this.planModel.copay[i].copay_type_id)
        this.planModel.copay[i].copay_amount = this.plan.copay[index].amount;
        this.planModel.copay[i].copay_unit = this.plan.copay[index].unit;
      }
      this.memberCardURL = this.plan.image;
      this.displayName = this.plan.image_style.name['display'] ? false : true;
      this.displayPolicy = this.plan.image_style.policy_no['display'] ? false : true;
      this.topName = this.plan.image_style.name['top'] ? parseInt(this.plan.image_style.name['top'].split('%')) : 0;
      this.topPolicy = this.plan.image_style.policy_no['top'] ? parseInt(this.plan.image_style.policy_no['top'].split('%')) : 0;
      this.leftName = this.plan.image_style.name['left'] ? parseInt(this.plan.image_style.name['left'].split('%')) : 0;
      this.leftPolicy = this.plan.image_style.policy_no['left'] ? parseInt(this.plan.image_style.policy_no['left'].split('%')) : 0;

      this.planModel.instructions = this.plan.instructions;
      this.planModel.services = [];
      for (let service of this.plan.services)
        this.planModel.services.push({
          id: service.id,
          copay_type_id: service.copay_type_id
        });
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  async add() {
    this.errorMsg = [];
    this.submitBtn = true;
    // error msg
    if (!this.edit) {
      if (!this.planModel.name)
        this.errorMsg.push(this.translatedText['add_plan.name_required']);

      if (!this.planModel.num_of_members)
        this.errorMsg.push(this.translatedText['add_plan.no_of_members_required']);
        
      if (this.planModel.num_of_members < this.MIN_NO_OF_MEMBERS)
        this.errorMsg.push(this.translatedText['add_plan.no_of_members_min']);

      if (this.planModel.num_of_members > this.MAX_NO_OF_MEMBERS)
        this.errorMsg.push(this.translatedText['add_plan.no_of_members_max']);
      
      if (!this.planModel.term_notice_period)
        this.errorMsg.push(this.translatedText['add_plan.term_notice_period_required']);

      if (this.planModel.term_notice_period < this.MIN_TERM_PERIOD)
        this.errorMsg.push(this.translatedText['add_plan.term_notice_period_min']);
        
      if (!this.memberCard)
        this.errorMsg.push(this.translatedText['add_plan.medical_card_template_required']);

      if (this.itemList.length <= 0)
        this.errorMsg.push(this.translatedText['add_plan.service_items_required']);
      // if (this.plan.type === PLAN_TYPE.std && this.isAdmin) {
      if (this.isAdmin) {
        if (!this.planModel.insurer_id) {
          this.errorMsg.push(this.translatedText['add_plan.payor_required']);
          return;
        }
      }
      if (this.planModel.instructions && this.planModel.instructions.length > 0) {
        let fail = false;
        this.planModel.instructions.forEach(item => {
          if (!item.id) 
            fail = true;
          item.messages.forEach(msg => {
            if(!msg) 
              fail = true;
          });
        });
        if (fail) {
          this.errorMsg.push(this.translatedText['add_plan.instructions_no_empty']);
          return;
        }
      }
    }

    if (this.edit) {
      let style = {
        'name': null,
        'policy_no': null
      }
      if(this.displayName) {
        style['name'] = {
          'top': this.topName + '%',
          'left': this.leftName + '%'
        }
      } else {
        style['name'] = {
          'display': 'none',
        }
      }
      if(this.displayPolicy) {
        style['policy_no'] = {
          'top': this.topPolicy + '%',
          'left': this.leftPolicy + '%'
        }
      } else {
        style['policy_no'] = {
          'display': 'none',
        }
      }
      let update = {
        plan_id : this.plan.id,
        services: [],
        instructions: this.planModel.instructions,
        image: this.memberCardURL,
        style: JSON.stringify(style)
      }
      this.itemList.forEach(item => {
        update.services.push({
          id: item.service.id,
          copay_type_id: item.copayType.id
        })
      })
      try {
        this.loading = true;
        await this.apiService.editPlan(update);
        this.router.navigate(['/plan/view', this.plan.id])
      } catch (err) {
        console.log(err);
        this.toastr.error( err.error.error_messages, this.translatedText['submit_error'], {
          positionClass: "toast-bottom-full-width"
        });
      } finally {
        this.loading = false;
      }

    } else {
      if (this.planModel.name && this.planModel.num_of_members && this.planModel.num_of_members >= this.MIN_NO_OF_MEMBERS && this.planModel.term_notice_period && this.planModel.term_notice_period >= this.MIN_TERM_PERIOD && this.memberCard && this.itemList.length > 0 && this.planModel.num_of_members <= this.MAX_NO_OF_MEMBERS) {
        let planModel = JSON.parse(JSON.stringify(this.planModel));
        this.itemList.forEach(item => {
          planModel.services.push({
            id: item.service.id,
            copay_type_id: item.copayType.id
          });
        });
        this.loading = true;
        try {
          let res = await this.apiService.addPlan(planModel);
          this.uploadMedicalCard(res.id, res.insurer.id);
          this.submit.emit(res.id);
        } catch (err) {
          console.log(err);
          this.toastr.error( err.error.error_messages, this.translatedText['submit_error'], {
            positionClass: "toast-bottom-full-width"
          });
        } finally {
          this.loading = false;
        }
      }
    }
  }

  newLine(i) {
    this.planModel.instructions[i].messages.push('');
  }

  async getInstructionList() {
    try {
      this.instructions = await this.apiService.getInstructions();
    } catch (err) {
      console.log(err);
    }
  }

  newInstruction() {
    this.planModel.instructions.push({
      id: null,
      messages: ['']
    });
  }

  removeInstruction(i) {
    this.planModel.instructions.splice(i, 1);
  }

  removeLine(i, j) {
    this.planModel.instructions[i].messages.splice(j, 1);
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

  async fetchServiceList(id) {
    try {
      this.loadingItems = true;
      let serviceList = <Service_Item[]>await this.apiService.serviceItemList(id);
      serviceList.forEach(item => {
        this.serviceList.push(item);
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.loadingItems = false;
    }
  }

  async fetchSpecialtyList() {
    try {
      this.specialtyList = <Specialty[]>await this.apiService.getSpecialty(false);
      this.specialtyList = this.sortName.transform(this.specialtyList);

      this.specialtyOptions = this.specialtyList.map(value => {
        return {
          id: value.id,
          text: value.name
        };
      });
      
      if (this.specialtyList.length > 0) {
        this.specialtyList.forEach(item => {
          this.fetchItemArr.push(false);
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async fetchCategoryList() {
    try {
      this.categoryList = await this.apiService.getServiceCategory();
      this.categoryList = this.sortName.transform(this.categoryList);
      this.categoryOptions = this.categoryList.map(value => {
        return {
          id: value.id.toString(),
          text: value.name
        };
      });
    } catch (err) {
      console.log(err);
    }
  }

  filterListBySpecialtyId(arr: Service_Item[], selector) {
    if (!arr || !selector)
      return arr;
    return arr.filter(item => item.specialty.id == selector);
  }

  getSelect2OptionsOfServiceItems(arr: Service_Item[], selector) {
    let array = this.filterListBySpecialtyId(arr, selector);
    array = this.filterServiceCategory.transform(array, this.selectedCategory);
    return array.map(value => {
      return {
        id: value.id,
        text: value.name
      };
    });
  }

  filterChanged(event: {}) {
    if (!this.fetchItemArr[event['value']]) {
      this.fetchServiceList(event['value']);
      this.fetchItemArr[event['value']] = true;
    }
  }

  selectedServiceItem(event) {
    this.newItem.service = this.serviceList.find(item => item.id == event['value']);
  }

  addNewFee() {
    this.addFeeCheck = true;
    if (this.newItem.service && this.newItem.copayType) {
      if (!(this.itemList.length > 0 && this.itemList.findIndex(item => item.service.id === this.newItem.service.id) > -1)) {
        let item = {
          service: this.newItem.service,
          copayType: this.newItem.copayType
        };
        this.itemList.push(item);

        if (!this.newlyAddedServiceItems[item.copayType.id])
          this.newlyAddedServiceItems[item.copayType.id] = [];
        this.newlyAddedServiceItems[item.copayType.id].push(item);
      }
      let index = this.serviceList.findIndex(item => item.id === this.newItem.service.id);
      this.serviceList.splice(index, 1);
      // this.itemList = this.sortingPipe.transform(this.itemList);
      this.newItem.service = null;
      this.addFeeCheck = false;
    }
  }

  addAllService() {
      let list = JSON.parse(JSON.stringify(this.filterServiceCategory.transform(this.filterListBySpecialtyId(this.serviceList, this.selectedSpecialty), this.selectedCategory)));
      list.forEach(item => {
        this.newItem.service = item;
        this.addNewFee();
      });
  }

  onFileChange(event: DataTransfer){
    this.memberCard = event.files[0];

    var reader = new FileReader();
    reader.onload = e => this.memberCardURL = reader.result;
    reader.readAsDataURL(this.memberCard);
  }

  async uploadMedicalCard(plan_id, payor_id) {
    let style = {
      'name': null,
      'policy_no': null
    }
    if(this.displayName) {
      style['name'] = {
        'top': this.topName + '%',
        'left': this.leftName + '%'
      }
    } else {
      style['name'] = {
        'display': 'none',
      }
    }
    if(this.displayPolicy) {
      style['policy_no'] = {
        'top': this.topPolicy + '%',
        'left': this.leftPolicy + '%'
      }
    } else {
      style['policy_no'] = {
        'display': 'none',
      }
    }
    let medicalCard = {
      insurer_id: payor_id,
      plan_id: plan_id,
      image: this.memberCard,
      style: JSON.stringify(style)
    }
    try {
      await this.apiService.uploadMedicalCard(medicalCard);
    } catch (err) {
      console.log(err)
    }
  }

  removeFee(index, id, copayType: number) {
    let i = this.itemList.findIndex(x => x.service.id === id);
    this.serviceList.push(this.itemList[i].service);
    this.itemList.splice(i, 1);

    this.newlyAddedServiceItems[copayType].splice(index, 1);
    this.serviceList = this.sortName.transform(this.serviceList);
  }

  async fetchCopyType() {
    try {
      this.copayTypeArray = await this.apiService.getCopayType();
      this.copayTypeArray.forEach(item => {
        if(item.type !== 'NOT COVERED')
          this.planModel.copay.push({
            copay_type_id: item.id,
            copay_amount: 0,
            copay_unit: COPAY_UNIT.amount
          })
      });
    } catch (err) {
      console.log(err);
    }
  }

  instructionString(id) {
    if(id)
      return this.instructions[this.instructions.findIndex(x => x.id.toString() === id.toString())]['type'];
  }

}
