import { Plan } from './../models/Plan';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PLAN_TYPE } from '../models/Plan';
import { User } from '../models/User';
import { LocalStorageService } from '../services/local-storage.service';
import { PER_PAGE } from '../constant/config';

@Component({
  selector: 'contract-template-list',
  templateUrl: './contract-template-list.component.html',
  styleUrls: ['./contract-template-list.component.scss']
})
export class ContractTemplateListComponent implements OnInit {

  // templates;
  planList: Plan[] = [];
  defaultList = [];
  selectedTemplate: number;
  selectedPlan = '-1';
  templateAgreeFee;
  contractType = PLAN_TYPE.std;
  loading;
  user: User;
  defaultTemplate = [];
  fetchDefault = false;
  loadingTemplate;
  loadingAgreeFee;
  totalNum = 0;

  constructor(private apiService: ApiService, private storage: LocalStorageService) {
  }

  async ngOnInit() {
    this.user = await this.storage.getUserProfile();
    // await this.fetchPlan();
    // await this.fetchContractTemplate(1);
    // await this.fetchDefaultTemplate();
  }

  ngAfterViewInit() {
    let newDefault = (new URL(window.location.href)).searchParams.get('default');
    if (newDefault) {
      $('#modal-default').modal('show');
      this.fetchDefaultTemplate();
    }
  }

  // async fetchContractTemplate(page) {
  //   try {
  //     this.loading = true;
  //     if( this.user.role === ROLE.admin || this.user.role === ROLE.operator)
  //       this.templates = await this.apiService.adminGetContractTemplate("true", PER_PAGE, page);
  //     else
  //       this.templates = await this.apiService.fetchContractTemplate("true", PER_PAGE, page);
      
  //     this.totalNum = this.templates['pagination'].total;
  //     // this.templates['data'].forEach(item => {
  //     //   this.planList.push(item.plan);
  //     // });
  //     // this.planList = this.planList.filter(function(item, pos, self) {
  //     //   return self.indexOf(item) == pos;
  //     // })
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     this.loading = false;
  //   }
  // }

  // async fetchPlan() {
  //   this.planList = <Plan[]>(await this.apiService.fetchPlan(false, PER_PAGE));
  // }

  async fetchDefaultTemplate() {
    if (!this.fetchDefault) {
      try {
        this.loadingTemplate = true;
        this.defaultList = await this.apiService.fetchDefaultContractTemplate(PER_PAGE);
        this.defaultList.forEach(item => {
          this.defaultTemplate.push();
        })
        this.fetchDefault = true;
      } catch (err) {
        console.log(err)
      } finally {
        this.loadingTemplate = false;
      }
    }
  }

  removeDuplicates(myArr: any[], prop: string): any[] {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  async fetchDefaultDetail() {
    this.loadingAgreeFee = true;
    if (this.defaultTemplate[this.selectedTemplate-1])
      this.templateAgreeFee = this.defaultTemplate[this.selectedTemplate-1]
    else {
      try {
        let detail = await this.apiService.getContractTemplateDetail(this.selectedTemplate);
        this.templateAgreeFee = detail.services;
        this.defaultTemplate[this.selectedTemplate-1] = detail.services;
      } catch (err) {
        console.log(err);
      }
    }
    this.loadingAgreeFee = false;
  }
}