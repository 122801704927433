import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api-service';

@Component({
  selector: 'app-edit-plan',
  templateUrl: './edit-plan.component.html',
  styleUrls: ['./edit-plan.component.scss']
})
export class EditPlanComponent implements OnInit {

  id;
  plan;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.route.url.subscribe((u) => {
      this.getPlanDetail(this.route.snapshot.params.id);
    });
  }

  async getPlanDetail(id) {
    try {
      this.plan = await this.apiService.getPlanDetail(id);
    } catch (err) {
      console.log(err)
    }
  }

  async updatePlan() {
    // await this.apiService.updatePlanDetail(this.plan);
    this.router.navigate(['/plan']);
  }
}
