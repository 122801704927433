import { COPAY_UNIT, Copay } from './../models/Copay';
import { Plan } from 'src/app/models/Plan';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { ROLE } from '../models/User';

@Component({
  selector: 'app-view-plan',
  templateUrl: './view-plan.component.html',
  styleUrls: ['./view-plan.component.scss']
})
export class ViewPlanComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private storage: LocalStorageService) { }

  plan: Plan;
  loading: boolean = false;
  isAdmin: boolean;

  copayType;

  async ngOnInit() {
    await this.fetchCopyType();
    this.route.url.subscribe((u) => {
      this.fetchPlanDetail(this.route.snapshot.params.id);
    });
    let user = await this.storage.getUserProfile();
    this.isAdmin = ( user.role === ROLE.admin || user.role === ROLE.operator );
  }
  
  // copayTypeArray() {
  //   return Object.keys(COPAY_TYPE);
  // }

  async fetchPlanDetail(id){
    try {
      this.loading = true;
      this.plan = await this.apiService.getPlanDetail(id);
    } catch(err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  copayString(copay: Copay): string {
    // for (let co of this.copayType)
    //   if (copay.copay_type.toUpperCase() === co.copayType.toUpperCase())
        if (copay.unit === COPAY_UNIT.amount) 
          return '$' + copay.amount;
        else
          return copay.amount + '%';
  }

  async fetchCopyType() {
    try {
      this.copayType = await this.apiService.getCopayType();
    } catch (err) {
      console.log(err);
    }
  }

}
