import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-default',
  templateUrl: './edit-default.component.html',
  styleUrls: ['./edit-default.component.scss']
})
export class EditDefaultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
