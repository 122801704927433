import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'agree-fee-table',
  templateUrl: './agree-fee-table.component.html',
  styleUrls: ['./agree-fee-table.component.scss']
})
export class AgreeFeeTableComponent implements OnInit {

  @Input('rows') rows;

  constructor() { }

  ngOnInit() {
  }

  copaymentString(row) {
    if(row.copay_unit === 'PERCENTAGE')
      return row.copay + '%';
    else
      return '$' + row.copay;
  }

}
