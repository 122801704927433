import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterServiceType'
})
export class FilterServiceTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || !args || args === 'default') {
      return value;
    }
    
    let returnList = []
    value.forEach(item => {
      if (item.copayType)
        returnList.push(item);
    });
    
    return returnList.filter(item => item.copayType.toUpperCase().indexOf(args.toUpperCase()) > -1);
  }

}
