import { Service_Item } from '../models/Service_Item';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortArrayWithServiceBySpecialty'
})
export class SortArrayWithServiceBySpecialtyPipe implements PipeTransform {

  transform(value: {service: Service_Item}[], args?: any): any {
    if (!value)
      return value;
    
    return value.sort(this.compare);
  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const genreA = a.service.specialty.name;
    const genreB = b.service.specialty.name;
  
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

}
