import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterClinicName'
})
export class FilterClinicNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || !args || args === '-1') {
      return value;
    }

    return value.filter(item => item.clinic_name.toUpperCase().indexOf(args.toUpperCase()) > -1);
  }

}
