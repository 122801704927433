import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterServiceCategory'
})
export class FilterServiceCategoryPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || !args || args === 'default') {
      return value;
    }
    
    let returnList = [];
    value.forEach(item => {
      if (item.category_id !== undefined)
        returnList.push(item);
    });

    return returnList.filter(item => item.category_id == args);
  }

}
