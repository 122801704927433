import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  public isInteger = (control:FormControl) => {
    return ((parseFloat(control.value) == parseInt(control.value)) && !isNaN(control.value))? null : { notNumeric: true };
  }

  public isNumber = (control:FormControl) => {
    return !isNaN(control.value)? null : { notNumeric: true };
  }

  public isEnglish(){
    return Validators.pattern("^([a-zA-Z0-9,. !()'#$%&*-_+=]?)+$");
  }

}
