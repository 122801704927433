import { Clinic } from './Clinic';

export interface DoctorClinic {
    id: number,
    clinic: Clinic
}

export enum CONTRACT_STATUS {
    pending = "Pending",
    signed = "Signed"
}