import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortServiceItem'
})
export class SortServiceItemPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.sort(this.compare);
  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const genreA = a.name.toUpperCase();
    const genreB = b.name.toUpperCase();
  
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

}
