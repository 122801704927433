import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';
import { PREAPPROVAL_STATUS } from '../models/Preapproval';

@Component({
  selector: 'app-submitted-preapproval',
  templateUrl: './submitted-preapproval.component.html',
  styleUrls: ['./submitted-preapproval.component.scss']
})

export class SubmittedPreapprovalComponent implements OnInit {

  selectedAll: boolean = false;
  preapprovalList: any[] = [];
  selectedList: any[] = [];

  loading: boolean;

  dtOptions: DataTables.Settings;

  constructor(private apiService: ApiService) { }

  async ngOnInit() {
    try {
      this.loading = true;
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let preapprovals = await this.apiService.getPreapproval(null, null, PREAPPROVAL_STATUS.SUBMITTED, null, null, null, true, PER_PAGE, (dataTablesParameters.start/PER_PAGE + 1));
          this.preapprovalList = preapprovals.data;
          this.loading = false;

          callback({
            recordsTotal: preapprovals.pagination.total,
            recordsFiltered: preapprovals.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  selectAll() {
    this.selectedAll = !this.selectedAll;
    for(let i = 0 ; i < this.preapprovalList.length ; i++) {
      this.selectedList[i] = this.selectedAll;
    }
  }

  submit() {
    let approvedList = [];
    for(let i = 0 ; i < this.preapprovalList.length ; i++) {
      if(this.selectedList[i])
        approvedList.push(this.preapprovalList[i]);
    }
  }

}
