import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent {
  @Input() accept?: string;
  @Input() multiple?: boolean = false;
  @Output() fileChange: EventEmitter<DataTransfer> = new EventEmitter();

  static INPUT_ID: number = 1;

  readonly inputId: number;
  file: DataTransfer;

  constructor() {
    this.inputId = InputFileComponent.INPUT_ID++;
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    this.file = <DataTransfer>(evt.target);
    this.fileChange.emit(this.file);
  }

}
