import { Doctor } from './../models/Doctor';
import { ContractTemplate } from './../models/ContractTemplate';
import { COPAY_UNIT } from 'src/app/models/Copay';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api-service';
import { SortArrayWithServiceBySpecialtyPipe } from '../pipes/sort-array-with-service-by-specialty.pipe';
import { Offer } from '../models/Offer';
import { LocalStorageService } from '../services/local-storage.service';
import { ROLE, User } from '../models/User';

@Component({
  selector: 'app-counter-offer',
  templateUrl: './counter-offer.component.html',
  styleUrls: ['./counter-offer.component.scss']
})
export class CounterOfferComponent implements OnInit {

  offer: Offer;
  checkList = [];
  agreeList: boolean[] = [];
  agreeFee: number[] = [];
  remarks = '';
  user: User;
  role = ROLE;
  loading;

  constructor(private router: Router, private apiService: ApiService, private route: ActivatedRoute, private pipe: SortArrayWithServiceBySpecialtyPipe, private storage: LocalStorageService) { }

  ngOnInit() {
    this.fetchOfferDetail(this.route.snapshot.paramMap.get('id'));
  }

  async fetchOfferDetail(id) {
    if(id) {
      try {
        this.loading = true;
        this.user = await this.storage.getUserProfile();
        if(this.user.role !== ROLE.doctor)
          this.offer = await this.apiService.getOfferDetail(id);
        else
          this.offer = await this.apiService.doctorGetOfferDetail(id);
        for (let i = 0 ; i < this.offer.services.length; i++) {
          this.agreeList.push(true);
          this.agreeFee.push(0);
        }
        // this.offer.services = this.pipe.transform(this.offer.services);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }

  copayString(item) {
    if (item.copay_unit === COPAY_UNIT.amount) 
      return '$' + item.copay;
    else
      return item.copay + '%';
  }

  async decline() {
    if (this.remarks) {
      let reply = {
        offer_id: this.offer.id,
        decision: 'DECLINE',
        decline_remarks: this.remarks
      }
      try {
        await this.apiService.replyOffer(reply);
        $('#modal-default').modal('hide');
        this.router.navigate(['/offer']);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async submit(){
    let reply = {
      offer_id: this.offer.id,
      decision: 'SUBMIT',
      services: []
    }
    for (let i = 0; i < this.offer.services.length; i++) {
      if (this.agreeList[i]) {
        reply.services.push({
        	decision: "ACCEPT",
          service_id: this.offer.services[i].id,
          agree_fee: this.offer.services[i].agree_fee
        });
      } else {
        reply.services.push({
        	decision: "COUNTER",
          service_id: this.offer.services[i].id,
          agree_fee: this.agreeFee[i]
        });
      }
    }
    await this.apiService.replyOffer(reply);
    this.router.navigate(['/offer']);
  }

  getContractName(contract: ContractTemplate) {
    return contract.name;
  }

  getDoctorName(doctor: Doctor) {
    return doctor.name;
  }

}
