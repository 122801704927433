export class Question<T> {  //interface
    value: T;
    key: string;
    label: string;
    required: boolean;
    questionNo: number;
    order: number;
    controlType: string;
    isArray: boolean;
   
    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        required?: boolean,
        questionNo?: number,
        order?: number,
        controlType?: string,
        isArray?: boolean
      } = {}) {
      this.value = options.value;
      this.key = options.key || '';
      this.label = options.label || '';
      this.required = options.required;
      this.questionNo = options.questionNo === undefined ? null : options.questionNo;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.isArray = options.isArray;
    }
}

export enum ANSWER_TYPE {
    STRING = "Input Box",
    DROPDOWN = "Dropdown",
    BOOLEAN = "Yes / No",
    DATE = "Date",
    DIAGNOSIS = "Diagnosis",
    HOSPITAL = "Hospital",
    SERVICE = "Services/Procedures"
}

export enum ANSWER_BOOLEAN {
    true = "Yes",
    false = "No"
}

export enum BIND_TYPE {
    BOTH = "Both",
    NO = "No",
    YES = "Yes",
    NONE = "None"
}