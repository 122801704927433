export interface User {
    id: string,
    email: string,
    role: ROLE,
    api_token: string,
    detail?: {
        icon: string,
        id: string,
        name: string,
        status: string,
        email: string,
    }
}

export enum ROLE {
    admin = 'admin',
    payor = 'payor',
    doctor = 'doctor',
    finance = 'finance',
    operator = 'operator',
    softlink = 'softlink'
}

export enum STATUS {
    active = 'Active', 
    inactive = 'Inactive'
}