import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api-service';
import { PLAN_TYPE } from '../models/Plan';
import { LocalStorageService } from '../services/local-storage.service';
import { ROLE } from '../models/User';

@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss']
})
export class AddTemplateComponent implements OnInit {

  warningDisplay: boolean = false;
  fromDefaultContract: boolean;
  // planList: Plan[] = [];

  contract = {
    name: '',
    plan_id: null,
    services: [],
    type: PLAN_TYPE.std,
    creator_id: ''
  };

  user;
  contractRes;

  constructor(private router: Router, private apiService: ApiService, private route: ActivatedRoute, private storage: LocalStorageService) {
    if ((new URL(window.location.href)).searchParams.get('default')) {
      this.fromDefaultContract = true;
    } else {
      this.fromDefaultContract = false;
    }
  }

  async ngOnInit() {
    this.user = await this.storage.getUserProfile();
    // await this.fetchPlan();
  }

  // async fetchPlan() {
  //   if(this.user.role === ROLE.admin || this.user.role === ROLE.operator)
  //     this.planList = <Plan[]>(await this.apiService.fetchALLPlan());
  //   else
  //     this.planList = <Plan[]>(await this.apiService.fetchPlan());
  // }

  async submit(contract) {
    console.log(contract)
    this.contractRes = contract;
    if(this.user.role === ROLE.admin || this.user.role === ROLE.operator)
      this.router.navigate(['/contract/view-template', contract.id])
    else
      $('#modal-default').modal({backdrop:'static',keyboard:false, show:true});
     // this.router.navigate(['/contract']);
  }

  // async fetchContractTemplateDetail (id) {
  //   if (id) {
  //     let temp = await this.apiService.getContractTemplateDetail(id);
  //     // temp[0].services.forEach(item => {
  //     //   let id = item.service_item._id;
  //     //   item.service_item = id;
  //     // });
  //     this.contract = temp;
  //     let plan_id = temp.plan._id;
  //     this.contract.plan_id = plan_id;
  //   }
  // }

  // async fetchDefaultTemplateDetail (id) {
  //   try {
  //     let defaultContract = await this.apiService.getContractTemplateDetail(id);
  //     // this.contract.name = defaultContract.name;
  //     defaultContract.services.forEach(item => {
  //       this.contract.services.push({
  //         services: {
  //           id: item.id,
  //           name: item.name,
  //           specialty: item.specialty
  //         },
  //         agree_fee: item.agree_fee,
  //         copay_unit: item.copay_unit,
  //         copay: item.copay
  //       })
  //     });
  //     // this.contract.services = defaultContract.services;
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

}
