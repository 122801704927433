import { Component, OnInit } from '@angular/core';
import { ANSWER_TYPE, Question, BIND_TYPE } from '../models/questions/Question';
import { Select2OptionData } from 'ng-select2';
import { ApiService } from '../services/api-service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../services/local-storage.service';
import { ROLE } from '../models/User';
import { QuestionControlService } from '../services/question-control.service';
import { PreapprovalQuestion } from '../models/PreapprovalQuestion';

@Component({
  selector: 'app-edit-preapproval-template',
  templateUrl: './edit-preapproval-template.component.html',
  styleUrls: ['./edit-preapproval-template.component.scss']
})
export class EditPreapprovalTemplateComponent implements OnInit {
  private translatedText;

  payorId: string = null;
  payorName: string = null;
  payorList: Map<any, any> = new Map();

  serviceOptions = {};
  diagnosisOptions = {};
  dynamicQuestions: PreapprovalQuestion[] = [];
  questions: Question<any>[];

  validate: boolean = true;
  validateNewQuestion: boolean;
  validateAddOption: boolean;

  bindTypeOptions: Select2OptionData[] = [];
  answerTypeOptions: Select2OptionData[] = [];
  preapprovalFieldOptions: Select2OptionData[] = [];

  loading: boolean;
  submitLoading: boolean;
  validateError: string;
  templateSubmitSuccess: boolean;
  templateSubmitError: string;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private storage: LocalStorageService, private translate: TranslateService, private questionService: QuestionControlService) {
    this.payorId = this.route.snapshot.params.id;
    this.translate.get([
      'preapproval.empty_field_error_msg',
      'preapproval.empty_option_error_msg'
    ]).subscribe(res => this.translatedText = res);
    
    this.answerTypeOptions = Object.keys(ANSWER_TYPE).map(key => {
      return { id: key, text: ANSWER_TYPE[key] };
    });
    this.bindTypeOptions = Object.keys(BIND_TYPE).map(key => {
      return { id: key, text: BIND_TYPE[key] };
    });
  }

  async ngOnInit() {
    try {
      this.loading = true;
      let id = this.payorId === 'default'? null : this.payorId;
      let user = await this.storage.getUserProfile();

      if (user.role === ROLE.payor) {
        this.payorName = user.detail.name;
      } else {
        if (id) {
          await this.fetchPayors();
          this.payorName = this.payorList.get(this.payorId);
        } else {
          this.payorName = this.payorId.replace(/./, x => x.toUpperCase());
        }
      }
      if (id) {
        await this.fetchPreapprovalFields(id);
      }
      await this.fetchQuestions(id);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async fetchPreapprovalFields(payorId) {
    try {
      let fields = <any[]> await this.apiService.getPreapprovalFieldsMapping(payorId);
      this.preapprovalFieldOptions = fields.map(item => {
        return {
          id: item.id,
          text: item.field_name
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  async fetchQuestions(payorId) {
    try {
      this.dynamicQuestions = await this.apiService.getPreapprovalQuestions(payorId);
    } catch (err) {
      console.log(err);
    }
  }

  async fetchPayors() {
    try {
      let res = await this.apiService.getPayors();
      this.payorList = new Map(res.map(item => [item.id, item.name]));
    } catch (err) {
      console.log(err);
    }
  }

  async saveTemplate() {
    this.templateSubmitError = "",
    this.templateSubmitSuccess = false;
    if (!this.validateForm())
      return;
    
    this.submitLoading = true;
    this.dynamicQuestions.forEach((item, key) => (item.order = key + 1));
    try {
      let id = (this.payorId === 'default')? null : this.payorId;
      await this.apiService.updatePreapprovalQuestions(id, this.dynamicQuestions);
      this.templateSubmitSuccess = true;
    } catch (err) {
      console.log(err);
      this.templateSubmitError = err.error.error_messages;
    } finally {
      this.submitLoading = false;
    }
  }

  addOption(i) {
    let len = this.dynamicQuestions[i].options.length;
    this.validateAddOption = false;
    if (len === 0 || (this.dynamicQuestions[i].options[len-1].id && this.dynamicQuestions[i].options[len-1].text)) {
      this.dynamicQuestions[i].options.push({
        id: null,
        text: null
      });
      this.resetValidate();
    }
  }

  addQuestion() {
    let len = this.dynamicQuestions.length;
    this.validateNewQuestion = false;
    if (len === 0 || this.validateQuestion(len-1)) {
      this.dynamicQuestions.push(this.newQuestion());
      this.resetValidate();
      this.setQuestionNo();
    }
  }

  addSubQuestion(i) {
    this.validateNewQuestion = false;
    do { i++; } while (this.dynamicQuestions[i] && this.dynamicQuestions[i].subQuestion)
    if (this.validateQuestion(i - 1)) {
      let question = this.newQuestion();
      question.subQuestion = true;
      this.dynamicQuestions.splice((i), 0, question);
      this.resetValidate();
      this.setQuestionNo();
    }
  }

  newQuestion() {
    return {
      id: null,
      answer: null,
      order: null,
      question: null,
      question_no: null,
      type: null,
      options: [],
      required: false,
      field_mapping_id: null,
      subQuestion: false,
      bind_option: null
    };
  }

  removeOption(i, j) {
    this.dynamicQuestions[i].options.splice(j, 1);
    this.resetValidate();
  }

  removeQuestion(i) {
    this.dynamicQuestions.splice(i, 1);
    this.setQuestionNo();
  }

  validateQuestion(i) {
    return (this.dynamicQuestions[i].question && this.dynamicQuestions[i].type);  //this.dynamicQuestions[i].insurer_field_id
  }

  validateForm() {
    this.validate = false;
    this.templateSubmitSuccess = false;
    this.validateError = "";

    for (let i = 0; i < this.dynamicQuestions.length; i++) {
      let questionNo = this.dynamicQuestions[i].question_no;
      if (!this.validateQuestion(i)) {
        this.validateError = 'Question ' + questionNo + ': ' + this.translatedText['preapproval.empty_field_error_msg'];
        return false;
      }    
      if (ANSWER_TYPE[this.dynamicQuestions[i].type] === ANSWER_TYPE.DROPDOWN) {
        if (this.dynamicQuestions[i].options && this.dynamicQuestions[i].options.length > 0) {
          for (let j = 0 ; j < this.dynamicQuestions[i].options.length ; j++) {
            if (!this.dynamicQuestions[i].options[j].id || !this.dynamicQuestions[i].options[j].text) {
              this.validateError = 'Question ' + questionNo + ': ' + this.translatedText['preapproval.empty_field_error_msg'];
              return false;
            }
          }
        } else {
          this.validateError = 'Question ' + questionNo + ': ' + this.translatedText['preapproval.empty_option_error_msg'];
          return false;
        }
      }
    }
    return true;
  }

  onChangeAnswerType(event, i) {
    this.dynamicQuestions[i].options = [];
    this.dynamicQuestions[i].bind_option = null;
    if (!this.dynamicQuestions[i].subQuestion) {
      this.removeSubQuestion(i);
    }
  }

  onChangeBindOption(event, i) {
    if (event.value === 'NONE') {
      this.removeSubQuestion(i);
    }
  }

  removeSubQuestion(i) {
    while (this.dynamicQuestions[i+1] && this.dynamicQuestions[i+1].subQuestion) {
      this.dynamicQuestions.splice(i+1, 1);
    }
    this.setQuestionNo();
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

  previewQuestions() {
    if (!this.validateForm())
      return;
    this.dynamicQuestions.forEach((item, key) => {
      item.order = key + 1;
      item.question_no = item.subQuestion? item.order + 0.1 : item.order;
    });
    this.setQuestionNo();
    this.questions = this.questionService.getPreapprovalFormQuestions(this.dynamicQuestions);
  }

  setQuestionNo() {
    for (let i = 0; i < this.dynamicQuestions.length; i++) {
      let item = this.dynamicQuestions[i];
      item.order = i + 1;
      if (i === 0) {
        item.question_no = item.order;
      } else {
        let lastQuestionNo = this.dynamicQuestions[i-1].question_no;
        item.question_no = item.subQuestion? lastQuestionNo + 0.1 : (Number.isInteger(lastQuestionNo)? lastQuestionNo + 1 : Math.ceil(lastQuestionNo));
      }
    }
  }

  resetValidate() {
    this.validate = true;
    this.validateNewQuestion = true;
    this.validateAddOption = true;
    this.validateError = "";
  }
}
