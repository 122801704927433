import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';
import { Offer, OFFER_STATUS } from '../models/Offer';
import { LocalStorageService } from '../services/local-storage.service';
import { ROLE, User } from '../models/User';
import { ActivatedRoute } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Plan } from '../models/Plan';

@Component({
  selector: 'offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {
  offers: Offer[] = [];
  doctorList: string[] = [];
  contractList: string[] = [];
  loading: boolean;
  mode: OFFER_STATUS;
  status = OFFER_STATUS;
  user: User;
  role = ROLE;
  payorList = [];
  payorOption: Select2OptionData[] = [];
  planList: Select2OptionData[] = [];

  searchOffer = {
    doctorName: null,
    planName: null,
    constractName: null,
    insurerId: null,
    clinicName: null
  }

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(private apiService: ApiService, private storage: LocalStorageService, route: ActivatedRoute) { 
    let planId = (new URL(window.location.href)).searchParams.get('plan_id');
    if (planId) {
      this.searchOffer.planName = planId;
    }
    switch(route.routeConfig.path) {
      case 'signed':
        this.mode = OFFER_STATUS.accept;
        break;
      case 'declined':
        this.mode = OFFER_STATUS.decline;
        break;
      case 'terminated':
        this.mode = OFFER_STATUS.terminated;
        break;
    }
  }

  ngOnInit() {
    this.getSignedContracts();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async getSignedContracts() {
    this.loading = true;
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          if(!this.user)
            this.user = await this.storage.getUserProfile();
          if(this.planList.length === 0)
            await this.fetchPlan();

          let res;
          switch(this.user.role) {
            case ROLE.payor:
              res = await this.apiService.getSignedOffer(null, this.mode, true, PER_PAGE, (dataTablesParameters.start/PER_PAGE + 1), this.searchOffer.doctorName, this.searchOffer.planName, this.searchOffer.clinicName);
              break;
            case ROLE.admin:
            case ROLE.operator:
              res = await this.apiService.adminGetOffer(null, this.mode, true, PER_PAGE, (dataTablesParameters.start/PER_PAGE + 1), this.searchOffer.insurerId, this.searchOffer.doctorName, this.searchOffer.planName, this.searchOffer.clinicName);
              break;
            case ROLE.doctor:
              if (this.mode === OFFER_STATUS.accept) {
                res = await this.apiService.doctorGetSigned(true, PER_PAGE, (dataTablesParameters.start/PER_PAGE + 1));
              }
              if (this.mode === OFFER_STATUS.terminated) {
                res = await this.apiService.doctorGetTerminated(true, PER_PAGE, (dataTablesParameters.start/PER_PAGE + 1));
              }
              break;
          }

          this.offers = res? res.data : [];
          this.offers.forEach(item => {
            if (item.doctor)
              this.doctorList.push(<string>item.doctor);
              this.contractList.push(<string>item.contract);
          });
          this.doctorList = this.doctorList.filter(function(item, pos, self) {
              return self.indexOf(item) == pos;
          })
          this.doctorList.sort();
          this.contractList = this.contractList.filter(function(item, pos, self) {
              return self.indexOf(item) == pos;
          })
          this.contractList.sort();
          
          this.loading = false;
          callback({
            recordsTotal: res? res.pagination.total:0,
            recordsFiltered: res? res.pagination.total:0,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }

    this.user = await this.storage.getUserProfile();
    if(this.user.role === ROLE.admin || this.user.role === ROLE.operator)
      this.fetchPayor();
  }

  removeDuplicates(myArr: any[], prop: string): any[] {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  async fetchPayor() {
    try {
      this.payorList = await this.apiService.getPayors(null, false);
      this.payorOption = this.payorList.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err)
    }
  }

  async refreshList() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  async fetchPlan() {
    if (!this.user)
      this.user = await this.storage.getUserProfile();
    let plan:Plan[];
    switch(this.user.role) {
      case ROLE.admin:
      case ROLE.operator:
        plan = <Plan[]>(await this.apiService.fetchALLPlan());
        break;
      case ROLE.payor:
        plan = <Plan[]>(await this.apiService.fetchPlan());
        break;
    }
    if(plan) {
      this.planList = plan.map(item => {
        return {
          id: item.name,
          text: item.name
        };
      });
    }
  }

}
