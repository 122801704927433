import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PLAN_TYPE } from '../models/Plan';
import { PER_PAGE } from '../constant/config';

@Component({
  selector: 'app-default-contract',
  templateUrl: './default-contract.component.html',
  styleUrls: ['./default-contract.component.scss']
})
export class DefaultContractComponent implements OnInit {

  defaultList = [];
  planList = [];
  loading;
  contractType = PLAN_TYPE;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.fetchContractTemplate();
  }

  async fetchContractTemplate() {
    try{
      this.loading = true;
      this.defaultList = await this.apiService.fetchDefaultContractTemplate(PER_PAGE);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

}
