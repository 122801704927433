import { Specialty } from "./Specialty";
import { Doctor } from "./Doctor";
import { OpeningHours } from "./OpeningHours";

export interface Clinic {
    id: string,
    qr_code: string,
    latitude: number,
    longitude: number,
    name: string,
    zh_hant_name: string,
    zh_hans_name: string,
    address_one: string,
    address_two: string,
    zh_hant_address_one: string,
    zh_hant_address_two: string,
    zh_hans_address_one: string,
    zh_hans_address_two: string,
    type: string,
    status: string,
    phone_one: string,
    phone_two: string,
    fax: string,
    email: string,
    address_code: string,
    token: string,
    is_open: boolean,
    is_connect: boolean,
    district: {
        id: number,
        name: string,
        zh_hant_name: string,
        zh_hans_name: string,
        latitude: number,
        longitude: number
    },
    bc_id: string,
    bc_pw: string,
    doctor: Doctor[],
    opening_hours: OpeningHours[],
    alipay_pid: string,
    alipay_key: string,
    clinic_solution_version: {
        build_num: string
        clinic_id: string
        ftp_url: string
        version_num: string,
        cs_api_version: string,
        cs_api_ftp_url: string
    },
    created_at: string
    created_by: string
    cs_ip: string
    cs_port: string,
    updated_by: string,
    websocket_ip: string, 
    websocket_max_connection: string, 
    clinic_management_system: { 
        id: string, 
        name: string
    }
}

export enum STATUS {
    active = "Active",
    inactive = "Inactive",
    activehidden = "Active But Hidden"
}

export enum TYPE {
    clinc = 'Clinic',
    facility = 'Facility'
}