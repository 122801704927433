import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-update-info',
  templateUrl: './update-info.component.html',
  styleUrls: ['./update-info.component.scss']
})
export class UpdateInfoComponent implements OnInit {
  @Input ('createdBy') createdBy: string;
  @Input ('createdAt') createdAt: string;
  @Input ('createdByRole') createdByRole: string;
  @Input ('updatedBy') updatedBy: string;
  @Input ('updatedAt') updatedAt: string;
  @Input ('updatedByRole') updatedByRole: string;
  @Input ('loading') loading: boolean;

  constructor() { }

  ngOnInit() {
  }

}
