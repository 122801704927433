import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPlan'
})
export class FilterPlanPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || args === '-1') {
      return value;
    }
    
    let result = [];
    value.forEach(item => {
      if(item.plan.id.toString() === args)
        result.push(item);
    });

    return result;
  }

}
