import { District } from './../models/District';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Clinic, STATUS, TYPE } from '../models/Clinic';
import { WEEKDAY, OpeningHours } from '../models/OpeningHours';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Select2OptionData } from 'ng-select2';
import { FetchDoctorsResponse } from '../response/FetchDoctorsResponse';
import { Doctor } from '../models/Doctor';
import { Specialty } from '../models/Specialty';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CMS_TYPE } from '../models/CMS';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-add-clinic',
  templateUrl: './add-clinic.component.html',
  styleUrls: ['./add-clinic.component.scss']
})
export class AddClinicComponent implements OnInit {
  form: FormGroup;
  validate: boolean = true;

  clinicId;
  clinicDetail: Clinic = {
    id: null,
    qr_code: null,
    latitude: null,
    longitude: null,
    name: null,
    zh_hant_name: null,
    zh_hans_name: null,
    address_one: null,
    address_two: null,
    zh_hant_address_one: null,
    zh_hant_address_two: null,
    zh_hans_address_one: null,
    zh_hans_address_two: null,
    type: null,
    status: null,
    phone_one: null,
    phone_two: null,
    fax: null,
    email: null,
    address_code: null,
    token: null,
    is_open: null,
    is_connect: null,
    district: {
        id: null,
        name: null,
        zh_hant_name: null,
        zh_hans_name: null,
        latitude: null,
        longitude: null
    },
    bc_id: null,
    bc_pw: null,
    doctor: null,
    opening_hours: [],
    alipay_pid: null,
    alipay_key: null,
    clinic_solution_version: {
        build_num: null,
        clinic_id: null,
        ftp_url: null,
        version_num: null,
        cs_api_version: null,
        cs_api_ftp_url: null
    },
    created_at: null,
    created_by: null,
    cs_ip: null,
    cs_port: null,
    updated_by: null,
    websocket_ip: null, 
    websocket_max_connection: null,
    clinic_management_system: {
      id: null,
      name: null
    }
  };
  districtList: District[] = [];
  districtOptions: Select2OptionData[] = [];
  clinicManagementList = [];
  apiUrlList = [];
  csUrlList = [];
  dtOptions: DataTables.Settings;
  status = STATUS;
  clinic_type = TYPE;
  weekday = WEEKDAY;

  modalLoading;
  loading;
  translateText;
  errorMsg;
  submitSuccess: boolean;

  searchDoctor = {
    name: null,
    nc_user: false,
    specialty_id: null,
    network_group: null
  };
  doctors: Doctor[] = [];
  customPerPage: number = 8;
  specialties: Specialty[] = [];
  specialtyOptions: Select2OptionData[] = [];

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  qrcodeUrl;

  constructor(private apiService:ApiService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private translate: TranslateService, private formBuilder: FormBuilder) {
    this.clinicId = this.route.snapshot.paramMap.get("id");
    translate.get([
      'success',
      'submit_error',
      'clinic.copy_message'
    ]).subscribe((text:string[]) => {
      this.translateText = text;
    });
    let env = `-${environment.env}`;
    if (environment.env === 'prod')
      env = '';

    // TODO: 临时修改，从download修改为web-portal
    this.qrcodeUrl = 'https://app' + env + '.healshealthcare.com/web-portal?clinic_id='
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    this.fetchDistrict();
    this.fetchDoctorList();
    this.fetchSpecialty();
    this.fetchCMS();
    this.fetchApiFtpUrl();
    this.fetchCSFtpUrl();

    if (this.clinicId) {
      await this.fetchClinic(this.clinicId);
    } else {
      this.form = this.getForm(null);
    }
    this.formOnChange();
  }

  getCMSId(name) {
    let cms = this.clinicManagementList;
    for (let i = 0; i < cms.length; i++) {
      if (cms[i].name === name) return cms[i].id;
    }
  }

  formOnChange() {
    let viewMode = false;
    if (!viewMode && this.form) {
      this.form.get('clinic_management_system_id').valueChanges.subscribe((val) => {
        this.formValidation('clinic_management_system_id', val);
      });
    }
  }

  formValidation(key, value) {
    switch(key) {
      case 'clinic_management_system_id':
        // TODO: Clarify the required fields for different cms type. Temp enable all fields on selection.
        if (value == this.getCMSId(CMS_TYPE.CS) || value == this.getCMSId(CMS_TYPE.CLINISYS) || this.getCMSId(CMS_TYPE.WINMED) || this.getCMSId(CMS_TYPE.CLINIC_ASSIST)) { 
          this.setField('cs_protocal', false, null, null);
          this.setField('cs_ip', false, "", null);
          this.setField('cs_port', false, "", null);
          this.setField('cs_version_num', false, null, null);
          this.setField('cs_build_num', false, "", null);
          this.setField('cs_ftp_url', false, "", null);
          this.setField('cs_api_version', false, "", null);
          this.setField('cs_api_ftp_url', false, "", null);
        } else {
          this.setField('cs_protocal', true, null, null);
          this.setField('cs_ip', true, "", null);
          this.setField('cs_port', true, "", null);
          this.setField('cs_version_num', true, null, null);
          this.setField('cs_build_num', true, "", null);
          this.setField('cs_ftp_url', true, "", null);
          this.setField('cs_api_version', true, "", null);
          this.setField('cs_api_ftp_url', true, "", null);
        }
        break;
    }
  }

  setField(field: string, disabled: boolean, value: any, validator: any[]) {
    let control = this.form.controls[field];
    if (disabled)
      control.disable();
    else
      control.enable();
    control.setValue(value);
    control.setValidators(validator);
    control.updateValueAndValidity();
  }

  async fetchClinic(id) {
    try {
      this.loading = true;
      this.clinicDetail = await this.apiService.getClinic(id);
      this.form = this.getForm(this.clinicDetail);
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  async fetchDistrict () {
    try {
      this.districtList = <District[]>await this.apiService.getDistrict();
      this.districtOptions = this.districtList.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });
    } catch (err) {
      console.log(err)
    }
  }

  async fetchCMS () {
    try {
      this.clinicManagementList = await this.apiService.getManagementSystem();
    } catch (err) {
      console.log(err)
    }
  }

  async fetchApiFtpUrl () {
    try {
      this.apiUrlList = await this.apiService.getCSApiFtpUrl("CS");
    } catch (err) {
      console.log(err)
    }
  }

  async fetchCSFtpUrl () {
    try {
      this.csUrlList = await this.apiService.getCSApiFtpUrl("CS_API");
    } catch (err) {
      console.log(err)
    }
  }

  newTime() {
    this.clinicDetail.opening_hours.push({
      weekday: null,
      session: null,
      by_appointment: false,
      start: null,
      end: null,
    });
  }
  
  removeTime(i) {
    this.clinicDetail.opening_hours.splice(i, 1);
  }

  async submit() {
    this.validate = false;
    this.errorMsg = null;
    this.submitSuccess = false;
    
    if(this.clinicDetail.latitude){
      this.form.get('latitude').setValue(this.clinicDetail.latitude);
    }
    if(this.clinicDetail.longitude){
      this.form.get('longitude').setValue(this.clinicDetail.longitude);
    }
    if(this.clinicDetail.address_code){
      this.form.get('address_code').setValue(this.clinicDetail.address_code);
    }

    console.log('this.form.value===>',this.form.value);
    console.log('this.form.valid===>',this.form.valid);

    if (!this.form.valid) return;
    try {
      this.loading = true;
      let formData = this.form.getRawValue();
      formData.cs_ip = formData.cs_protocal + formData.cs_ip;
      delete formData.cs_protocal;

      let open_hour:OpeningHours[] = [];
      this.clinicDetail.opening_hours.forEach( item => {
        if(item.by_appointment) {
          open_hour.push({
            weekday: item.weekday,
            by_appointment: true,
            session: item.session,
            start: "00:00",
            end: "00:00"
          })
        } else {
          open_hour.push(item)
        }
      })
      formData.opening_hours = open_hour;
      let res = await this.apiService.addClinic(formData);
      this.submitSuccess = true;
      if(this.clinicDetail.id)
        this.fetchClinic(this.clinicDetail.id);
      else
        this.router.navigate(['/clinic/edit', res.clinic.id])
    } catch(err) {
      console.log(err)
      this.errorMsg = err.error.error_messages;
    } finally {
      this.loading = false;
    }
  }

  async fetchDoctorList() {
    this.modalLoading = true;
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: this.customPerPage,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.modalLoading = true;
          let doctors = <FetchDoctorsResponse>await this.apiService.adminGetDoctorList(null, this.searchDoctor.specialty_id, this.searchDoctor.name, this.searchDoctor.nc_user, true, this.customPerPage, dataTablesParameters.start/this.customPerPage + 1);
          this.doctors = doctors.data;
          this.modalLoading = false;

          callback({
            recordsTotal: doctors.pagination.total,
            recordsFiltered: doctors.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.modalLoading = false;
    }
  }

  async fetchSpecialty() {
    try {
      this.specialties = <Specialty[]>await this.apiService.getSpecialty(false);
      this.specialtyOptions = this.specialties.map(item => {
        return {
          id: item.id, 
          text: item.name
        };
      });
    } catch (err) {
      console.log(err)
    }
  }

  async filterDoctor() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  addDoctorClinic(doctorId) {
    $('#modal-link-doctor').modal("hide");
    this.router.navigate(['/doctor-clinic/add'], { queryParams: {clinicId: this.clinicId, doctorId: doctorId}} );
  }

  setLatLng(latLong: {latitude: number, longitude: number}) {
    this.clinicDetail.latitude = latLong.latitude;
    this.clinicDetail.longitude = latLong.longitude;
  }

  validateRow(key) {
    let validate = this.form && (this.form.controls[key].disabled || this.form.controls[key].valid);
    return (this.validate || validate);
  }

  getForm(data) {
    data = data? data : {};
    // let csSelected = data && data.clinic_management_system && (data.clinic_management_system.id === this.getCMSId(CMS_TYPE.CS) || data.clinic_management_system.id === this.getCMSId(CMS_TYPE.CLINISYS))? true : false;
    let csSelected = data && data.clinic_management_system? true : false;

    let index = data.cs_ip? (data.cs_ip.lastIndexOf('/') + 1) : null;
    let csProtocal = data.cs_ip? data.cs_ip.substr(0, index) : null;
    let csIp = data.cs_ip? data.cs_ip.substr(index) : null;

    return this.formBuilder.group({
      id: [{value: data.id, disabled: true}],
      qr_code: [{value: data.qr_code, disabled: false}],
      en_name: [{value: data.name, disabled: false}, Validators.required],
      hant_name: [{value: data.zh_hant_name, disabled: false}, Validators.required],
      hans_name: [{value: data.zh_hans_name, disabled: false}, Validators.required],
      en_address_one: [{value: data.address_one, disabled: false}],
      hant_address_one: [{value: data.zh_hant_address_one, disabled: false}],
      hans_address_one: [{value: data.zh_hans_address_one, disabled: false}],
      type: [{value: data.type? data.type:null, disabled: false}, Validators.required],
      status: [{value: data.status? data.status:null, disabled: false}, Validators.required],
      latitude: [{value: data.latitude, disabled: false}, Validators.required],
      longitude: [{value: data.longitude, disabled: false}, Validators.required],
      phone_one: [{value: data.phone_one, disabled: false}],
      phone_two: [{value: data.phone_two, disabled: false}],
      fax: [{value: data.fax, disabled: false}],
      email: [{value: data.email, disabled: false}],
      address_code: [{value: data.address_code, disabled: false}, Validators.required],
      district_id:[{value: (data.district? data.district.id:null), disabled: false}, Validators.required],
      alipay_pid: [{value: data.alipay_pid, disabled: false}],
      alipay_key: [{value: data.alipay_key, disabled: false}],
      clinic_management_system_id: [{value: (data.clinic_management_system? data.clinic_management_system.id:null), disabled: false}, Validators.required],
      cs_protocal: [{value: csProtocal, disabled: !csSelected}],
      cs_ip: [{value: csIp, disabled: !csSelected}],
      cs_port: [{value: data.cs_port, disabled: !csSelected}],
      cs_version_num: [{value: data.clinic_solution_version? data.clinic_solution_version.version_num : null, disabled: !csSelected}],
      cs_build_num: [{value: data.clinic_solution_version? data.clinic_solution_version.build_num : null, disabled: !csSelected}],
      cs_ftp_url: [{value: data.clinic_solution_version? data.clinic_solution_version.ftp_url : null, disabled: !csSelected}],
      cs_api_version: [{value: data.clinic_solution_version? data.clinic_solution_version.cs_api_version : null, disabled: !csSelected}],
      cs_api_ftp_url: [{value: data.clinic_solution_version? data.clinic_solution_version.cs_api_ftp_url : null, disabled: !csSelected}],
    });
  }

  copyToClipboard(val) {
    let listener = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', (val));
        e.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    this.toastr.success(this.translateText['clinic.copy_message'], null, {
      positionClass: "toast-bottom-right"
    });
  }
}
