import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  loading;
  changePassword = {
    old_password: '',
    password: '',
    password_confirmation: ''
  }
  submitSuccess = false;
  errorMsg;
  submitBtn;


  constructor(private apiService: ApiService) { }

  ngOnInit() {
  } 

  async update() {
    this.submitBtn = true;
    this.loading = true;
    this.submitSuccess = false;
    this.errorMsg = '';
    if(this.changePassword.old_password && this.changePassword.password && this.changePassword.password_confirmation) {
      try {
        await this.apiService.changePassword(this.changePassword);
        this.submitBtn = false;
        this.submitSuccess = true;
        this.changePassword.old_password = '';
        this.changePassword.password = '';
        this.changePassword.password_confirmation = '';
      } catch (err) {
        console.log(err)
        this.errorMsg = err.error.error_messages;
      } finally {
        this.loading = false;
      }
    } 
  }

}
