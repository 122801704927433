import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';

@Component({
  selector: 'app-default-plan',
  templateUrl: './default-plan.component.html',
  styleUrls: ['./default-plan.component.scss']
})
export class DefaultPlanComponent implements OnInit {
  
  plans = [];
  loading = false;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.fetchDefaultPlanList();
  }

  async fetchDefaultPlanList() {
    try {
      this.loading = true;
      this.plans = await this.apiService.fetchDefaultPlan(PER_PAGE);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

}
