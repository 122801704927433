import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';
import { PREAPPROVAL_STATUS } from '../models/Preapproval';
import { Select2OptionData } from 'ng-select2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Component({
  selector: 'app-manage-preapproval',
  templateUrl: './manage-preapproval.component.html',
  styleUrls: ['./manage-preapproval.component.scss']
})
export class ManagePreapprovalComponent implements OnInit {

  searchClinic = {};
  loading: boolean = false;
  preapprovalList: any[] = [];
  filter: any = {};
  statusOptions: Select2OptionData[] = [];
  payorOptions: Select2OptionData[] = [];
  role: string = null;

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings;

  constructor(private apiService: ApiService, private storage: LocalStorageService) { }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    this.resetFilter();
    this.getDropdownOptions();
    await this.getPreapprovals();
    let user = await this.storage.getUserProfile();
    this.role = user.role;
  }

  async getDropdownOptions() {
    this.statusOptions = Object.values(PREAPPROVAL_STATUS).map(value => {
      return {
        id: value,
        text: value
      }
    });

    let res = await this.apiService.getPayors(null, false);
    this.payorOptions = res.map(item => {
      return {
        id: item.id.toString(),
        text: item.name
      };
    });
  }

  async getPreapprovals() {
    try {
      this.loading = true;
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let res = await this.apiService.getPreapproval(null, this.filter.reference_no, this.filter.status, this.filter.doctor_name, this.filter.clinic_name, this.filter.patient_name, true, PER_PAGE, (dataTablesParameters.start/PER_PAGE + 1));  
          this.preapprovalList = res.data;
          this.loading = false;

          callback({
            recordsTotal: res.pagination.total,
            recordsFiltered: res.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  async filterPreapprovals() {   
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  resetFilter() {
    this.filter = {
      status: null,
      clinic_name: null,
      doctor_name: null,
      patient_name: null,
      reference_no: null,
      pa_token: null,
      doctor_id: null,
      clinic_id: null,
      // insurer_id: null,
    };
  }
}