import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { Router } from '@angular/router';
import { User, ROLE } from '../models/User';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {

  user: User;
  role = ROLE;

  constructor(private storage: LocalStorageService, private router: Router) { }

  async ngOnInit() {
    this.user = await this.storage.getUserProfile();
  }

  // logout() {
  //   this.storage.setLogout();
  //   this.router.navigate(['/login']);
  // }



}
