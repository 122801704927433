import { Plan } from 'src/app/models/Plan';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { ActivatedRoute, Router } from '@angular/router';
import { PER_PAGE } from '../constant/config';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss']
})
export class EditTemplateComponent implements OnInit {
  agreeFee:{
    item: String,
    agree_fee: number,
    copay_unit,
    copay: number
  }[] = [];

  contract;
  planList: Plan[];

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  async ngOnInit() {
    await this.fetchPlan();
    this.route.url.subscribe((u) => {
      this.fetchContractTemplateDetail(this.route.snapshot.params.id);
    });
  }

  async fetchPlan() {
    this.planList = <Plan[]>await this.apiService.fetchPlan(false, PER_PAGE);
  }

  async fetchContractTemplateDetail (id) {
    this.contract = await this.apiService.getContractTemplateDetail(id);
    // this.contract.services.forEach(item => {
    //   let id = item.service_item._id;
    //   item.service_item = id;
    // });
    this.agreeFee = this.contract.services;
  }

  async submit(item) {
    this.contract.services = item;
    let id = this.contract.plan._id;
    this.contract.plan = id;
    await this.apiService.addContractTemplate(this.contract);
    this.router.navigate(['/contract']);
  }
}
