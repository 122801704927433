import { LocalStorageService } from './../../services/local-storage.service';
import { FetchPlansResponse } from './../../response/FetchPlansResponse';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api-service';
import { PER_PAGE } from 'src/app/constant/config';
import { Plan } from 'src/app/models/Plan';
import { ROLE, User } from 'src/app/models/User';
import { Select2OptionData } from 'ng-select2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'plan-table',
  templateUrl: './plan-table.component.html',
  styleUrls: ['./plan-table.component.scss']
})
export class PlanTableComponent implements OnInit {

  plans: Plan[] = [];
  payorList: Select2OptionData[] = [];
  totalNum: number = 0;
  planDoc = {
    plan_id: -1,
    insurer_id: -1,
    documents: []
  }
  selectedPlan;
  allDocList: any = [];
  exceedFile: any = [];
  planClasses: any = [];
  errorMsg: string = '';
  planClassErrMsg: string = '';
  planClassId: string = null;
  planListOptions: Select2OptionData[] = [];

  submitSuccess = false;
  submitPlanClassSuccess: boolean = false;

  loading: boolean;
  modalLoading: boolean;
  user: User;
  searchOption = {
    payor_id: null,
    plan_name: null
  }
  role = ROLE;

  dtOptions: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(private apiService: ApiService, private storage: LocalStorageService) {
  }

  private async getUser() {
    this.user = await this.storage.getUserProfile();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        processing: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          if (!this.user)
            await this.getUser();
          let res: FetchPlansResponse;
          if (this.user.role === ROLE.admin || this.user.role === ROLE.operator) {
            if(this.payorList.length === 0) {
              let payor = await this.apiService.getPayors(null, false);
              this.payorList = payor.map(item => {
                return {
                  id: item.id.toString(),
                  text: item.name
                };
              });
            }
            res = <FetchPlansResponse>(await this.apiService.fetchALLPlan(true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1, this.searchOption.payor_id, this.searchOption.plan_name));
          } else
            res = <FetchPlansResponse>(await this.apiService.fetchPlan(true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1, this.searchOption.plan_name));
            
          this.plans = res.data;
          this.totalNum = res.pagination.total;
          this.loading = false;
          callback({
            recordsTotal: this.totalNum,
            recordsFiltered: this.totalNum,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  async planDocModal(plan) {
    this.setPlan(plan);
    this.planDoc.plan_id = plan.id;
    this.planDoc.insurer_id = plan.insurer.id;
    this.planDoc.documents = [];
    try {
      this.exceedFile = await this.apiService.getDoc(plan.insurer.id, plan.id)
    } catch(err){ 
      console.log(err)
    }
    this.submitSuccess = false;
    this.errorMsg = '';
  }

  setPlan(plan) {
    this.selectedPlan = plan;
  }

  onFileChange(event: DataTransfer, i: number){
    this.planDoc.documents[i].file = event.files[0];
    this.planDoc.documents[i].name = event.files[0].name.split(".")[0];
  }

  addNewDoc(){
    this.planDoc.documents.push({
      id: null,
      type: '',
      name: '',
      file: null
    })
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

  async uploadDoc() {
    this.submitSuccess = false;
    this.errorMsg = '';
    try {
      this.modalLoading = true;
      await this.apiService.uploadDoc(this.planDoc);
      this.planDoc.documents.forEach(item => {
        this.exceedFile.push(item)
      })
      this.planDoc.documents = [];
      this.submitSuccess = true;
    } catch(err) {
      console.log(err)
      this.errorMsg = err.error.error_messages;
    } finally {
      this.modalLoading = false;
    }
  }

  async filterPlan() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }

  resetPlanClassModal(plan) {
    this.setPlan(plan);
    this.planClasses = [];
    this.planClassId = null;
    this.planClassErrMsg = '';
    this.submitPlanClassSuccess = false;
  }

  async fetchPlanClassList(plan) {
    try {
      this.modalLoading = true;
      this.resetPlanClassModal(plan);
      this.planClasses = await this.apiService.getPlanClass(this.selectedPlan.id);

      let res = await this.apiService.getPlanClass(null, this.selectedPlan.insurer.id);
      this.planListOptions = res.map(item => {
        return {
          id: item.id.toString(),
          text: item.name
        };
      });      
    } catch (err) {
      console.log(err)
    } finally {
      this.modalLoading = false;
    }
  }
  
  async addPlanClass() {
    if (!this.planClassId) return;
    try {
      this.modalLoading = true;      
      await this.apiService.assignPlanClass(this.selectedPlan.id, this.planClassId);

      let index = this.planListOptions.findIndex(x => x.id == this.planClassId);
      this.planListOptions.splice(index, 1);
      this.resetPlanClassModal(this.selectedPlan);
      this.planClasses = await this.apiService.getPlanClass(this.selectedPlan.id);  // update list

      this.submitPlanClassSuccess = true;
    } catch(err) {
      console.log(err)
      this.planClassErrMsg = err.error.error_messages;
    } finally {
      this.modalLoading = false;
    }
  }
}
