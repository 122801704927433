import { Diagnosis } from './../models/Diagnosis';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';
import { Specialty } from '../models/Specialty';
import { FetchDiagnosisResponse } from '../response/FetchDiagnosisResponse';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.scss']
})
export class DiagnosisComponent implements OnInit {
  
  newDiagnosis:Diagnosis  = {
    code: '',
    name: '',
    scheme: '',
    chinese_name: '',
    specialties: []
  };
  specialtyList: Specialty[] = [];
  diagnosisModel: Diagnosis;
  diagnosisList: Diagnosis[] = [];
  loading: boolean;
  addBtnClicked: boolean = false;
  addSuccess: boolean;
  addLoading: boolean;

  editBtnClicked: boolean = false;
  editSuccess: boolean;
  editLoading: boolean;

  addErrorMsg: string;
  editErrorMsg: string;
  importErrorMsg: string;

  diagnosisFile: File;
  importSuccess: boolean;
  importLoading: boolean;

  searchDiagnosis = {
    name: null
  }

  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(private apiService: ApiService) { }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  async ngOnInit() {
    await this.fetchDiagnosis();
    await this.fetchSpecialty()
  }

  async fetchDiagnosis() {
    this.loading = true;
    try {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.loading = true;
          let response = <FetchDiagnosisResponse> await this.apiService.getDiagnosis(null, null, true, PER_PAGE, dataTablesParameters.start/PER_PAGE + 1, this.searchDiagnosis.name);
          this.diagnosisList = response.data;
          this.loading = false;

          callback({
            recordsTotal: response.pagination.total,
            recordsFiltered: response.pagination.total,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.loading = false;
    }
  }

  async fetchSpecialty() {
    try {
      this.specialtyList = <Specialty[]>await this.apiService.getSpecialty();
    } catch (err) {
      console.log(err)
    }
  }

  async addDiagnosis() {
    this.addBtnClicked = true;
    this.addErrorMsg = '';
    this.addSuccess = false;

    if (this.newDiagnosis.code.length > 0 && this.newDiagnosis.name.length > 0 && this.newDiagnosis.scheme.length > 0) {
      try {
        this.addLoading = true;
        let tags = [];
        this.newDiagnosis.specialties.forEach(item => {
          tags.push(item.id)
        });
        let diagnosis = await this.apiService.addDiagnosis(this.newDiagnosis.name, this.newDiagnosis.code, this.newDiagnosis.scheme, this.newDiagnosis.chinese_name, tags);
        // this.diagnosisList.push(diagnosis);
        this.refreshList();
        this.addSuccess = true;
        this.resetAddMember();
      } catch (err) {
        this.addErrorMsg = err.error.error_messages;
        console.error(err);
      } finally {
        this.addLoading = false;
      }
    }
  }

  async editModal(item: Diagnosis) {
    this.editSuccess = false;
    this.diagnosisModel = <Diagnosis> await this.apiService.getDiagnosis(item.id);
    // for(let i = 0; i < this.diagnosisModel.specialties.length; i++ ) {
    //   this.diagnosisModel.specialties[i].value = this.diagnosisModel.specialties[i].id;
    // }
  }

  async editDiagnosis() {
    this.editBtnClicked = true;
    this.editErrorMsg = '';
    this.editSuccess = false;
    console.log(this.diagnosisModel)

    if (this.diagnosisModel.code.length > 0 && this.diagnosisModel.name.length > 0 && this.diagnosisModel.scheme.length > 0) {
      try {
        let tags = [];
        this.diagnosisModel.specialties.forEach(item => {
          tags.push(item.id)
        });
        this.editLoading = true;
        await this.apiService.editDiagnosis(this.diagnosisModel.id, this.diagnosisModel.name, this.diagnosisModel.code, this.diagnosisModel.scheme, this.diagnosisModel.chinese_name, tags);
        let index = this.diagnosisList.findIndex(item => item.id === this.diagnosisModel.id);
        if (index !== -1)
          this.diagnosisList[index] = JSON.parse(JSON.stringify(this.diagnosisModel));
        this.editSuccess = true;
      } catch (err) {
        this.editErrorMsg = err.error.error_messages;
        console.error(err);
      } finally {
        this.editLoading = false;
      }
    }
  }

  private resetAddMember() {
    this.newDiagnosis = {
      code: '',
      name: '',
      scheme: '',
      chinese_name: '',
      specialties: []
    };
    this.addBtnClicked = false;
  }
  
  onFileChange(data: DataTransfer) {
    this.diagnosisFile = data.files[0];
  }

  async importDiagnosis() {
    this.importErrorMsg = '';
    this.importSuccess = false;

    if (this.diagnosisFile) {
      try {
        this.importLoading = true;
        await this.apiService.importFile(this.diagnosisFile, 'DIAGNOSIS', null);
        this.importSuccess = true;
      } catch (err) {
        this.importErrorMsg = err.error.error_messages;
        console.error(err);
      } finally {
        this.importLoading = false;
      }
    }
  }

  async refreshList() {
    let dtInstance = await this.dtElement.dtInstance;
    dtInstance.destroy();
    this.dtTrigger.next();
  }
}
