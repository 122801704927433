import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Select2OptionData } from 'ng-select2';
import { ApiService } from '../services/api-service';
import { PER_PAGE } from '../constant/config';
import { FetchDoctorClinicResponse } from '../response/FetchDoctorClinicResponse';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-network-group',
  templateUrl: './network-group.component.html',
  styleUrls: ['./network-group.component.scss']
})
export class NetworkGroupComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  viewProvidersDtOptions: DataTables.Settings;
  doctorClinicDtOptions: DataTables.Settings;

  checkedIds: number[] = [];
  doctorClinics: any[] = [];

  filterDoctorClinic: any;
  filterNetworkGroupProvider: any;

  providersCount: number = 0;

  newNetworkGroup: any;
  networkGroupIds: number[] = [];
  networkGroupList: Select2OptionData[] =  [];
  networkGroupProviders: any[] = [];
  payorOptions: Select2OptionData[] = [];

  selectedNetworkGroup: string = null;

  modalLoading: boolean = false;
  viewProvidersTableLoading: boolean = false;
  doctorClinicsTableLoading: boolean = false;

  submitError: string;
  submitSuccess: boolean = false;
  validate: boolean = true;
  
  translatedText: any[] = [];


  constructor(private apiService: ApiService, private toastr: ToastrService, private translate: TranslateService) { 
    this.translate.get([
			'error',
			'success',
			'error',
      'network_group.add_provider_success_msg',
      'network_group.remove_provider_success_msg'
		]).subscribe(res => this.translatedText = res);
  }

  ngOnInit() {
    this.resetModal();
    this.resetDoctorClinicFilter();
    this.resetNetworkGroupProviderFilter();

    this.fetchPayors();
    this.fetchDoctorClinics();
    this.fetchNetworkGroupList();
    this.fetchNetworkGroupProviders();
  }

  // add/remove provider
  async updateProvider(doctorClinicId?: number) {
    try {
      let successMsg;
      if (doctorClinicId) {
        if (!this.selectedNetworkGroup) return;
        await this.apiService.removeNetworkGroupProviders(this.selectedNetworkGroup, [doctorClinicId]);
        successMsg = this.translatedText['network_group.remove_provider_success_msg'];
      } else {
        if (!this.selectedNetworkGroup && this.checkedIds.length === 0) return;
        await this.apiService.addNetworkGroupProviders(this.selectedNetworkGroup, this.checkedIds);
        successMsg = this.translatedText['network_group.add_provider_success_msg'];
      }

      this.toastr.success(successMsg,this.translatedText['success'], {
        positionClass: "toast-bottom-full-width"
      });
      this.reloadDoctorClinicTable();
      this.reloadNetworkGroupProviderTable();
    } catch (err) {
      console.log(err);
      this.toastr.error( err.error.error_messages, this.translatedText['error'], {
        positionClass: "toast-bottom-full-width"
      });
    }
  }

  async createNetworkGroup() {
    this.submitError = "";
    this.validate = false;
    this.submitSuccess = false;

    if (this.newNetworkGroup.name) {
      try {
        this.submitError = "";
        this.modalLoading = true;
        await this.apiService.updateNetworkGroup(this.newNetworkGroup);
        this.fetchNetworkGroupList();
        this.submitSuccess = true;
      } catch (err) {
        console.log(err);
        this.submitError = err.error.error_messages;
      } finally {
        this.modalLoading = false;
      }
    }
  }

  async fetchPayors() {
    try {
      let res = await this.apiService.getPayors();
      this.payorOptions = res.map(item => {
        return {
          id: item.id,
          text: item.name
        };
      });
    } catch (err) {
      console.log(err)
    }
  }

  async fetchNetworkGroupProviders() {
    this.viewProvidersTableLoading = true;
    try {
      this.viewProvidersDtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.viewProvidersTableLoading = true;
          let res;
          if (this.selectedNetworkGroup) {
            res = <FetchDoctorClinicResponse> await this.apiService.getDoctorClinic(
                null, 
                this.filterNetworkGroupProvider.clinic_id, 
                this.filterNetworkGroupProvider.doctor_id,  
                [this.selectedNetworkGroup],
                this.filterNetworkGroupProvider.clinic_name,
                this.filterNetworkGroupProvider.doctor_name,
                true, 
                PER_PAGE, 
                dataTablesParameters.start/PER_PAGE + 1, 
              );
            this.networkGroupProviders = res.data;
            this.providersCount = res.pagination.total;
          } else {
            this.networkGroupProviders = [];
            this.providersCount = 0;
          }
          this.viewProvidersTableLoading = false;

          callback({
            recordsTotal: res? res.pagination.total : 0,
            recordsFiltered: res? res.pagination.total : 0,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.viewProvidersTableLoading = false;
    }
  }

  async fetchDoctorClinics() {
    this.doctorClinicsTableLoading = true;
    try {
      this.doctorClinicDtOptions = {
        pagingType: 'full_numbers',
        pageLength: PER_PAGE,
        serverSide: true,
        searching: false,
        ordering:  false,
        lengthChange: false,
        autoWidth: false,
        ajax: async (dataTablesParameters: any, callback) => {
          this.doctorClinicsTableLoading = true;
          let res;
          if (this.selectedNetworkGroup) {
            // let filterGroupIds = Object.assign([], this.networkGroupIds);
            // filterGroupIds.splice(filterGroupIds.indexOf(parseInt(this.selectedNetworkGroup)), 1);
            res = <FetchDoctorClinicResponse> await this.apiService.getDoctorClinic(
                null, 
                this.filterDoctorClinic.clinic_id, 
                this.filterDoctorClinic.doctor_id,  
                null,
                this.filterDoctorClinic.clinic_name,
                this.filterDoctorClinic.doctor_name,
                true, 
                PER_PAGE, 
                dataTablesParameters.start/PER_PAGE + 1, 
              );
  
            this.doctorClinics = res.data;
            this.doctorClinics.forEach(item => {
              item.checked = this.isChecked(item.id);
            });
          } else {
            this.doctorClinics = [];
          }
          this.doctorClinicsTableLoading = false;

          callback({
            recordsTotal: res? res.pagination.total : 0,
            recordsFiltered: res? res.pagination.total : 0,
            data: []
          });
        }
      };
    } catch (err) {
      console.log(err)
    } finally {
      this.doctorClinicsTableLoading = false;
    }
  }

  async fetchNetworkGroupList() {
    try {
      let res = <any[]> await this.apiService.getNetworkGroup();
      let options = [];
      res.forEach(item => {
        options.push({
          id: item.id.toString(),
          text: item.name
        });
        this.networkGroupIds.push(item.id);
      });
      this.networkGroupList = options;
    } catch (err) {
      console.log(err);
    }
  }
  
  isChecked(id) {
    return this.checkedIds.indexOf(id) >= 0 ? true : false;
  }

  rowCheckBoxChecked(e, id) {
    if (e.currentTarget.checked) {
        this.checkedIds.push(id);
    } else {
      this.checkedIds.splice(this.checkedIds.indexOf(id), 1);
    }
  }

  reloadNetworkGroupProviderTable() {
    $('#viewProvidersTable').DataTable().ajax.reload();
  }

  reloadDoctorClinicTable() {
    this.checkedIds = [];
    $('#doctorClinicsTable').DataTable().ajax.reload();
  }

  resetModal() {
    this.submitSuccess = false;
    this.validate = true;
    this.submitError = '';
    this.newNetworkGroup = {
      name: "",
      insurer_id: null
    };
  }

  resetNetworkGroupProviderFilter() {
    this.filterNetworkGroupProvider = {
      doctor_id: null,
      clinic_id: null,
      doctor_name: null,
      clinic_name: null
    };
  }

  resetDoctorClinicFilter() {
    this.filterDoctorClinic = {
      doctor_id: null,
      clinic_id: null,
      doctor_name: null,
      clinic_name: null
    };
  }

  viewNetworkGroupDetail(id) {
    this.checkedIds = [];
    this.selectedNetworkGroup = id;
    this.resetDoctorClinicFilter();
    this.resetNetworkGroupProviderFilter();
    this.reloadDoctorClinicTable();
    this.reloadNetworkGroupProviderTable();
  }
}
