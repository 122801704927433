import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api-service';
import { Router } from '@angular/router';
import { PLAN_TYPE } from '../models/Plan';
import { LocalStorageService } from '../services/local-storage.service';

@Component({
  selector: 'app-add-plan',
  templateUrl: './add-plan.component.html',
  styleUrls: ['./add-plan.component.scss']
})
export class AddPlanComponent implements OnInit {

  mode: string;

  plan = {
    name: '',
    num_of_members: 0,
    term_notice_period: 0,
    type: PLAN_TYPE.std,
    gp_copay_amount: 0,
    gp_copay_unit: 'AMOUNT',
    sp_copay_amount: 0,
    sp_copay_unit: 'AMOUNT',
    tcm_copay_amount: 0,
    tcm_copay_unit: 'AMOUNT',
    physio_copay_amount: 0,
    physio_copay_unit: 'AMOUNT',
    instructions: [],
    services: []
  };

  plan_id = null;
  
  constructor(private apiService: ApiService, private router: Router, private storage: LocalStorageService) { }

  ngOnInit() {
    this.mode = this.router.url.includes("edit") ? 'edit' : 'add';
  }

  async add(id) {
    this.plan_id = id;
    $('#modal-default').modal({backdrop:'static',keyboard:false, show:true});
  }

}
