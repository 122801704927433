export interface Specialty {
    id: string,
    name: string,
    zh_hans_name: string,
    zh_hant_name: string,
    icon_blob?: string,
    rank?: number,
    value? :number
}

export enum SPECIALTY_OPTION_TYPE{
    diagnosis = 'DIAGNOSIS',
    service = 'SERVICE'
}